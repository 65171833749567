<div id="patient-login" class="full-height overflow-y-auto">
  <div
    class="row m-0 p-0"
    style="height:90vh;"
    *ngIf="
      showSelectionSignIn && !preview && !creatingNote && tabletMode != 'true'
    "
  >
    <div class="col-sm-2 hidden-sm-down"></div>
    <div class="col-sm-8 col-12 patient-select-login-bg">
      <div class="row text-center px-3 pb-3 pt-5">
        <h4 class="text-center w-100">How would you like to start?</h4>
      </div>
      <div class="full-width">
        <div
          class="button-secondary"
          (click)="resetVariables(); showPatientDemographics = true"
        >
          <mat-icon class="notranslate material-icons">person</mat-icon>
          &nbsp;Quick Start
        </div>
      </div>

      <hr />
      <insig-health-login
        [disableClinicianRegistrationAndLogin]="true"
        (loginOutput)="handleLoggedIn()"
      ></insig-health-login>
    </div>
  </div>

  <div *ngIf="loading || stillLoading">
    <div class="divider "></div>
    <div class="divider "></div>
    <insig-app-loader class="mx-auto"></insig-app-loader>
  </div>

  <div class="full-height" *ngIf="showScanDriversLicence">
    <div class="text-left m-3">
      <button
        [hidden]="stillLoading || onlyLogin"
        mat-raised-button
        (click)="resetVariables(); showSelectionSignIn = true"
      >
        <mat-icon class="material-icons">keyboard_arrow_left</mat-icon> Back
      </button>
    </div>

    <div class="row">
      <div class="row p-5 text-center" id="cont">
        <h4 class="text-center mt-0">
          Take a picture of the barcode on the back of your Driver's Licence
        </h4>
      </div>

      <div id="inputFix" class="col-8 offset-2 text-center">
        <ngx-file-drop
          (onFileDrop)="onAdded($event)"
          multiple="false"
          accept=".png,.jpg,.jpeg"
          dropZoneClassName="ngxFileDropZone"
          contentClassName="ngxFileDropContent"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <span>Add Photo</span>
            <span class="px-3"><!-- divider --></span>
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="openFileSelector()"
            >
              Browse
            </button>
          </ng-template>
        </ngx-file-drop>
      </div>

      <div class="columnCustom mt-5">
        <img
          src="../../../assets/images/exampleOne.jpeg"
          alt="Forest"
          style="width:100%"
        />
      </div>
    </div>
  </div>
  <!--Health card end-->

  <div
    class="auth-page h-100"
    [hidden]="!showPatientDemographics && !creatingNote && tabletMode != 'true'"
  >
    <div class="top-nav-right">
      <button
        mat-raised-button
        *ngIf="preview"
        color="primary"
        class="m-2"
        (click)="resetVariables(); showQuickPreview = true"
      >
        Quick Preview
      </button>
    </div>

    <div class="pt-5" style="height:90vh;">
      <div class="l-banner container banner-content pt-0">
        <div>
          <!-- <div class="col-12 text-center" *ngIf="!preview && tabletMode != 'true' && !creatingNote">
             <div *ngIf="!signingUp && !loggingIn" class="article-title p-0 m-0">
              <div class="row">
                <div class="col-12 text-center">
                  <button mat-raised-button class="btn-w-md" color="primary" (click)="signingUp = true;">Sign Up</button>
                  <span class="space"></span>
                  <button mat-raised-button class="btn-w-md" color="primary" (click)="loggingIn = true;">Login</button>
                </div>
              </div>
            </div>
            <div class="text-left mb-3">
              <button [hidden]="stillLoading || onlyLogin" mat-raised-button (click)="resetVariables();showSelectionSignIn = true;">
                <mat-icon class="material-icons">keyboard_arrow_left</mat-icon> Back
              </button>
            </div>
          </div> -->

          <div class="col-12 text-center">
            <h2
              *ngIf="!loggingIn && !signingUp && !creatingNote"
              class="article-title mt-0"
            >
              Patient Check-In
            </h2>
            <h2 *ngIf="creatingNote" class="article-title mt-0">
              Patient Info
            </h2>
            <h2 *ngIf="loggingIn" class="article-title paddingTop05em">
              Patient Login
            </h2>
            <h2 *ngIf="signingUp" class="article-title paddingTop05em">
              Patient Sign Up
            </h2>
            <h6
              *ngIf="(!loggingIn || signingUp) && !creatingNote"
              class="placeholder pb-3 mt-0 text-left"
            >
              <!-- This system is designed to support in providing the highest quality care. Your information is secure, and only accessible by the clinic. -->
              You will now be asked a series of questions that will be reviewed
              by your medical provider as part of your appointment. Your
              responses will be entered into your medical chart; please answer
              as accurately and completely as possible. Your information is
              secure and is only shared with your medical provider.
              <hr />
              Please enter your information as per government ID.
            </h6>
            <h6 *ngIf="creatingNote" class="placeholder pb-3 mt-0 text-left">
              <hr />
              <br />
              Enter patient info to match your EMR records.
              <br /><br />
            </h6>
          </div>
        </div>

        <div
          class="form-wrapper form-wrapper-larger clearfix-md"
          [hidden]="loading"
        >
          <form
            [hidden]="!validationForm || !signingUp || stillLoading"
            [formGroup]="validationForm"
            (ngSubmit)="onSubmit()"
            onkeypress="return event.keyCode != 13;"
          >
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >person</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>First Name</mat-label>
                    <input
                      id="first-name-launch-survey"
                      matInput
                      required
                      autocomplete="First Name"
                      autofocus
                      formControlName="first"
                    />
                    <mat-error>Required</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >person_outline</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Last Name</mat-label>
                    <input
                      id="last-name-launch-survey"
                      matInput
                      required
                      autocomplete="Last Name"
                      formControlName="last"
                    />
                    <mat-error>Required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="divider "></div>

            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >phone</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Cell Phone Number</mat-label>
                    <input
                      matInput
                      insig-phone-mask
                      required
                      autocomplete="Cell Phone Number"
                      minlength="10"
                      maxlength="14"
                      type="tel"
                      formControlName="phone"
                    />
                    <mat-error>Please enter a 10-digit phone number</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >email</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      [required]="signingUp"
                      autocomplete="Email"
                      pattern="^.+@.+\..+$"
                      type="email"
                      formControlName="email"
                    />
                    <mat-error>Please enter a valid email (joe@example.com)</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- <div class="divider "></div> -->

            <div class="row">
              <div class="col-12">
                <label class="placeholder pl-1">Birthday *</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="row">
                  <div class="col-5 pr-0">
                    <div class="form-group form-field-no-padding">
                      <mat-form-field class="full-width">
                        <mat-label>YYYY</mat-label>
                        <input
                          id="birthday-year-launch-survey"
                          class="notranslate"
                          type="tel"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          required
                          matInput
                          autocomplete="Birthdate Year"
                          maxlength="4"
                          formControlName="year"
                        />
                        <mat-error>Invalid year</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-7 pl-0 d-flex">
                    <div class="form-group form-field-no-padding w-50">
                      <mat-form-field class="full-width">
                        <mat-label>MM</mat-label>
                        <input
                          id="birthday-month-launch-survey"
                          class="notranslate"
                          type="tel"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          matInput
                          required
                          autocomplete="Birthdate Month"
                          maxlength="2"
                          formControlName="month"
                        />
                        <mat-error>Invalid</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="form-group form-field-no-padding w-50">
                      <mat-form-field class="full-width">
                        <mat-label>DD</mat-label>
                        <input
                          id="birthday-day-launch-survey"
                          class="notranslate"
                          type="tel"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          matInput
                          required
                          autocomplete="Birthdate Day"
                          maxlength="2"
                          formControlName="day"
                        />
                        <mat-error>Invalid</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="divider  hidden-sm-up"></div>
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >credit_card</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Health Card Number</mat-label>
                    <input
                      matInput
                      autocomplete="Healthcard"
                      formControlName="healthCardNumber"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="divider "></div>

            <div class="row">
              <div class="col-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >location_on</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Home Address</mat-label>
                    <input
                      matInput
                      required
                      autocomplete="Home Address"
                      #searchAddress
                      formControlName="address"
                    />
                    <mat-error>Please enter your address</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="divider "></div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >lock_outline</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Password</mat-label>
                    <input
                      required
                      matInput
                      type="password"
                      name="password"
                      formControlName="password"
                    />
                    <mat-error
                      >Password must be at least 6 characters</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="form-group form-field">
                  <mat-form-field class="full-width ">
                    <span matPrefix>
                      <mat-icon class="notranslate material-icons"
                        >lock_outline</mat-icon
                      >&nbsp;
                    </span>
                    <mat-label>Confirm Password</mat-label>
                    <input
                      required
                      matInput
                      type="password"
                      name="password"
                      formControlName="passwordConfirm"
                    />
                    <mat-error>Passwords must match</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="divider "></div>

            <div class="row">
              <div class="col-md-6 col-xs-12 text-center">
                <label class="placeholder">Designated Gender At Birth *</label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="text-center">
                  <mat-radio-group
                    required
                    class="mat-radio-group-spacing"
                    formControlName="gender"
                  >
                    <mat-radio-button
                      class="marginRight1em"
                      id="male-gender-select-launch-survey"
                      value="Male"
                      >Male
                    </mat-radio-button>
                    <mat-radio-button
                      id="female-gender-select-launch-survey"
                      value="Female"
                      >Female</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <div class="hidden-md-up text-center">
                  <div class="divider "></div>
                  <mat-checkbox
                    required
                    color="primary"
                    formControlName="terms"
                  ></mat-checkbox>
                  <span class="space"></span>I read and accept the
                  <p>
                    <a href="javascript:;" (click)="openTerms()"
                      ><i>Terms & Conditions</i></a
                    >
                  </p>
                </div>
                <div class="hidden-sm-down">
                  <mat-checkbox
                    id="terms-select-launch-survey"
                    required
                    color="primary"
                    formControlName="terms"
                  >
                  </mat-checkbox>
                  <span class="space"></span>I read and accept the
                  <a href="javascript:;" class="d-inline" (click)="openTerms()"
                    ><i>Terms & Conditions</i></a
                  >
                </div>
              </div>
            </div>

            <div class="divider "></div>

            <div class="text-center">
              <button
                mat-raised-button
                class="btn-w-md"
                color="primary"
                (click)="signUp($event)"
              >
                Sign Up
              </button>
            </div>
          </form>

          <form *ngIf="loggingIn" [hidden]="stillLoading">
            <insig-health-login
              [disableClinicianRegistrationAndLogin]="true"
              (loginOutput)="handleLoggedIn()"
            ></insig-health-login>
          </form>

          <form
            *ngIf="!!validationForm"
            [formGroup]="validationForm"
            (ngSubmit)="onSubmit()"
            [hidden]="stillLoading || signingUp || loggingIn"
            onkeypress="return event.keyCode != 13;"
          >
            <fieldset>
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons"
                          >person</mat-icon
                        >&nbsp;
                      </span>
                      <mat-label>First Name</mat-label>
                      <input
                        id="first-name-launch-survey-signingup"
                        matInput
                        required
                        autocomplete="First Name"
                        autofocus
                        formControlName="first"
                      />
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons"
                          >person_outline</mat-icon
                        >&nbsp;
                      </span>
                      <mat-label>Last Name</mat-label>
                      <input
                        id="last-name-launch-survey-signingup"
                        matInput
                        required
                        autocomplete="Last Name"
                        formControlName="last"
                      />
                      <mat-error>Required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="divider "></div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons"
                          >phone</mat-icon
                        >&nbsp;
                      </span>
                      <mat-label>Cell Phone Number</mat-label>
                      <input
                        matInput
                        insig-phone-mask
                        required
                        autocomplete="Cell Phone Number"
                        minlength="10"
                        maxlength="14"
                        type="tel"
                        formControlName="phone"
                      />
                      <mat-error
                        >Please enter a 10-digit phone number</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons"
                          >email</mat-icon
                        >&nbsp;
                      </span>
                      <mat-label>Email</mat-label>
                      <input
                        matInput
                        [required]="signingUp"
                        autocomplete="Email"
                        pattern="^.+@.+\..+$"
                        type="email"
                        formControlName="email"
                      />
                      <mat-error
                        >Please enter a valid email (joe@example.com)</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- <div class="divider "></div> -->

              <div class="row">
                <div class="col-12">
                  <label class="placeholder pl-1">Birthday *</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="row">
                    <div class="col-5 pr-0">
                      <div class="form-group form-field-no-padding">
                        <mat-form-field class="full-width">
                          <mat-label>YYYY</mat-label>
                          <input
                            id="birthday-year-launch-survey-signingup"
                            class="notranslate"
                            type="tel"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            required
                            matInput
                            autocomplete="Birthdate Year"
                            maxlength="4"
                            formControlName="year"
                          />
                          <mat-error>Invalid year</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-7 pl-0 d-flex">
                      <div class="form-group form-field-no-padding w-50">
                        <mat-form-field class="full-width">
                          <mat-label>MM</mat-label>
                          <input
                            id="birthday-month-launch-survey-signingup"
                            class="notranslate"
                            type="tel"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            matInput
                            required
                            autocomplete="Birthdate Month"
                            maxlength="2"
                            formControlName="month"
                          />
                          <mat-error>Invalid</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-group form-field-no-padding w-50">
                        <mat-form-field class="full-width">
                          <mat-label>DD</mat-label>
                          <input
                            id="birthday-day-launch-survey-signingup"
                            class="notranslate"
                            type="tel"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            matInput
                            required
                            autocomplete="Birthdate Day"
                            maxlength="2"
                            formControlName="day"
                          />
                          <mat-error>Invalid</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="divider  hidden-sm-up"></div>
                  <div class="form-group form-field">
                    <mat-form-field class="full-width">
                      <span matPrefix>
                        <mat-icon class="notranslate material-icons"
                          >credit_card</mat-icon
                        >&nbsp;
                      </span>
                      <mat-label>Health Card Number</mat-label>
                      <input
                        matInput
                        autocomplete="Healthcard"
                        formControlName="healthCardNumber"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="divider "></div>

              <div class="row">
                <div class="col-md-6 col-xs-12 text-center">
                  <label class="placeholder"
                    >Designated Gender At Birth *</label
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="text-center ">
                    <mat-radio-group
                      required
                      class="mat-radio-group-spacing"
                      formControlName="gender"
                    >
                      <mat-radio-button
                        class="marginRight1em"
                        id="male-gender-select-launch-survey-signingup"
                        value="Male"
                        >Male</mat-radio-button
                      >
                      <mat-radio-button
                        id="female-gender-select-launch-survey-signingup"
                        value="Female"
                        >Female
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <div class="hidden-md-up text-center">
                    <div class="divider "></div>
                    <mat-checkbox
                      required
                      color="primary"
                      formControlName="terms"
                    ></mat-checkbox>
                    <span class="space"></span>I read and accept the
                    <p>
                      <a href="javascript:;" (click)="openTerms()"
                        ><i>Terms & Conditions</i></a
                      >
                    </p>
                  </div>
                  <div class="hidden-sm-down">
                    <mat-checkbox
                      id="terms-select-launch-survey-signingup"
                      required
                      color="primary"
                      formControlName="terms"
                    ></mat-checkbox>
                    <span class="space"></span>I read and accept the
                    <a
                      href="javascript:;"
                      class="d-inline"
                      (click)="openTerms()"
                      ><i>Terms & Conditions</i></a
                    >
                  </div>
                </div>
              </div>

              <div class="mt-4 text-center">
                <div class="divider "></div>
                <button
                  id="start-button-launch-survey"
                  mat-raised-button
                  type="submit"
                  class="btn-w-md"
                  color="primary"
                >
                  START!
                </button>
                <div class="divider "></div>
                <div class="divider "></div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <br /><br /><br /><br /><br />
    </div>
  </div>
  <!--Demographics End-->

  <div class="auth-page h-100" *ngIf="showQuickPreview">
    <div class="top-nav-right">
      <button
        mat-raised-button
        color="primary"
        class="m-2"
        (click)="resetVariables(); showPatientDemographics = true"
      >
        Patient Login
      </button>
    </div>

    <div class="top-nav-right" id="googleTranslateHeader">
      <div id="google_translate_element_main"></div>
    </div>

    <div class="full-height">
      <div class="l-banner container banner-content pt-5">
        <h1 class="m-0 h2 text-center" [hidden]="loading || stillLoading">
          Quick Preview
        </h1>

        <div
          class="form-wrapper form-wrapper-larger clearfix-md"
          [hidden]="loading"
        >
          <div class="card" [hidden]="stillLoading">
            <div class="card-content">
              <div class="row">
                <div class="col-12">
                  <mat-icon class="notranslate">person</mat-icon> <b>Name:</b>
                  {{ quickPreviewVals.first }} {{ quickPreviewVals.last }}
                </div>
              </div>
              <div class="mt-2 row">
                <div class="col-12">
                  <mat-icon class="notranslate">person</mat-icon>
                  <b>Gender:</b> &nbsp;
                  <mat-radio-group
                    required
                    class="mat-radio-group-spacing"
                    [(ngModel)]="quickPreviewVals.gender"
                  >
                    <mat-radio-button value="Male">Male</mat-radio-button>
                    <mat-radio-button class="ml-2" value="Female"
                      >Female</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
              <div class="mt-2 row">
                <div class="col-12">
                  <mat-icon class="notranslate">cake</mat-icon>
                  <b>Age:</b> &nbsp;
                  <mat-radio-group
                    required
                    class="mat-radio-group-spacing"
                    [(ngModel)]="quickPreviewVals.year"
                  >
                    <mat-radio-button
                      class="marginRight1em"
                      *ngFor="let year of yearValues"
                      [value]="year"
                      >{{ today.getFullYear() - year }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>

              <hr />
              <button
                id="quick-preview-start-launch-survey"
                mat-raised-button
                class="btn-w-md full-width"
                color="primary"
                (click)="quickPreview = true; onSubmit()"
              >
                START!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
