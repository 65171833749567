<div class="full-width full-height">
  <div *ngIf="!!loading" class="text-center m-auto">
    <mat-progress-spinner
      class="my-auto mx-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>

  <insig-place-in-line-select
    *ngIf="currentScreen === 'selectOption'"
    class="full-height full-width"
    (placeInLineOptionSelected)="placeInLineOptionSelected($event)"
  ></insig-place-in-line-select>

  <!-- Removed old login component from here to avoid confusion on whether this component actually uses login code -Tom -->

  <insig-place-in-line-check-in-input
    *ngIf="currentScreen === 'checkInInput'"
    class="full-height full-width"
    (checkInIDSubmitted)="checkInIDSubmitted($event)"
  ></insig-place-in-line-check-in-input>

  <insig-place-in-line-display
    *ngIf="currentScreen === 'showAppt'"
    class="full-height full-width"
    [checkInID]="checkInID"
    [currentAppointment]="currentAppointment"
    [parsedAppointments]="parsedAppointments"
  ></insig-place-in-line-display>

  <insig-place-in-line-nothing-found
    *ngIf="currentScreen === 'nothingFound'"
    class="full-height full-width"
    (retryPlaceInLine)="resetVariables()"
  ></insig-place-in-line-nothing-found>
</div>
