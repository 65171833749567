<mat-card class="p-5">
  <h1 matDialogTitle class="mb-0">
    <img src='./assets/images/pillway/logo.png' class="pillwayDialogLogo" alt="Pillway Logo" />
  </h1>

  <div mat-dialog-content class="px-3">
    <h4 style="color:#e34429;" class="bold m-0">Easy as 1 2 3</h4>
    <div class="divider"></div>
    <span class="text-left">
      <p>
        1. Select Pillway as your preferred pharmacy, and you prescription will be sent after your appointment.
      </p>
      <p>
        2. Pillway will notify you within 1 business hour and arrange delivery. For same-day delivery, prescriptions must be sent to the pharmacy before 3 PM Mon-Fri. Same-day service is available in the Greater Toronto Area and Greater Vancouver Area only, for the time being. Weekend deliveries will be shipped the next business day.
      </p>
      <p>
        3. Your prescription will be delivered to your door! Download the free Pillway app to connect to the Pillway pharmacy team, check status updates, and more.
      </p>

      <br>
      <p>
        By choosing Pillway as your preferred pharmacy, you authorize the sending of your prescription, and contact info to Pillway. You also authorize Pillway to send you email and/or SMS.
      </p>

    </span>
    <div class="divider"></div>
  </div>

  <button mat-raised-button class="float-right" (click)="dialogRef.close(true)">
    Close
  </button>
  <div class="divider mb-4"></div>
</mat-card>
