import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'insig-check-in-select-option',
  templateUrl: './check-in-select-option.component.html',
  styleUrls: ['./check-in-select-option.component.scss'],
})
export class CheckInSelectOptionComponent {
  @Output() checkInOption: EventEmitter<string> = new EventEmitter<string>();
}
