// @angular
import { NgModule, ApplicationRef, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ApiModule as DoctorBookingFlowApiModule, Configuration as DoctorBookingFlowConfiguration } from '@insig-health/api/doctor-booking-flow-api-v1';
import { ApiModule as DoctorBookingFlowApiCopyModule, Configuration as DoctorBookingFlowCopyConfiguration } from '@insig-health/api/doctor-booking-flow-api-v1-copy';
import { ApiModule as PatientRegistrationApiModule, Configuration as PatientRegistrationConfiguration } from '@insig-health/api/patient-registration-api';
import { ApiModule as PatientManagementApiModule, Configuration as PatientManagementConfiguration } from '@insig-health/api/patient-management-api';
import { ApiModule as UserManagementApiModule, Configuration as UserManagementConfiguration } from '@insig-health/api/user-management-api';
import { ApiModule as AuthApiModule, Configuration as AuthConfiguration } from '@insig-health/api/auth-api';
import { ApiModule as DoctorApiModule, Configuration as DoctorConfiguration } from '@insig-health/api/doctor-api';
import { ApiModule as NoteManagementApiModule, Configuration as NoteManagementConfiguration } from '@insig-health/api/note-management-api';
import { ApiModule as OldBookingFlowApiModule, Configuration as OldBookingFlowConfiguration } from '@insig-health/api/old-booking-flow-api';
import { ApiModule as AppointmentManagementModule, Configuration as AppointmentManagementConfiguration } from '@insig-health/api/appointment-management-api';
import { AppointmentService } from 'insig-app/services/appointment/appointment.service';

// apollo stuff
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, concat } from '@apollo/client/core';
import { OperationDefinitionNode } from 'graphql';
import { getMainDefinition } from 'apollo-utilities';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Material
import { MaterialComponentsModule } from './material-components.module';

// Angularfire

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Sub modules
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './dashboard/shared/shared.module';

// config
import {
  AWSCLOUDFUNCTIONSENDPOINT,
  AWS_WAF_CAPTCHA_API_KEY,
  AWS_WAF_CAPTCHA_SCRIPT_URL,
  JAVA_BACKEND_ENDPOINT,
  JAVA_BACKEND_ENDPOINT_MIRROR,
} from '@insig-health/config/config';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { InsigAppLoaderModule } from './global/loader/loader.module';
import { InsigMaterialModule } from './insig-material/insig-material.module';

import { DeactivateGuardService } from './services/deactivateGuard.service';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { CustomHammerConfig } from './custom-hammer-config';

import { JavaBackendAuthorizationInterceptor } from './interceptors/insig-core-spring-authorization-interceptor/java-backend-authorization-interceptor';

// Libs
import { UiModule } from '@insig-health/ui';

import { createErrorHandler } from '@sentry/angular-ivy';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DevEnvironmentLabelModule } from '@insig-health/dev-environment-label/dev-environment-label.module';

import { GoogleMapsModule } from '@angular/google-maps';
import { ReauthenticateDialogComponent } from './dialogs/reauthenticate/reauthenticate-dialog.component';
import { GcpIpModule } from '@insig-health/gcp-ip/gcp-ip.module';
import { PatientTermsOfUseModule } from '@insig-health/patient-terms-of-use/patient-terms-of-use.module';
import { AWS_WAF_CAPTCHA_API_KEY_TOKEN, AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.service';
import { AwsWafCaptchaInterceptor } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.interceptor';
import { AwsWafCaptchaModule } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha.module';
import { TaliAiWidgetModule } from './tali-ai-widget/tali-ai-widget.module';

function apiConfigFactory<T>(configurationClass: new (params: { basePath: string }) => T): () => T {
  const params = {
    basePath: JAVA_BACKEND_ENDPOINT.slice(0, JAVA_BACKEND_ENDPOINT.length - 1),
  };
  return () => new configurationClass(params);
}

function apiConfigFactoryCopy<T>(configurationClass: new (params: { basePath: string }) => T): () => T {
  const mirrorEndpoint = JAVA_BACKEND_ENDPOINT_MIRROR ?? JAVA_BACKEND_ENDPOINT;
  const params = {
    basePath: mirrorEndpoint.slice(0, mirrorEndpoint.length - 1)
  };
  return () => new configurationClass(params);
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule,
    // Material modules
    MaterialComponentsModule,
    // Sub modules
    LayoutModule,
    SharedModule,
    // GOOGLEMAPS
    GoogleMapsModule,
    // loader
    InsigAppLoaderModule,
    InsigMaterialModule,
    UiModule,
    DoctorBookingFlowApiModule.forRoot(apiConfigFactory(DoctorBookingFlowConfiguration)),
    DoctorBookingFlowApiCopyModule.forRoot(apiConfigFactoryCopy(DoctorBookingFlowCopyConfiguration)),
    PatientManagementApiModule.forRoot(apiConfigFactory(PatientManagementConfiguration)),
    UserManagementApiModule.forRoot(apiConfigFactory(UserManagementConfiguration)),
    PatientRegistrationApiModule.forRoot(apiConfigFactory(PatientRegistrationConfiguration)),
    AuthApiModule.forRoot(apiConfigFactory(AuthConfiguration)),
    DoctorApiModule.forRoot(apiConfigFactory(DoctorConfiguration)),
    NoteManagementApiModule.forRoot(apiConfigFactory(NoteManagementConfiguration)),
    OldBookingFlowApiModule.forRoot(apiConfigFactory(OldBookingFlowConfiguration)),
    AppointmentManagementModule.forRoot(apiConfigFactory(AppointmentManagementConfiguration)),
    RouterModule,
    CommonModule,
    DevEnvironmentLabelModule,
    GcpIpModule,
    PatientTermsOfUseModule,
    AwsWafCaptchaModule,
    TaliAiWidgetModule,
  ],
  providers: [
    DeactivateGuardService,
    AppointmentService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JavaBackendAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AwsWafCaptchaInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: AWS_WAF_CAPTCHA_API_KEY_TOKEN,
      useValue: AWS_WAF_CAPTCHA_API_KEY,
    },
    {
      provide: AWS_WAF_CAPTCHA_SCRIPT_URL_TOKEN,
      useValue: AWS_WAF_CAPTCHA_SCRIPT_URL,
    },
    Apollo,
  ],
  declarations: [
    AppComponent,
    ReauthenticateDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public appRef: ApplicationRef,
    apollo: Apollo,
    httpLink: HttpLink,
  ) {
    const cleanTypenameLink = new ApolloLink((operation, forward) => {
      const omitTypename = (key: any, value: any) =>
        key === '__typename' ? undefined : value;

      const def = getMainDefinition(operation.query);
      if (def && (def as OperationDefinitionNode).operation === 'mutation') {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward ? forward(operation) : null;
    });

    const link = httpLink.create({
      uri: `${AWSCLOUDFUNCTIONSENDPOINT}graphql/graphql`,
    });
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
    } as const;

    apollo.create({
      cache: new InMemoryCache(),
      link: concat(cleanTypenameLink, link),
      defaultOptions,
    });
  }
}
