<div
  class="row no-gutters m-0 p-0 kiosk-bg full-height d-flex flex-column justify-content-between"
>
  <div
    class="col-12 align-self-center justify-content-center kiosk-title d-flex flex"
  >
    <div class="pb-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        How would you like to check your place in line?
      </h4>
    </div>
  </div>

  <div class="col-12 action-row d-flex flex">
    <div class="row m-0 full-width">
      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="placeInLineOptionSelected.emit('login')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Login</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="placeInLineOptionSelected.emit('checkInInput')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Enter Check-In ID</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
