<div class="p-3">
  <h5 class="roboto-title text-center">
    {{
      !!textReplacements.title
        ? textReplacements.title
        : 'Update your Information'
    }}
  </h5>
  <form
    name="material_login_form"
    class="md-form-auth form-validation"
    [formGroup]="validationForm"
    onkeypress="return event.keyCode != 13;"
  >
    <div class="divider"></div>

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              matInput
              formControlName="first"
              required
            />
            <mat-error>Please enter your first name</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              formControlName="last"
              required
            />
            <mat-error>Please enter your last name</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        [ngClass]="{
          'col-md-4 col-xs-9': !hideFields?.phoneExtension,
          'col-12': !!hideFields?.phoneExtension
        }"
      >
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Cell Phone Number</mat-label>
            <input
              matInput
              minlength="10"
              maxlength="14"
              insig-phone-mask
              type="tel"
              formControlName="phone"
              required
            />
            <mat-error>Please enter a 10-digit phone number</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-2 col-xs-3" *ngIf="!hideFields?.phoneExtension">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Ext.</mat-label>
            <input
              matInput
              pattern="[0-9#*w]+"
              formControlName="extension"
            />
            <mat-error>(0-9, #, *)</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              pattern="^.+@.+\..+$"
              type="email"
              formControlName="email"
            />
            <mat-error>Please enter a valid email (joe@example.com)</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Address</mat-label>
            <input
              matInput
              #searchAddress
              formControlName="address"
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select
              formControlName="country"
              (selectionChange)="countryChanged($event)"
            >
              <mat-option
                *ngFor="let country of countryList"
                [value]="country"
                >{{ country }}</mat-option
              >
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error>Please select your country</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Province / State</mat-label>
            <mat-select formControlName="province">
              <ng-container *ngIf="validationForm.controls.country.value === 'Canada'">
                <mat-option
                  *ngFor="let province of canadianProvinces"
                  [value]="province.id"
                  >{{ province.name }}
                </mat-option>
              </ng-container>
              <ng-container *ngIf="validationForm.controls.country.value === 'United States'">
                <mat-option
                  *ngFor="let state of americanStates"
                  [value]="state.id"
                >
                  {{ state.name }}
                </mat-option>
              </ng-container>
              <mat-option value="N/A">N/A</mat-option>
              <mat-error>Required</mat-error>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>City</mat-label>
            <input
              matInput
              formControlName="city"
              required
            />
            <mat-error>Please enter your last name</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input
              matInput
              minlength="5"
              maxlength="7"
              formControlName="postalCode"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="text-left placeholder">Birthday</label>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>YYYY</mat-label>
              <input
                type="tel"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="4"
                minlength="4"
                max="{{ thisYear }}"
                min="1900"
                matInput
                autocomplete="off"
                formControlName="year"
                required
              />
              <mat-error>Please enter a valid year</mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>MM</mat-label>
              <input
                type="tel"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="2"
                minlength="1"
                matInput
                autocomplete="off"
                max="12"
                min="1"
                formControlName="month"
                required
              />
              <mat-error>Please enter a valid month</mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>DD</mat-label>
              <input
                type="tel"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="2"
                minlength="1"
                matInput
                autocomplete="off"
                max="31"
                min="1"
                formControlName="day"
                required
              />
              <mat-error>Please enter a valid day</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Health Card Number</mat-label>
            <input
              matInput
              autocomplete="off"
              formControlName="healthCardNumber"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <label class="placeholder">Designated Gender At Birth</label>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center full-width">
        <mat-radio-group
          class="mat-radio-group-spacing"
          formControlName="gender"
          required
        >
          <mat-radio-button value="Male">Male</mat-radio-button>
          <span class="space"></span>
          <mat-radio-button value="Female">Female</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="divider"></div>

    <hr *ngIf="!hideFields?.pharmacy" />
    <div class="row mt-2" id="pharmaLabel" *ngIf="!hideFields?.pharmacy">
      <div class="col-12">
        <label
          class="text-left bold placeholder mb-0"
          [ngClass]="{ 'text-danger': addingPharmacy }"
          >Preferred Pharmacy</label
        >
      </div>
    </div>
    <p class="mb-0" *ngIf="!hideFields?.pharmacy">
      Any prescriptions for your visit may be faxed to your preferred pharmacy.
    </p>

    <span [hidden]="hideFields?.pharmacy">

      <div class="pb-2">

        <div class="row">
          <div class="col-md-6 text-center">
            <img
              class="w-100 mb-2"
              src="{{ defaultPharmacy.img2 }}"
              alt="Pharmacy Rx"
            />
          </div>
          <div class="col-md-6 my-auto">
            <img
              src="{{ defaultPharmacy.img }}"
              style="height: 60px;"
              alt="Pharmacy Logo"
            />
            <h5 style="color:#e34429;" class="bold mt-0">{{ defaultPharmacy.line2 }}</h5>
            <h6 class="my-3">{{ defaultPharmacy.line1 }}</h6>
            <div
              *ngIf="defaultPharmacy.id === 'pillway'"
            >
              <a style="color:#e34429;" (click)="openPillwayInfo()"
              href="javascript:;" class="p-0">
               Learn More ▶
             </a>
            </div>
          </div>
        </div>

        <p *ngIf="defaultPharmacy.line3" class=" text-left mt-2">{{ defaultPharmacy.line3 }}</p>

      </div>

      <div
        class="filled-btn full-width d-flex flex-column p-0 mx-auto mb-3"
        (click)="useDefaultPharmacy()"
      >
        <div class="mt-auto mb-auto">
          Set {{ defaultPharmacy.pharmaName }} as your Preferred Pharmacy
        </div>
      </div>

    </span>

    <div class="row" *ngIf="!hideFields?.pharmacy">
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Pharmacy Name</mat-label>
            <input
              matInput
              required
              formControlName="pharmaName"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Pharmacy Fax Number</mat-label>
            <input
              matInput
              minlength="10"
              maxlength="14"
              required
              insig-phone-mask
              type="tel"
              formControlName="pharmaFax"
            />
            <mat-error>Please enter a 10-digit fax number</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="divider" *ngIf="!hideFields?.pharmacy"></div>

    <div class="row">
      <div class="col-12 text-center">
        <mat-checkbox
          required
          [checked]="true"
          color="primary"
          formControlName="terms"
        ></mat-checkbox>
        <span class="space"></span>I read and accept the
        <a href="javascript:;" class="d-inline" (click)="triggerPatientTerms()">
          <i>Terms & Conditions</i>
        </a>
      </div>
    </div>
    <div class="divider"></div>
    <hr />

    <div class="row mb-5 mx-0 bottom-margin">
      <div
        class="empty-btn col-5 d-flex flex-column p-0 mx-auto"
        (click)="backButtonClicked.emit()"
        *ngIf="!hideFields?.backButton"
      >
        <div class="mt-auto mb-auto">Back</div>
      </div>

      <div
        class="filled-btn float-right d-flex flex-column p-0 mx-auto"
        [ngClass]="{
          'col-5': !hideFields?.backButton,
          'col-10': !!hideFields?.backButton
        }"
        (click)="formSubmitted()"
      >
        <div class="mt-auto mb-auto">
          {{
            !!textReplacements.updateButton
              ? textReplacements.updateButton
              : 'Update'
          }}
        </div>
      </div>
    </div>
  </form>
</div>
