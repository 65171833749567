// @ts-strict-ignore
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import * as LZString from 'lz-string';

import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { CompanyLocationService } from 'insig-app/services/company/location.service';
import { OscarService } from 'insig-app/services/emr/oscar.service';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { ThemeService } from 'insig-app/services/theme/theme.service';
import { SendSMSService } from 'insig-app/services/sendSMS.service';

import { APPURL, APPCONFIG, DEFAULT_THEME } from '@insig-health/config/config';

import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { subHours } from 'date-fns';

import screenfull from 'screenfull';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Component({
  selector: 'insig-check-in-mode',
  templateUrl: './check-in-mode.component.html',
  providers: [
    SendSMSService,
    TranslateService,
    PatientUserDataService,
    ThemeService,
    CompanyLocationService,
    OscarService,
  ],
  styleUrls: ['./check-in-mode.component.scss'],
})
export class CheckInModeComponent implements OnInit, OnDestroy {
  // graphql queries
  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        branding
        brandingName
        name
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
      }
    }
  `;
  //

  public patientPhone: any;
  public phoneValid = false;
  private doctorSurveys = null;
  public librarySurveys = null;
  public doctorList = null;
  public selectedDoctor = null;
  public loopSurvey = true;
  private companyID: string;
  private locationID: string;
  private sub: any;
  public smsKiosk = false;
  public companyData: any = {};
  public phoneNumber = null;
  public digitsRegex = /[^\d]/g;
  public smsSurveyURL: any = '';
  public tempHide = false;
  private userID: string;
  private surveyID: string;
  public checkInType: string;
  private noFullScreen = false;
  public currentScreen = 'selectOption';
  public numberSteps = 5;
  public patientDevice = false;
  public selectedStepperIndex = 0;
  public AppConfig: any = APPCONFIG;
  public userData: any;
  public patientUserData: any;
  public generatedID: string;
  public generatedAppointmentType: string;
  public generatedAppointmentTime: string;
  public resetPasswordFlow = false;
  public goToResetPassword = false;
  public skipLogin = false;
  public familyMemberNeeded = false;
  public goToValidation = false;
  public locationData: any = {};
  public emrDoctors = [];
  public templateMapping: {};
  private doctorAppointmentSubscriptions: Subscription[] = [];
  public doctorAppointments = {};
  public queryCheckInID: string;
  public parsedAppointments = {
    walkIn: [],
    appointmentBased: [],
  };
  public availableAppointments = {
    walkIn: [],
    appointmentBased: [],
  };

  // endpoints
  private unsubscribe: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private gcpIpAuthService: GcpIpAuthService,
    private sendSMSService: SendSMSService,
    public translateService: TranslateService,
    public cookieService: CookieService,
    private patientUserDataService: PatientUserDataService,
    private themeService: ThemeService,
    private companyLocationService: CompanyLocationService,
    private oscarService: OscarService,
    private apollo: Apollo,
    private dateAndTimeService: DateAndTimeService,
  ) {} // end constructor

  async ngOnInit() {
    // only set it to false if they set it to false
    if (this.cookieService.get('loopSurvey') === 'false') {
      this.loopSurvey = false;
    } else {
      this.cookieService.set('loopSurvey', 'true');
      this.loopSurvey = true;
    }
    // sign out to make sure patient's cant see patient data
    this.gcpIpAuthService.signOut();
    this.cookieService.set('checkInMode', 'true');

    this.loadQueryParams();

    this.sub = this.route.params.subscribe((params) => {
      if (this.router.url.includes('place-in-line')) {
        this.checkInType = 'placeInLine';
        this.companyID = params.companyID;
      } else {
        this.companyID = params.companyID;
        this.checkInType = params.type;
        this.locationID = params.locationID;
        this.userID = params.userID;
        this.surveyID = params.surveyID;
      }

      this.loadLocationData();
    });

    this.smsSurveyURL = `${APPURL}check-in/check-in-mode/kiosk/${this.companyID}/${this.locationID}/${this.userID}/${this.surveyID}?noFullScreen=true&patientDevice=true`;
    if (screenfull.isEnabled && !this.noFullScreen) {
      try {
        await screenfull.request();
      } catch (err) {
        console.error('error with fullscreen: ', err);
      }
    }

    this.switchLanguage('English');
    this.loadCompanyData(this.companyID);
  } // end ngOnInit

  async getOscarDoctors() {
    try {
      this.emrDoctors = (
        await this.oscarService.getAllDoctorsForCompany(this.companyID)
      ).doctors;
    } catch (err) {
      console.error('error getting oscar doctors: ', err);
      this.emrDoctors = [];
    }
    this.getLiveAppointmentFeed();
  }

  getLiveAppointmentFeed() {
    const today = new Date();
    let month: any = today.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }

    let day: any = today.getDate();
    if (day < 10) {
      day = '0' + day;
    }

    const dateID = `${today.getFullYear()}-${month}-${day}`;

    if (!!this.emrDoctors && this.emrDoctors.length > 0) {
      this.emrDoctors.forEach((doctor) => {
        this.doctorAppointmentSubscriptions.push(
          this.oscarService
            .getDoctorMetadata(doctor.userID, dateID)
            .subscribe((appointments) => {
              this.doctorAppointments[doctor.userID] = appointments;
              this.combineDoctorAppointments();
            })
        );
      });
    }
  }

  combineDoctorAppointments() {
    this.parsedAppointments = { walkIn: [], appointmentBased: [] };
    this.availableAppointments = { walkIn: [], appointmentBased: [] };
    for (const userID in this.doctorAppointments) {
      if (this.doctorAppointments[userID]) {
        const currentDoctor = this.emrDoctors.filter(
          (doctor) => doctor.userID === userID
        )[0];

        const doctorAppointments = this.doctorAppointments[userID];
        for (const time in doctorAppointments) {
          if (doctorAppointments[time]) {
            const appointment = doctorAppointments[time];
            if (
              !!appointment.val ||
              (!!appointment.apptID && !!appointment.patientID)
            ) {
              const tempAppointment = {
                ...appointment,
                appointmentBased: !!currentDoctor.appointmentBased,
                walkIn: !!currentDoctor.walkIn,
                doctorID: userID,
              };

              // this.parsedAppointments.appointmentBased.push(tempAppointment);

              if (
                !!appointment.appointmentBased ||
                !!currentDoctor.appointmentBased
              ) {
                this.parsedAppointments.appointmentBased.push(tempAppointment);
              } else {
                this.parsedAppointments.walkIn.push(tempAppointment);
              }
            } else {
              const tempAppointment = {
                ...appointment,
                appointmentBased: !!currentDoctor.appointmentBased,
                walkIn: !!currentDoctor.walkIn,
                doctorID: userID,
              };

              if (
                !!appointment.appointmentBased ||
                !!currentDoctor.appointmentBased
              ) {
                this.availableAppointments.appointmentBased.push(
                  tempAppointment
                );
              } else {
                this.availableAppointments.walkIn.push(tempAppointment);
              }
            }
          }
        }
      }
    }
    // removed filter
    // .filter((appt)=>{
    //   console.log(new Date('1970/01/01 ' + appt.time).getTime(), new Date('1970/01/01 ' + nowTimeStr).getTime())
    //   return new Date('1970/01/01 ' + appt.time).getTime() >= new Date('1970/01/01 ' + nowTimeStr).getTime();
    // })

    this.parsedAppointments.appointmentBased = this.parsedAppointments.appointmentBased.sort(
      (a, b) => {
        return Math.abs(
          new Date('1970/01/01 ' + a.time).getTime() -
            new Date('1970/01/01 ' + b.time).getTime()
        );
      }
    );

    this.parsedAppointments.walkIn = this.parsedAppointments.walkIn.sort(
      (a, b) => {
        return Math.abs(
          new Date('1970/01/01 ' + a.time).getTime() -
            new Date('1970/01/01 ' + b.time).getTime()
        );
      }
    );

    this.availableAppointments.appointmentBased = this.availableAppointments.appointmentBased.sort(
      (a, b) => {
        return Math.abs(
          new Date('1970/01/01 ' + a.time).getTime() -
            new Date('1970/01/01 ' + b.time).getTime()
        );
      }
    );

    this.availableAppointments.walkIn = this.availableAppointments.walkIn.sort(
      (a, b) => {
        return Math.abs(
          new Date('1970/01/01 ' + a.time).getTime() -
            new Date('1970/01/01 ' + b.time).getTime()
        );
      }
    );

    if (this.templateMapping) {
      console.log(
        'filtering available appoitnments: ',
        this.availableAppointments
      );
      this.availableAppointments.appointmentBased = this.availableAppointments.appointmentBased.filter(
        (appt) => {
          // console.log(appt, this.templateMapping[appt.templateCode]);

          if (
            !!appt.templateCode &&
            !!this.templateMapping[appt.templateCode]
          ) {
            if (this.templateMapping[appt.templateCode].exclude) {
              // console.log("exluding: ", appt);
              return false;
            } else if (
              !!this.templateMapping[appt.templateCode].mediums &&
              !this.templateMapping[appt.templateCode].mediums.includes(
                'inPerson'
              )
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      );

      this.availableAppointments.walkIn = this.availableAppointments.walkIn.filter(
        (appt) => {
          if (
            !!appt.templateCode &&
            !!this.templateMapping[appt.templateCode]
          ) {
            if (this.templateMapping[appt.templateCode].exclude) {
              return false;
            } else if (
              !!this.templateMapping[appt.templateCode].mediums &&
              !this.templateMapping[appt.templateCode].mediums.includes(
                'inPerson'
              )
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      );
    }

    console.log('parsedappointments: ', this.parsedAppointments);

    console.log('available appointments: ', this.availableAppointments);
  }

  async loadLocationData() {
    try {
      this.locationData = (
        await this.companyLocationService.getLocationsByID(this.locationID)
      ).data();
    } catch (err) {
      console.error('error getting locations data: ', err);
    }
  }

  loadQueryParams() {
    // get apptID if virtual care survey
    this.route.queryParams
      .pipe(take(1))
      .toPromise()
      .then(async (params) => {
        if (params.noFullScreen) {
          // console.log(params['noFullScreen'])
          this.noFullScreen = true;
        }
        if (params.patientDevice) {
          // add skip to sign in
          this.patientDevice = true;
          this.numberSteps = 4;
          this.selectedStepperIndex = 0;
          this.currentScreen = 'patientSelect';
        }

        if (params.resetPassword) {
          this.goToResetPassword = true;
          this.patientDevice = true;
          this.numberSteps = 4;
          this.selectedStepperIndex = 0;
          this.currentScreen = 'patientLogin';
        }

        if (params.checkInID) {
          this.queryCheckInID = params.checkInID;
        }
      });
  }

  async loadCompanyData(cid) {
    const companyDataQuery: any = await this.apollo
      .query({
        query: this.companyDataQuery,
        variables: {
          companyID: cid,
          token: await this.gcpIpAuthService.getCurrentUser()?.getIdToken(),
        },
      })
      .toPromise();
    this.companyData = companyDataQuery.data.getCompanyData;
    console.log(this.companyData);
    let companyData = this.companyData;
    let theme = DEFAULT_THEME;

    if (!!companyData.ThemeConfig) {
      theme = {
        'primary-color': companyData.ThemeConfig.primary,
        'secondary-color': companyData.ThemeConfig.secondary,
        'text-color': companyData.ThemeConfig.text,
        'text-selected': companyData.ThemeConfig.textSelected,
        'text-secondary': companyData.ThemeConfig.textSecondary,
      };
    }
    console.log(theme);
    this.themeService.setTheme(theme);
    this.getOscarDoctors();
  }

  switchLanguage(language: any) {
    this.translateService.changeLanguage(language);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    this.detachDoctorAppointmentSubscriptions();
  }

  detachDoctorAppointmentSubscriptions() {
    this.doctorAppointmentSubscriptions.forEach((listener) =>
      listener.unsubscribe()
    );
    this.doctorAppointmentSubscriptions = [];
  }

  sortFunc(array) {
    return array.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  sortLibraryFunc(array) {
    // Alphabetical sorting by 'name'
    return array.sort((a, b) => {
      if (b.name.includes('START Insig')) {
        return 1;
      } else if (a.name.includes('START Insig')) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  sortFuncName(array) {
    return array.sort((a, b) => {
      return a.last.localeCompare(b.last);
    });
  }

  getLZPatientData(patientData) {
    const patientInfo = {
      patientBirthDate: `${patientData.year}/${patientData.month}/${patientData.day}`,
      patientEmail: patientData.email,
      patientFirstName: patientData.first,
      patientLastName: patientData.last,
      patientGender: patientData.gender === 'Male' ? '0' : '1',
      patientHealthCard: patientData.healthCard,
      patientPhone: this.patientPhone,
    };
    const returnStr = LZString.compressToEncodedURIComponent(
      JSON.stringify(patientInfo)
    );

    return returnStr;
  }

  resetScreen() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    this.selectedStepperIndex = 0;
    this.currentScreen = 'selectOption';
    this.patientPhone = '';
    this.phoneValid = false;
    this.userData = {};
    this.patientUserData = {};
    this.familyMemberNeeded = false;
    this.skipLogin = false;
    this.resetPasswordFlow = false;
    this.goToResetPassword = false;
    this.goToValidation = false;
    this.generatedID = undefined;
    this.generatedAppointmentType = undefined;
    this.generatedAppointmentTime = undefined;
    this.smsSurveyURL = `${APPURL}check-in/check-in-mode/kiosk/${this.companyID}/${this.locationID}/${this.userID}/${this.surveyID}?noFullScreen=true&patientDevice=true`;
    this.gcpIpAuthService.signOut();
  }

  launchSurvey() {
    const type = this.userID;
    if (type === 'library') {
      this.router.navigate(
        [
          '/surveys/launch/check-in-mode',
          'library',
          this.companyID,
          'library',
          this.surveyID,
        ],
        {
          queryParams: {
            ptInfo: this.getLZPatientData(this.patientUserData),
            locationID: this.locationID,
          },
        }
      );
    } else {
      this.router.navigate(
        [
          '/surveys/launch/check-in-mode',
          'user',
          this.companyID,
          this.userID,
          this.surveyID,
        ],
        {
          queryParams: {
            ptInfo: this.getLZPatientData(this.patientUserData),
            locationID: this.locationID,
          },
        }
      );
    }
  } // end launcsurvey func

  sendSurveySMS() {
    const phoneNumber = this.patientPhone.replace(/[^\d]/g, '');
    if (phoneNumber.length !== 10) {
      this.snackBar.open('Please enter a valid phone number!', null, {
        duration: 4000,
      });
      return false;
    } else {
      this.tempHide = true;
      try {
        this.snackBar.open('Sending text message ...', null, {
          duration: 4000,
        });

        let message = '';
        if (this.generatedID) {
          const placeInLineURL = `${APPURL}check-in/place-in-line/${this.companyID}?checkInID=${this.generatedID}`;
          if (
            !!this.generatedAppointmentType &&
            this.generatedAppointmentType === 'appointmentBased'
          ) {
            if (this.generatedAppointmentTime) {
              message = `Thank you for completing your check-in at ${
                !!this.locationData && !!this.locationData.name
                  ? this.locationData.name
                  : this.companyData.name
              }! \
              \n\nYou are all set for your appointment at ${
                this.generatedAppointmentTime
              } and your visit ID is ${this.generatedID}. \
              \n\nWe will send you another message when your provider is ready to see you.`;
            } else {
              message = `Thank you for completing your check-in at ${
                !!this.locationData && !!this.locationData.name
                  ? this.locationData.name
                  : this.companyData.name
              }! \
              \n\nYou are all set for your appointment and your visit ID is ${
                this.generatedID
              }. \
              \n\nWe will send you another message when your provider is ready to see you.`;
            }
          } else {
            message = `Thank you for completing your check-in at ${
              !!this.locationData && !!this.locationData.name
                ? this.locationData.name
                : this.companyData.name
            }! \
            \n\nWe have added you to our walk-in queue and your visit ID is ${
              this.generatedID
            }. \
            \n\nFeel free to relax in our waiting room or go for a stroll, we will send you another \
            message when we are almost ready to see you. You can also view your position in line at the following link:\n ${placeInLineURL}`;
          }
        } else {
          message = `
          ${
            !!this.locationData && !!this.locationData.name
              ? this.locationData.name
              : this.companyData.name
          } - Please complete your intake form: ${this.smsSurveyURL}`;
        }

        this.sendSMSService.sendSMS(phoneNumber, message).then((response) => {
          if (response.statusCode === 200) {
            this.snackBar.open('Text message Sent!', null, { duration: 5000 });
            this.phoneNumber = null;
          } else {
            this.snackBar.open('Erorr sending message!', null, {
              duration: 5000,
            });
          }
          this.tempHide = false;
        });
      } catch (err) {
        console.error(err);
        this.tempHide = false;
      }
    }
  }

  checkInOption(event) {
    if (event === 'phone') {
      this.currentScreen = 'enterPhone';
    } else {
      this.currentScreen = 'patientSelect';
    }

    this.selectedStepperIndex = 1;
  }

  dotsBackClicked(): void {
    if (
      this.currentScreen === 'enterPhone' ||
      this.currentScreen === 'patientSelect' ||
      this.currentScreen === 'patientLogin'
    ) {
      this.currentScreen = 'selectOption';
      this.selectedStepperIndex = 0;
      this.resetScreen();
    } else if (this.currentScreen === 'familyMemberSelect') {
      this.currentScreen = 'patientLogin';
      this.selectedStepperIndex = this.patientDevice ? 0 : 1;
    } else if (this.currentScreen === 'forgotPassword') {
      this.currentScreen = 'patientLogin';
      this.selectedStepperIndex = this.patientDevice ? 0 : 1;
    }
  }

  dotsForwardClicked() {
    if (
      this.currentScreen === 'enterPhone' &&
      !!this.patientPhone &&
      !!this.phoneValid
    ) {
      if (this.resetPasswordFlow) {
        this.smsSurveyURL = `${APPURL}check-in/check-in-mode/kiosk/${this.companyID}/${this.locationID}/${this.userID}/${this.surveyID}?noFullScreen=true&patientDevice=true&resetPassword=true`;
      } else {
        this.smsSurveyURL = `${APPURL}check-in/check-in-mode/kiosk/${this.companyID}/${this.locationID}/${this.userID}/${this.surveyID}?noFullScreen=true&patientDevice=true`;
      }
      this.sendSurveySMS();
      this.selectedStepperIndex = 4;
      this.currentScreen = 'phoneConfirmation';
    }
  }

  async loadUserData(userID) {
    this.userData = await this.patientUserDataService
      .getPatientData(userID)
      .pipe(take(1))
      .toPromise();
  }

  async patientInfoEntered(userData) {
    this.userData = userData;

    if (this.familyMemberNeeded) {
      this.currentScreen = 'familyMemberSelect';
      this.selectedStepperIndex = 2;
    } else if (this.skipLogin) {
      this.patientUserData = this.userData;
      this.patientPhone = this.userData.phone;
      this.generatedID = await this.generatePatientID(this.userData);
      if (this.generatedID) {
        this.currentScreen = 'numberConfirmation';
        this.selectedStepperIndex = 4;
      } else {
        this.currentScreen = 'checkInError';
      }

      // this.launchSurvey();
    } else {
      console.log(this.userData);
      this.patientUserData = this.userData;
      this.patientPhone = this.userData.phone;
      this.generatedID = await this.generatePatientID(this.userData);

      if (this.generatedID) {
        if (!this.patientDevice) {
          this.sendPatientQuestionnaire(this.patientUserData);
        }

        this.currentScreen = 'numberConfirmation';
        this.selectedStepperIndex = 4;
      } else {
        this.currentScreen = 'checkInError';
      }
    }
  }

  async patientLoggedIn(userID) {
    await this.loadUserData(userID);
    if (this.familyMemberNeeded) {
      this.currentScreen = 'familyMemberSelect';
      this.selectedStepperIndex = 2;
    } else {
      console.log(this.userData);
      this.patientUserData = this.userData;
      this.patientPhone = this.userData.phone;
      this.generatedID = await this.generatePatientID(this.userData);

      if (this.generatedID) {
        if (!this.patientDevice) {
          this.sendPatientQuestionnaire(this.patientUserData);
        }

        this.currentScreen = 'numberConfirmation';
        this.selectedStepperIndex = 4;
      } else {
        this.currentScreen = 'checkInError';
      }
    }
  }

  getSequentialWalkInNumber() {
    console.log('in sequential number function');
    if (
      !this.parsedAppointments ||
      !this.parsedAppointments.walkIn ||
      this.parsedAppointments.walkIn.length === 0
    ) {
      return '000';
    } else {
      const nextAppt = this.parsedAppointments.walkIn
        .filter((appt) => !!appt.checkedInTime)
        .sort((a, b) => {
          return b.checkedInTime - a.checkedInTime;
        })[0];
      console.log('nextAppt: ', nextAppt);
      if (nextAppt) {
        let newNumber = (
          parseInt(
            nextAppt.checkInID.slice(
              nextAppt.checkInID.length - 3,
              nextAppt.checkInID.length
            ),
            10
          ) + 1
        ).toString();
        if (newNumber.length === 1) {
          newNumber = '00' + newNumber;
        } else if (newNumber.length === 2) {
          newNumber = '0' + newNumber;
        }
        return newNumber;
      } else {
        return '000';
      }
    }
  }

  async signedUpForFamilyMember(familyData) {
    // await this.loadUserData(userID);
    // console.log(this.userData)
    this.patientUserData = familyData;
    this.patientPhone = familyData.phone;
    this.generatedID = await this.generatePatientID(familyData);
    if (this.generatedID) {
      if (!this.patientDevice) {
        this.sendPatientQuestionnaire(this.patientUserData);
      }

      this.currentScreen = 'numberConfirmation';
      this.selectedStepperIndex = 4;
    } else {
      this.currentScreen = 'checkInError';
    }
  }

  adjustAppointmentTime(timeStr: string) {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const clinicTimeZoneOffset = Math.abs(this.dateAndTimeService.getUtcOffsetMinutes(new Date(), 'America/Vancouver'));
    // console.log(timeZoneOffset, clinicTimeZoneOffset)

    return new Date(
      new Date(
        new Date().setHours(
          parseInt(timeStr.split(':')[0], 10),
          parseInt(timeStr.split(':')[1], 10)
        )
      ).getTime() +
        (clinicTimeZoneOffset - timeZoneOffset) * 60 * 1000
    ).getTime();
  }

  async generatePatientID(patientData) {
    let IDToken = '';
    const timeZoneOffset = new Date().getTimezoneOffset();
    try {
      IDToken = await this.gcpIpAuthService.getCurrentUser()?.getIdToken();
    } catch (err) {
      console.log('error getting ID token: ', err);
    }
    const familyID = this.familyMemberNeeded ? patientData.id : undefined;
    const start = new Date();
    let month: any = start.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }

    let day: any = start.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    const today = `${start.getFullYear()}-${month}-${day}`;
    let oscarPatientID = '';
    try {
      oscarPatientID = await this.oscarService.getOscarPatientID(
        this.companyID,
        this.locationID,
        patientData,
        true
      );
    } catch (err) {
      console.log('error getting patient ID: ', err);
    }
    console.log('oscar patientID ', oscarPatientID);

    let checkInID;
    let appointmentType;
    let appointmentTime;
    const appointmentBased = this.parsedAppointments.appointmentBased.filter(
      (appt) => {
        return appt.patientID === oscarPatientID.toString();
      }
    );

    const walkIn = this.parsedAppointments.walkIn.filter((appt) => {
      return appt.patientID === oscarPatientID.toString();
    });

    console.log('appoitnments matched: ', appointmentBased, walkIn);
    console.log('parsed appointments: ', this.parsedAppointments);
    // get next available walk in appointment
    const clinicTimeZoneOffset = Math.abs(this.dateAndTimeService.getUtcOffsetMinutes(new Date(), 'America/Vancouver'));
    // console.log(timeZoneOffset, clinicTimeZoneOffset)
    let now = new Date();
    if (timeZoneOffset < clinicTimeZoneOffset) {
      const numberOfHours = Math.round(
        (clinicTimeZoneOffset - timeZoneOffset) / 60
      );
      // console.log(numberOfHours)
      now = new Date(subHours(now, numberOfHours));
    }

    let hour = '' + now.getHours();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = '' + now.getMinutes();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    const nowTimeStr = `${hour}:${minute}`;

    const nextWalkInAppt = this.availableAppointments.walkIn.filter((appt) => {
      // console.log(new Date('1970/01/01 ' + appt.time).getTime(), new Date('1970/01/01 ' + nowTimeStr).getTime())
      return (
        new Date('1970/01/01 ' + appt.time).getTime() >=
        new Date('1970/01/01 ' + nowTimeStr).getTime()
      );
    })[0];
    console.log('next walk in appt: ', nextWalkInAppt);
    // end next available walk in appointment;

    if (!!appointmentBased && appointmentBased.length > 0) {
      console.log('adding appointment apointment based');
      const appt = appointmentBased[0];
      appointmentType = 'appointmentBased';
      appointmentTime = appt.time;
      if (appt.checkInID) {
        checkInID = appt.checkInID;
      } else {
        const start = this.adjustAppointmentTime(appt.time);
        checkInID = `A${patientData.first[0].toUpperCase()}${patientData.last[0].toUpperCase()}${
          appt.time
        }`;
        try {
          await this.oscarService.checkInPatient(
            IDToken,
            this.companyID,
            this.locationID,
            appt.doctorID,
            patientData,
            appt.time,
            today,
            checkInID,
            false,
            new Date().getTime(),
            oscarPatientID,
            false,
            timeZoneOffset,
            start,
            familyID
          );
        } catch (err) {
          console.log('error checking in patient: ', err);
        }
      }
    } else if (!!walkIn && walkIn.length) {
      console.log('adding walk in');
      const appt = walkIn[0];
      appointmentType = 'walkIn';
      appointmentTime = appt.time;
      if (appt.checkInID) {
        checkInID = appt.checkInID;
      } else {
        const start = this.adjustAppointmentTime(appt.time);
        const sequentialNumber = this.getSequentialWalkInNumber();
        console.log('sequential number: ', sequentialNumber);
        // checkInID = `A${patientData.first[0].toUpperCase()}${patientData.last[0].toUpperCase()}${
        //   appt.time
        // }`;
        checkInID = `W${patientData.first[0].toUpperCase()}${patientData.last[0].toUpperCase()}${sequentialNumber}`;

        try {
          await this.oscarService.checkInPatient(
            IDToken,
            this.companyID,
            this.locationID,
            appt.doctorID,
            patientData,
            appt.time,
            today,
            checkInID,
            true,
            new Date().getTime(),
            oscarPatientID,
            false,
            timeZoneOffset,
            start,
            familyID
          );
        } catch (err) {
          console.log('error checking in patient: ', err);
        }
      }
    } else {
      console.log('create walk in appointment');
      console.log(this.parsedAppointments);

      try {
        appointmentType = 'walkIn';
        appointmentTime = nextWalkInAppt.time;
        const start = this.adjustAppointmentTime(nextWalkInAppt.time);
        const sequentialNumber = this.getSequentialWalkInNumber();
        console.log('sequential number: ', sequentialNumber);
        checkInID = `W${patientData.first[0].toUpperCase()}${patientData.last[0].toUpperCase()}${sequentialNumber}`;
        await this.oscarService.checkInPatient(
          IDToken,
          this.companyID,
          this.locationID,
          nextWalkInAppt.doctorID,
          patientData,
          nextWalkInAppt.time,
          today,
          checkInID,
          true,
          new Date().getTime(),
          oscarPatientID,
          true,
          timeZoneOffset,
          start,
          familyID
        );
      } catch (err) {
        console.log('error checking in patient: ', err);
        checkInID = false;
        // this.snackBar.open("There is a problem with the Check In Kiosk, please tell the clinic staff", null, { duration:  4000});
        // setTimeout(()=>{
        //   this.resetScreen()
        // }, 5000)
      }
    }
    this.generatedAppointmentType = appointmentType;
    this.generatedAppointmentTime = appointmentTime;
    return checkInID;
  }

  async familyMemberInfoEntered(member) {
    console.log(member);
    if (member === 'myself') {
      this.skipLogin = true;
      this.selectedStepperIndex = this.patientDevice ? 0 : 1;
      this.familyMemberNeeded = false;
      this.currentScreen = 'patientLogin';
    } else {
      this.patientUserData = member;
      this.patientPhone = member.phone;
      this.generatedID = await this.generatePatientID(member);

      if (this.generatedID) {
        console.log(this.patientPhone);
        if (!this.patientDevice) {
          this.sendPatientQuestionnaire(this.patientUserData);
        }

        this.currentScreen = 'numberConfirmation';
        this.selectedStepperIndex = 4;
      } else {
        this.currentScreen = 'checkInError';
      }
    }
  }

  async familyMemberSelected(member) {
    console.log(member);
    if (member === 'myself') {
      this.selectedStepperIndex = this.patientDevice ? 0 : 1;
      this.goToValidation = true;
      this.familyMemberNeeded = false;
      this.currentScreen = 'patientLogin';
    } else {
      this.patientUserData = member;
      this.patientPhone = member.phone;
      this.generatedID = await this.generatePatientID(member);

      if (this.generatedID) {
        console.log(this.patientPhone);
        if (!this.patientDevice) {
          this.sendPatientQuestionnaire(this.patientUserData);
        }

        this.currentScreen = 'numberConfirmation';
        this.selectedStepperIndex = 4;
      } else {
        this.currentScreen = 'checkInError';
      }
    }
  }

  patientSelected(member) {
    console.log(member);
    if (member !== 'myself') {
      this.familyMemberNeeded = true;
    }

    this.currentScreen = 'patientLogin';
    this.selectedStepperIndex = this.patientDevice ? 0 : 1;
    //
  }

  sendPatientQuestionnaire(userData) {
    if (this.userID === 'library') {
      this.smsSurveyURL = `${APPURL}surveys/launch/library/${this.companyID}/${
        this.userID
      }/${this.surveyID}?ptInfo=${this.getLZPatientData(userData)}`;
    } else {
      this.smsSurveyURL = `${APPURL}surveys/launch/user/${this.companyID}/${
        this.userID
      }/${this.surveyID}?ptInfo=${this.getLZPatientData(userData)}`;
    }

    this.sendSurveySMS();
  }

  goToForgotPassword() {
    this.currentScreen = 'forgotPassword';
  }

  checkInResetPasswordOption(type) {
    this.resetPasswordFlow = true;
    if (!!type && type === 'phone') {
      // go to phone appointment on phone
      this.currentScreen = 'enterPhone';
    } else if (this.familyMemberNeeded) {
      this.currentScreen = 'familyMemberSelect';
      this.selectedStepperIndex = 2;
      this.skipLogin = true;
    } else {
      // skip login
      this.currentScreen = 'patientLogin';
      this.skipLogin = true;
    }
  }
} // end component
