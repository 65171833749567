<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Thank  you!  Your  number  is:
      </h4>

      <h4 class="px-4 mx-auto roboto-title-large">
        {{ generatedID }}
      </h4>

      <h4
        class="px-4 mx-auto roboto-title"
        *ngIf="!!patientDevice || !!skipLogin"
      >
        You will momentarily be presented with a medical questionnaire to fill
        out!
      </h4>

      <h4
        class="px-4 mx-auto roboto-title"
        *ngIf="!patientDevice && !skipLogin"
      >
        A  medical  questionnaire  was  sent  to  your  phone.  Please  click
         on  the  link  sent  to  you  to  fill  out  the medical  questionnaire
      </h4>
    </div>
  </div>
</div>
