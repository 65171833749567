<div class="row no-gutters m-0 p-0 kiosk-bg align-content-center">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="pb-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        {{
          !addingFamilyMember
            ? 'Who is being seen?'
            : !!validatingFamilyMember
            ? 'Please confirm the details below'
            : 'Please fill in the details below'
        }}
      </h4>
    </div>
  </div>

  <div class="col-12 action-row">
    <div
      *ngIf="!addingFamilyMember && !isFamilyMemberSelected"
      class="overflow-y-auto no-scrollbar bottom-padding my-auto"
    >
      <div class="row">
        <div class="col-11 col-lg-8 col-md-8 mx-auto">
          <div
            class="button-secondary text-button"
            (click)="addFamilyMember(); scrollTop()"
          >
            Add New Patient
          </div>

          <span *ngIf="familyMembersList && familyMembersList.length < 8">
            <div class="row m-0" *ngFor="let member of familyMembersList">
              <div
                class="col-2 d-flex align-items-center pr-0 flex-row-reverse"
              >
                <button
                  mat-mini-fab
                  color="none"
                  class="bottom-toolbar-button"
                  (click)="openEditFamilyMember(member); scrollTop()"
                  matTooltip="Edit Patient Info"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon class="material-icons">edit</mat-icon>
                </button>
              </div>
              <div class="col-8">
                <div
                  class="button-secondary primary-color-full"
                  (click)="validateFamilyMember(member); scrollTop()"
                >
                  {{ member.first }} {{ member.last }}
                </div>
              </div>
              <div class="col-2 d-flex align-items-center pl-0">
                <button
                  mat-mini-fab
                  color="none"
                  class="bottom-toolbar-button"
                  (click)="deleteFamilyMember(member); scrollTop()"
                  matTooltip="Delete Patient"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon class="material-icons">delete</mat-icon>
                </button>
              </div>
            </div>
          </span>

          <div
            class="button-secondary text-button"
            (click)="bookMyself(); scrollTop()"
          >
            Myself
          </div>

          <div
            class="text-center"
            *ngIf="familyMembersList && familyMembersList.length >= 8"
          >
            <hr />
            <mat-form-field class="w-50 mt-4">
              <mat-icon matPrefix class="material-icons">search</mat-icon>
              <mat-label>Search {{ familyMembersList.length }} Patients</mat-label>
              <input
                matInput
                #searchPatient
              />
            </mat-form-field>
            <span
              *ngIf="
                searchPatient &&
                searchPatient.value &&
                searchPatient.value.length > 1
              "
            >
              <div
                class="row"
                *ngFor="
                  let member of familyMembersList
                    | searchpipe: 'fullName':searchPatient.value
                "
              >
                <div
                  class="col-2 d-flex align-items-center pr-0 flex-row-reverse"
                >
                  <button
                    mat-mini-fab
                    color="none"
                    (click)="openEditFamilyMember(member); scrollTop()"
                    matTooltip="Edit Patient Info"
                    [matTooltipPosition]="'above'"
                  >
                    <mat-icon class="material-icons">edit</mat-icon>
                  </button>
                </div>
                <div class="col-8">
                  <div
                    class="my-0 button-white primary-color-full"
                    (click)="selectFamilyMember(member); scrollTop()"
                  >
                    {{ member.first }} {{ member.last }}
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center pl-0">
                  <button
                    mat-mini-fab
                    color="none"
                    (click)="deleteFamilyMember(member); scrollTop()"
                    matTooltip="Delete Patient"
                    [matTooltipPosition]="'above'"
                  >
                    <mat-icon class="material-icons">delete</mat-icon>
                  </button>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      [hidden]="!addingFamilyMember"
      class="overflow-y-auto overflow-x-hidden no-scrollbar bottom-padding my-auto"
    >
      <div class="row bottom-margin">
        <div class="col-11 col-lg-8 col-md-8 mx-auto">
          <form
            name="material_login_form"
            class="md-form-auth form-validation"
            [formGroup]="createFamilyMemberForm"
            onkeypress="return event.keyCode != 13;"
          >
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input
                      matInput
                      required
                      formControlName="first"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input
                      matInput
                      required
                      formControlName="last"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="text-left placeholder">Birthday</label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>YYYY</mat-label>
                      <input
                        type="tel"
                        required
                        pattern="[0-9]*"
                        inputmode="numeric"
                        maxlength="4"
                        minlength="4"
                        max="{{ thisYear }}"
                        min="1900"
                        matInput
                        autocomplete="off"
                        formControlName="year"
                      />
                      <mat-error>Please enter a valid year</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>MM</mat-label>
                      <input
                        type="tel"
                        required
                        pattern="[0-9]*"
                        inputmode="numeric"
                        maxlength="2"
                        minlength="1"
                        matInput
                        autocomplete="off"
                        max="12"
                        min="1"
                        formControlName="month"
                      />
                      <mat-error>Please enter a valid month</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>DD</mat-label>
                      <input
                        type="tel"
                        required
                        pattern="[0-9]*"
                        inputmode="numeric"
                        maxlength="2"
                        minlength="1"
                        matInput
                        autocomplete="off"
                        max="31"
                        min="1"
                        formControlName="day"
                      />
                      <mat-error>Please enter a valid day</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>City</mat-label>
                  <input
                    required
                    matInput
                    formControlName="city"
                  />
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Address</mat-label>
                  <input
                    required
                    matInput
                    #address
                    formControlName="address"
                  />
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Province</mat-label>
                  <mat-select formControlName="province">
                    <mat-option
                      *ngFor="let province of provinces"
                      [value]="province.id"
                      >{{ province.name }}</mat-option
                    >
                    <mat-error>Required</mat-error>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Postal Code</mat-label>
                  <input
                    matInput
                    minlength="5"
                    maxlength="7"
                    formControlName="postalCode"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 col-xs-9">
                <div class="form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Cell Phone Number</mat-label>
                    <input
                      matInput
                      minlength="10"
                      maxlength="14"
                      insig-phone-mask
                      required
                      type="tel"
                      formControlName="phone"
                    />
                    <mat-error>Please enter a 10-digit phone number</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 col-xs-3">
                <div class="form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Ext.</mat-label>
                    <input
                      matInput
                      pattern="[0-9#*w]+"
                      autocomplete="off"
                      formControlName="extension"
                    />
                    <mat-error>(0-9, #, *)</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Health Card Number</mat-label>
                    <input
                      matInput
                      formControlName="healthCardNumber"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <label class="placeholder">Designated Gender At Birth *</label>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center full-width">
                <mat-radio-group
                  class="mat-radio-group-spacing"
                  required
                  formControlName="gender"
                >
                  <mat-radio-button value="Male">Male</mat-radio-button>
                  <span class="space"></span>
                  <mat-radio-button value="Female">Female</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </form>

          <div class="divider-md divider"></div>

          <div class="row mt-4 m-0 mb-5">
            <div
              class="empty-btn col-5 d-flex flex-column p-0 mx-auto"
              (click)="addingFamilyMember = false; scrollTop()"
            >
              <!-- *ngIf="appointmentConfig.medium != 'inPerson' && !userData.pharmaFax" -->
              <div class="mt-auto mb-auto">Back</div>
            </div>

            <div
              *ngIf="!editingFamilyMember"
              class="filled-btn col-5 float-right d-flex flex-column p-0 mx-auto"
              (click)="createFamilyMember(); scrollTop()"
            >
              <div class="mt-auto mb-auto">Create</div>
            </div>
            <div
              *ngIf="editingFamilyMember"
              class="filled-btn col-5 float-right d-flex flex-column p-0 mx-auto"
              (click)="editFamilyMember(); scrollTop()"
            >
              <div class="mt-auto mb-auto">
                {{ !!validatingFamilyMember ? 'Confirm' : 'Save' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-fade"></div>
</div>
