import { Injectable } from '@angular/core';
import { UpdateUserEmailRequest, UserManagementService as GeneratedUserManagementService, SuccessResponse } from '@insig-health/api/user-management-api';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(
    private generatedUserManagementService: GeneratedUserManagementService,
  ) {}

  async updateEmail(userId: string, newEmail: UpdateUserEmailRequest): Promise<SuccessResponse> {
    return lastValueFrom(this.generatedUserManagementService.updateUserEmail(userId, newEmail));
  }
}
