<mat-card>
  <h1 matDialogTitle>Warning!</h1>
  <div mat-dialog-content>
    <p>
      The preview feature is intended only for viewing how a patient would
      complete this questionnaire.
    </p>
    <p>
      If you wish to have a patient fill out this questionnaire, either use the
      'Send' button, or see 'Kiosk Mode'.
    </p>

    <div class="divider"></div>

    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button
      id="confirm-preview-survey-button"
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="dialogRef.close(true)"
    >
      Confirm
    </button>
  </div>
</mat-card>
