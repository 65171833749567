// @ts-strict-ignore
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { PreferredPharmacyService } from 'insig-app/services/preferred-pharmacy.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { VirtualCareService } from 'insig-app/services/virtual-care/virtual-care.service';
import { PatientTermsDialogComponent } from '../dialogs/terms/patient-terms-dialog.component';
import { PillwayInfoDialogComponent } from 'insig-app/global/dialogs/pillway-info-dialog/pillway-info-dialog.component';

import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from '@insig-health/services/location/location.service';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';

@Component({
  selector: 'patient-validate-info',
  templateUrl: './patient-validate-info.component.html',
  styleUrls: ['./patient-validate-info.component.scss'],
  providers: [
    VirtualCareService,
    PreferredPharmacyService,
    PatientUserDataService,
  ],
})
export class PatientValidateInfoComponent
  implements OnInit, AfterViewInit {
  public validationForm: UntypedFormGroup;
  private today = new Date();

  @Input() hideFields: any = {};
  @Input() textReplacements: any = {};
  @Output() updatePatientInfo = new EventEmitter();
  @Output() backButtonClicked = new EventEmitter();
  @ViewChildren('searchAddress')
  public addressElementRef: QueryList<ElementRef>;

  public canadianProvinces = this.patientUserDataService.provinces;
  public americanStates = this.patientUserDataService.americanStates;
  public thisYear: any = new Date().getFullYear();

  public countryList = ['Canada', 'United States', 'Mexico', 'Grenada'];

  public addingPharmacy = false;

  public _userData: any;

  @Input() get userData() {
    // this.parseVirtualArray()
    return this._userData;
  }
  set userData(val) {
    this._userData = val;
    this.userDataChange.emit(this._userData);
  }
  @Output() userDataChange = new EventEmitter();

  public defaultPharmacy: any = {};

  constructor(
    private locationService: LocationService,
    private preferredPharmacyService: PreferredPharmacyService,
    private patientUserDataService: PatientUserDataService,
    private formBuilder: UntypedFormBuilder,
    private insigDialog: MatDialog,
    private snackBar: MatSnackBar,
    private googlePlaceService: GooglePlaceService,
  ) {}

  ngOnInit() {
    this.validationForm = this.buildValidationForm();
    this.getDefaultPharmacy();
  }

  ngAfterViewInit() {
    this.googlePlaceService.getPlaceResultObservable(this.addressElementRef.first.nativeElement).subscribe((placeResult) => {
      console.log('place: ', placeResult);
      this.validationForm.controls['address'].setValue(
        placeResult['formatted_address']
      );
      if (placeResult.address_components) {
        placeResult.address_components.forEach((item) => {
          try {
            if (item.types.includes('postal_code')) {
              this.validationForm.controls['postalCode'].setValue(
                item.long_name
              );
            }

            if (item.types.includes('country')) {
              this.validationForm.controls['country'].setValue(
                item.long_name
              );
            }

            if (item.types.includes('administrative_area_level_1')) {
              this.validationForm.controls['province'].setValue(
                item.short_name
              );
            }

            if (item.types.includes('locality')) {
              this.validationForm.controls['city'].setValue(
                item.long_name
              );
            }
          } catch (err) {
            console.log('error pre-filling adress: ', err);
          }
        });
      }
    });
  }

  buildValidationForm() {
    // setting preferred pharmacy for pharma partners
    const pharmacyFax = this.userData.pharmaFax || null;
    const pharmacyName = this.userData.pharmaName || null;

    return this.formBuilder.group(
      {
        first: this.formBuilder.control(this.userData.first || null, [
          Validators.required,
        ]),
        last: this.formBuilder.control(this.userData.last || null, [
          Validators.required,
        ]),
        email: this.formBuilder.control(this.userData.email || null, []),
        phone: this.formBuilder.control(this.userData.phone || null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(14),
        ]),
        extension: this.formBuilder.control(this.userData.extension || null),
        healthCardNumber: this.formBuilder.control(
          this.userData.healthCardNumber || null
        ),
        address: this.formBuilder.control(this.userData.address || null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        country: this.formBuilder.control(this.userData.country || null, [
          Validators.required,
        ]),
        province: this.formBuilder.control(this.userData.province || null, [
          Validators.required,
        ]),
        city: this.formBuilder.control(this.userData.city || null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        postalCode: this.formBuilder.control(this.userData.postalCode || null, [
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
        year: this.formBuilder.control(this.userData.year || null, [
          Validators.required,
          Validators.min(1900),
          Validators.max(this.today.getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(this.userData.month || null, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(this.userData.day || null, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(this.userData.gender || null, [
          Validators.required,
        ]),
        terms: this.formBuilder.control(this.userData.terms || false, [
          Validators.requiredTrue,
        ]),
        pharmaFax: this.formBuilder.control(pharmacyFax, [
          Validators.minLength(10),
          Validators.maxLength(14),
        ]),
        pharmaName: this.formBuilder.control(pharmacyName, [
          Validators.required,
        ]),
      },
      {
        validators: [],
      }
    );
  }

  async triggerPatientTerms() {
    console.log('adding new category');
    const dialogRef = this.insigDialog.open(PatientTermsDialogComponent);

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        this.validationForm.controls.terms.setValue(true);
      }
    });
    return dialogRef.afterClosed();
  }

  async getDefaultPharmacy() {
    const locationObj = await this.locationService.getRegion();
    this.defaultPharmacy = this.preferredPharmacyService.getDefaultPharmacyObj(
      locationObj
    );
    console.log(this.defaultPharmacy);
  }

  useDefaultPharmacy() {
    this.userData.pharmaName = this.defaultPharmacy.pharmaName;
    this.userData.pharmaFax = this.defaultPharmacy.pharmaFax;
    this.addingPharmacy = false;
    this.snackBar.open('Pharmacy selected!', null, { duration: 5000 });
  }

  checkFormValid(form) {
    if (form.controls['year'].value) {
      form.controls['year'].setValue(
        form.controls['year'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['month'].value) {
      form.controls['month'].setValue(
        form.controls['month'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['day'].value) {
      form.controls['day'].setValue(
        form.controls['day'].value.replace(/[^\d]/g, '')
      );
    }
    let message = '';
    if (!form.controls['first'].valid) {
      message = 'Please enter your first name!';
    } else if (!form.controls['last'].valid) {
      message = 'Please enter your last name!';
    } else if (!form.controls['phone'].valid) {
      message = 'Please enter a valid phone number!';
    } else if (
      !form.controls['year'].valid ||
      !form.controls['month'].valid ||
      !form.controls['day'].valid
    ) {
      message = 'Please enter a valid birthdate!';
    } else if (!form.controls['city'].valid) {
      message = 'Please enter a city!';
    } else if (!form.controls['address'].valid) {
      message = 'Please enter a valid address!';
    } else if (!form.controls['province'].valid) {
      message = 'Please enter a province!';
    } else if (!form.controls['postalCode'].valid) {
      message = 'Please enter a valid postal code!';
    } else if (!form.controls['gender'].valid) {
      message = 'Please enter your gender!';
    } else if (!form.controls['terms'].valid) {
      message = 'You must accept the Terms & Conditions to continue!';
    } else {
      form.controls['phone'].setValue(
        form.controls['phone'].value.replace(/[^\d]/g, '')
      );
      if (form.controls['phone'].value.length !== 10) {
        message = 'Please enter a valid phone number!';
        return { valid: false, message };
      }
      // form is valid so return true
      return { valid: true };
    }
    return { valid: false, message };
  }

  formSubmitted() {
    const check: any = this.checkFormValid(this.validationForm);
    this.userData = {};
    for (const field of Object.keys(this.validationForm.controls || {})) {
      this.userData[field] = this.validationForm.controls[field].value;
    }

    if (!check.valid) {
      // return { valid: false, message: check.message };
      this.snackBar.open('Error: ' + check.message, null, { duration: 4000 });
    } else {
      this.updatePatientInfo.emit();
    }
  }

  countryChanged(_event) {
    // If the country changed reset the province field value
    this.validationForm.controls.province.setValue(null);
  }

  openPillwayInfo() {
    this.insigDialog.open(PillwayInfoDialogComponent);
  }

}
