<section class="overflow-x-auto">
  <div class="container" id="topOfPage">
    <h3>Members</h3>

    <div class="row" *ngIf="!isFamilyMemberFormVisible && !familyMemberSelected">
      <div class="col-12">
        <hr />

        <div class="button-white" (click)="addFamilyMember(); scrollTop()">
          Add New Patient
        </div>

        <span *ngIf="familyMembersList && familyMembersList.length < 8">
          <div class="row" *ngFor="let member of familyMembersList">
            <div
              class="col-2 d-flex align-items-center pr-0 flex-row-reverse"
            ></div>
            <div class="col-8">
              <div
                class="my-0 button-white primary-color-full"
                (click)="openEditFamilyMember(member); scrollTop()"
              >
                {{ member.first }} {{ member.last }}
              </div>
            </div>
            <div class="col-2 d-flex align-items-center pl-0">
              <button
                mat-mini-fab
                color="none"
                (click)="deleteFamilyMember(member); scrollTop()"
                matTooltip="Delete Patient"
                [matTooltipPosition]="'above'"
              >
                <mat-icon class="material-icons">delete</mat-icon>
              </button>
            </div>
          </div>
        </span>

        <div
          class="text-center"
          *ngIf="familyMembersList && familyMembersList.length >= 8"
        >
          <hr />
          <mat-form-field class="w-50 mt-4">
            <mat-icon matPrefix class="material-icons">search</mat-icon>
            <mat-label>Search {{ familyMembersList.length }} Patients</mat-label>
            <input
              matInput
              #searchPatient
            />
          </mat-form-field>
          <span
            *ngIf="
              searchPatient &&
              searchPatient.value &&
              searchPatient.value.length > 1
            "
          >
            <div
              class="row"
              *ngFor="
                let member of familyMembersList
                  | searchpipe: 'fullName':searchPatient.value
              "
            >
              <div
                class="col-2 d-flex align-items-center pr-0 flex-row-reverse"
              ></div>
              <div class="col-8">
                <div
                  class="my-0 button-white primary-color-full"
                  (click)="openEditFamilyMember(member); scrollTop()"
                >
                  {{ member.first }} {{ member.last }}
                </div>
              </div>
              <div class="col-2 d-flex align-items-center pl-0">
                <button
                  mat-mini-fab
                  color="none"
                  (click)="deleteFamilyMember(member); scrollTop()"
                  matTooltip="Delete Patient"
                  [matTooltipPosition]="'above'"
                >
                  <mat-icon class="material-icons">delete</mat-icon>
                </button>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="!isFamilyMemberFormVisible">
      <div class="col-12">
        <h5 class="mt-0 mb-3" *ngIf="!editingFamilyMember">Add Patient</h5>
        <h5 class="mt-0 mb-3" *ngIf="editingFamilyMember">Edit Patient</h5>
        <hr />

        <form
          name="material_login_form"
          class="md-form-auth form-validation"
          [formGroup]="createFamilyMemberForm"
          onkeypress="return event.keyCode != 13;"
        >
          <div class="row">
            <div class="col-6 pr-1">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    required
                    formControlName="first"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-6 pl-1">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    required
                    formControlName="last"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 pr-1">
              <mat-form-field class="full-width">
                <mat-label>Address</mat-label>
                <input
                  required
                  matInput
                  #address
                  formControlName="address"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4 px-1">
              <mat-form-field class="full-width">
                <mat-label>City</mat-label>
                <input
                  required
                  matInput
                  formControlName="city"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Province</mat-label>
                <mat-select formControlName="province">
                  <mat-option
                    *ngFor="let province of provinces"
                    [value]="province.id"
                    >{{ province.name }}</mat-option
                  >
                  <mat-error>Required</mat-error>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3 pr-1">
              <mat-form-field class="full-width">
                <mat-label>Postal Code / Zip Code</mat-label>
                <input
                  required
                  matInput
                  minlength="5"
                  maxlength="7"
                  formControlName="postalCode"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>

            <div class="col-3 px-1">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Health Card Number</mat-label>
                  <input
                    matInput
                    formControlName="healthCardNumber"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="col-3 px-1">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Cell Phone Number</mat-label>
                  <input
                    matInput
                    minlength="10"
                    maxlength="14"
                    insig-phone-mask
                    required
                    type="tel"
                    formControlName="phone"
                  />
                  <mat-error>Please enter a 10-digit phone number</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="col-3 pl-1">
              <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>Ext.</mat-label>
                  <input
                    matInput
                    pattern="[0-9#*w]+"
                    autocomplete="off"
                    formControlName="extension"
                  />
                  <mat-error>(0-9, #, *)</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="text-left placeholder">Birthday</label>
            </div>
          </div>

          <div class="row">
            <div class="col-8 pr-1">
              <div class="row">
                <div class="col-4 pr-0">
                  <mat-form-field class="full-width">
                    <mat-label>YYYY</mat-label>
                    <input
                      type="tel"
                      required
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="4"
                      minlength="4"
                      max="{{ thisYear }}"
                      min="1900"
                      matInput
                      autocomplete="off"
                      formControlName="year"
                    />
                    <mat-error>Please enter a valid year</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-4 px-0">
                  <mat-form-field class="full-width">
                    <mat-label>MM</mat-label>
                    <input
                      type="tel"
                      required
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="2"
                      minlength="1"
                      matInput
                      autocomplete="off"
                      max="12"
                      min="1"
                      formControlName="month"
                    />
                    <mat-error>Please enter a valid month</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-4 pl-0">
                  <mat-form-field class="full-width">
                    <mat-label>DD</mat-label>
                    <input
                      type="tel"
                      required
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="2"
                      minlength="1"
                      matInput
                      autocomplete="off"
                      max="31"
                      min="1"
                      formControlName="day"
                    />
                    <mat-error>Please enter a valid day</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Gender At Birth</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="text-left placeholder" for="familyDoctorInputFields">Family Doctor</label>
            </div>
          </div>

          <div class="row" id="familyDoctorInputFields">
            <div class="col-4 pr-1">
              <mat-form-field class="full-width">
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  formControlName="familyDoctorFirstName"
                />
              </mat-form-field>
            </div>

            <div class="col-4 px-1">
              <mat-form-field class="full-width">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  formControlName="familyDoctorLastName"
                />
              </mat-form-field>
            </div>

            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Fax Number</mat-label>
                <input
                  matInput
                  minlength="10"
                  maxlength="14"
                  insig-phone-mask
                  type="tel"
                  formControlName="familyDoctorFaxNumber"
                />
                <mat-error>Please enter a 10-digit fax number</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="divider-md divider"></div>

        <div class="row mt-4">
          <div class="col-6">
            <button
              mat-raised-button
              class="full-width"
              (click)="isFamilyMemberFormVisible = false; scrollTop()"
            >
              Back
            </button>
          </div>
          <div class="col-6">
            <button
              mat-raised-button
              *ngIf="!editingFamilyMember"
              (click)="createFamilyMember(createFamilyMemberForm); scrollTop()"
              class="primary-color-full full-width"
            >
              Create
            </button>
            <button
              mat-raised-button
              *ngIf="editingFamilyMember"
              (click)="editFamilyMember(userID, editingFamilyMember, createFamilyMemberForm); scrollTop()"
              class="primary-color-full full-width"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <br />
  </div>
</section>

<br /><br />
