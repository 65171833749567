<div class="row no-gutters m-0 p-0 kiosk-bg full-height align-content-center">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Unfortunately, we could not find your appointment.
      </h4>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0">
      <div class="col-12 col-md-6 mx-auto">
        <div
          class="my-4 action-button primary-color-full"
          (click)="retryPlaceInLine.emit()"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Click Here to Retry!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
