// @ts-strict-ignore
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { addHours, differenceInMinutes } from 'date-fns';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  selector: 'insig-place-in-line-display',
  templateUrl: './place-in-line-display.component.html',
  styleUrls: ['./place-in-line-display.component.scss'],
})
export class PlaceInLineDisplayComponent implements OnInit, OnChanges {
  @Input() checkInID: string;
  @Input() currentAppointment: any;
  @Input() parsedAppointments: any;

  public waitTime: string;
  public notValidReadyStatuses = ['Here', 'Daysheet Printed', 'To Do'];

  constructor(private dateAndTimeService: DateAndTimeService) {}

  ngOnInit() {
    this.parseAppointment();
  }

  ngOnChanges(changes) {
    if (!!changes.currentAppointment || changes.parsedAppointments) {
      this.parseAppointment();
    }
  }

  parseAppointment() {
    if (!!this.currentAppointment && !!this.parsedAppointments) {
      if (this.currentAppointment.walkIn) {
        this.waitTime = this.calculateWaitTimeWalkIn();
      } else {
        this.waitTime = this.calculateWaitTimeAppointment();
      }
    }
  }

  calculateWaitTimeWalkIn(): string {
    let numberOfPatients = 0;
    for (const appt of this.parsedAppointments.walkIn) {
      if (appt.checkInID === this.checkInID) {
        return `${numberOfPatients}`;
      } else {
        if (
          !!appt.val &&
          !!appt.status &&
          !this.notValidReadyStatuses.includes(appt.status)
        ) {
          numberOfPatients += 1;
        }
      }
    }
  }

  calculateWaitTimeAppointment() {
    let returnStr = '';
    const timeZoneOffset = new Date().getTimezoneOffset();
    const now = new Date();
    let appointmentTime = new Date();
    appointmentTime = new Date(
      appointmentTime.setHours(
        parseInt(this.currentAppointment.time.split(':')[0], 10),
        parseInt(this.currentAppointment.time.split(':')[1], 10)
      )
    );
    const clinicTimeZoneOffset = Math.abs(this.dateAndTimeService.getUtcOffsetMinutes(new Date(), 'America/Vancouver'));
    // console.log(timeZoneOffset, clinicTimeZoneOffset)

    if (timeZoneOffset < clinicTimeZoneOffset) {
      const numberOfHours = Math.round(
        (clinicTimeZoneOffset - timeZoneOffset) / 60
      );
      // console.log(numberOfHours)
      appointmentTime = new Date(addHours(now, numberOfHours));
    }
    const difference = differenceInMinutes(appointmentTime, now);

    if (difference > 0) {
      returnStr = `${difference} mins.`;
    } else {
      returnStr = '10 mins.';
    }

    return returnStr;
  }
}
