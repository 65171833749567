// @ts-strict-ignore
import {
  Component,
  Input,
  HostListener,
  OnInit,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'insig-stepper',

  templateUrl: './insig-stepper.component.html',
  providers: [],
  styleUrls: ['./insig-stepper.component.scss'],
})
export class InsigStepperComponent implements OnInit, OnChanges {
  @Input() numberSteps: number;
  @Input() currentStep: number;
  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    // console.log(window.innerWidth)
    this.innerWidth = window.innerWidth;
  }
  public stepsArray = [];

  ngOnInit() {
    this.stepsArray = Array(this.numberSteps)
      .fill(this.numberSteps)
      .map((_x, i) => i);
  }

  getLeftPixelString(index) {
    console.log(`${index * 48}px;`);
    return `${index * 48}px;`;
  }
  ngOnChanges(change) {
    console.log(change);
  }
}
