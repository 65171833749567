import { Component, Output, EventEmitter } from '@angular/core';
import { APPURL } from '@insig-health/config/config';

@Component({
  selector: 'show-surveys-tablet-mode',

  templateUrl: './show-surveys-tablet-mode.component.html',
  providers: [],
})
export class ShowSurveysTabletModeComponent {
  @Output() startTabletMode = new EventEmitter<string>();
  public APPURL = APPURL;
}
