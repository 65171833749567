import { Routes, RouterModule } from '@angular/router';
import { SurveysComponent } from './surveys.component';
import { ShowSurveysComponent } from './surveys/show-surveys.component';
import { TabletModeComponent } from './tablet/tablet-mode.component';
import { CheckInModeComponent } from 'insig-app/check-in/check-in-mode/check-in-mode.component';
import { LaunchSurveyComponent } from './launch-survey/launch-survey.component';
import { EditSurveyComponent } from './edit-survey/edit-survey.component';

import { DeactivateGuardService } from '../services/deactivateGuard.service';
import { LaunchSurveyLoginComponent } from './launch-survey-login/launch-survey-login.component';

export const SurveysRoutes: Routes = [
  {
    path: '',
    component: SurveysComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: 'show-surveys', component: ShowSurveysComponent },
      { path: 'tablet-mode/:companyID', component: TabletModeComponent },
      { path: 'place-in-line/:companyID', component: CheckInModeComponent },
      {
        path: 'check-in-mode/:type/:companyID/:locationID/:userID/:surveyID',
        component: CheckInModeComponent,
      },
      {
        path: 'edit-survey/:companyID/:userID/:surveyID',
        component: EditSurveyComponent,
        canDeactivate: [DeactivateGuardService],
      },
      {
        component: LaunchSurveyLoginComponent,
        path: 'launch/:type/:companyID/:userID/:surveyID',
        children: [
          { path: 'loggedIn', component: LaunchSurveyComponent },
        ],
      },
      {
        component: LaunchSurveyLoginComponent,
        path: 'launch/:moreOptions/:type/:companyID/:userID/:surveyID',
        children: [
          { component: LaunchSurveyComponent, path: 'loggedIn' },
        ],
      },
    ],
  },
];

export const SurveysRoutingModule = RouterModule.forChild(SurveysRoutes);
