import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-check-in-reset-password',
  templateUrl: './check-in-reset-password.component.html',
  styleUrls: ['./check-in-reset-password.component.scss'],
})
export class CheckInResetPasswordComponent {
  @Output() checkInResetPasswordOption: EventEmitter<string> = new EventEmitter<
    string
  >();
}
