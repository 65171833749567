// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-check-in-error',
  templateUrl: './check-in-error.component.html',
  styleUrls: ['./check-in-error.component.scss'],
})
export class CheckInErrorComponent implements OnInit {
  @Output() resetScreen = new EventEmitter();
  @Input() patientDevice: boolean;

  ngOnInit() {
    setTimeout(() => {
      console.log('reset');
      if (!this.patientDevice) {
        this.resetScreen.emit();
      }
    }, 10000);
  }
}
