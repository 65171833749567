// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root'
})
export class SendSMSService {
  private sendSMSURL = AWSCLOUDFUNCTIONSENDPOINT + 'communications/sendSMS';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  sendSMS(phoneNumber, message): any {
    return this.http
      .post(
        this.sendSMSURL,
        {
          number: phoneNumber,
          body: message,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  } // end sendSMS func
} // end service1
