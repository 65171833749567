// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { Observable } from 'rxjs';
import { Conversation } from 'insig-types/virtual-care/messaging';

@Injectable({
  providedIn: 'root',
})
export class VirtualVisitService {
  private getVirtualVisitTokenURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/getVirtualVisitToken';
  private getTwilioCallDetailsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/getTwilioCallDetails';
  private initializeChatURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/initializeChat';
  private loadConversationAdditionalDetailsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/loadConversationAdditionalDetails';
  private sendMessageEmailSMSURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/sendMessageEmailSMS';
  private switchPhoneCallToVideoCallURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/switchPhoneCallToVideoCall';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  async initializeChat(appointmentID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.initializeChatURL,
          JSON.stringify({
            appointmentID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async switchPhoneCallToVideoCall(appointmentID: string, IDToken: string): Promise<{ message: string }> {
      return this.http
        .post<{ message: string }>(
          this.switchPhoneCallToVideoCallURL,
          JSON.stringify({
            appointmentID,
            IDToken
          }),
          { headers: this.headers }
        )
        .toPromise()
  } //end func

  async updateUnreadBy(conversationID, uid) {
    const chatSnapshot = await firebase
      .firestore()
      .collection('conversations/')
      .doc(conversationID)
      .get();
    if (
      chatSnapshot &&
      chatSnapshot.data() &&
      chatSnapshot.data().unreadBy &&
      chatSnapshot.data().unreadBy.length > 0
    ) {
      const unreadArray = chatSnapshot.data().unreadBy;
      const userIDInArrayIndex = unreadArray.indexOf(uid);
      // if user is in the unread array, remove them
      if (userIDInArrayIndex > -1) {
        unreadArray.splice(userIDInArrayIndex, 1);
        chatSnapshot.ref.update({ unreadBy: unreadArray });
      }
    } // end if
    // remove from notifSent once read
    if (
      chatSnapshot &&
      chatSnapshot.data() &&
      chatSnapshot.data().notifSent &&
      chatSnapshot.data().notifSent.length > 0
    ) {
      const notifSent = chatSnapshot.data().notifSent;
      const userIDInNotifSentArrayIndex = notifSent.indexOf(uid);
      // if user is in the unread array, remove them
      if (userIDInNotifSentArrayIndex > -1) {
        notifSent.splice(userIDInNotifSentArrayIndex, 1);
        chatSnapshot.ref.update({ notifSent });
      }
    }
  }

  async sendMessage(conversationID: string, message, uid) {
    // update unreadBy array
    try {
      const chatSnapshot = await firebase
        .firestore()
        .collection('conversations/')
        .doc(conversationID)
        .get();
      if (chatSnapshot && chatSnapshot.data() && chatSnapshot.data().users) {
        const users = chatSnapshot.data().users;
        const userIDInArrayIndex = users.indexOf(uid);
        if (userIDInArrayIndex > -1) {
          users.splice(userIDInArrayIndex, 1);
        }
        await firebase
          .firestore()
          .collection('conversations')
          .doc(conversationID)
          .update({
            unreadBy: users,
            lastMessage: new Date().getTime(),
            lastMessageBy: uid,
          });
      }
    } catch (err) {
      console.log(err);
    }
    // send msg
    await firebase
      .firestore()
      .collection('conversations')
      .doc(conversationID)
      .collection('messages')
      .add(message);
    // after 2 seconds send SMS & email notice about new messages
    try {
      const x = await this.sendMessageEmailSMS(conversationID);
      console.log(x);
    } catch (err) {
      console.log(err);
    } // end try-catch
  } // end func

  sendMessageEmailSMS(conversationID) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.sendMessageEmailSMSURL,
          JSON.stringify({
            conversationID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  // load name of message sender and medium of appt
  loadConversationAdditionalDetails(
    IDToken,
    conversationID,
    lastMessageBy
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.loadConversationAdditionalDetailsURL,
          JSON.stringify({
            IDToken,
            conversationID,
            lastMessageBy,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  checkUnreadMessages(userID) {
    console.log(userID);
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('conversations')
        .where('unreadBy', 'array-contains', userID)
        .orderBy('lastMessage', 'desc')
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.docs) {
              observer.next(snapshot.docs);
            } else {
              observer.next(undefined);
            }
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  } // end func

  loadConversation(conversationID: string): Observable<Conversation> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('conversations')
        .doc(conversationID)
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.data()) {
              observer.next(snapshot.data());
            } else {
              observer.next(undefined);
            }
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  } // end func

  loadMessages(conversationID) {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('conversations')
        .doc(conversationID)
        .collection('messages')
        .orderBy('date', 'asc')
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.docs) {
              observer.next(snapshot.docs);
            } else {
              observer.next(undefined);
            }
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  } // end func

  async getVirtualVisitToken(IDToken, linkID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.getVirtualVisitTokenURL,
          JSON.stringify({
            IDToken,
            linkID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  getTwilioCallDetails(callSID, linkID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.getTwilioCallDetailsURL,
          JSON.stringify({
            callSID,
            linkID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func
} // end service
