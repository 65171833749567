import { Component, Output, EventEmitter } from '@angular/core';
import { APPURL } from '@insig-health/config/config';
@Component({
  selector: 'show-surveys-check-in-mode',

  templateUrl: './show-surveys-check-in-mode.component.html',
  providers: [],
})
export class ShowSurveysCheckInModeComponent {
  @Output() startCheckInMode = new EventEmitter<string>();
  public APPURL = APPURL;
}
