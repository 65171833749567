<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Reset Password
      </h4>

      <h6 class="px-4 mx-auto roboto-desc">
        We will send you a link to reset your password on your phone
      </h6>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0 full-width">
      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full row"
          (click)="checkInResetPasswordOption.emit('phone')"
        >
          <div class="justify-content-center align-items-center flex row">
            <div class="m-auto">
              <span class="title mx-auto"
                >&nbsp;Reset password <br />
                on your phone</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full row"
          (click)="checkInResetPasswordOption.emit('none')"
        >
          <div class="justify-content-center align-items-center flex row">
            <div class="m-auto">
              <span class="title mx-auto">&nbsp;I don't have a phone</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
