<div *ngIf="question.allowMultiple">
  <div class="form-group row" [hidden]="question.response.noMeds">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-chip-listbox class="text-center">
        <div
          class="notranslate"
          *ngFor="let item of question.response.selectedAnswers"
        >
          <span class="space space-md"></span>
          <span color="primary">{{ item }} &nbsp;</span>
          <button mat-mini-fab color="none" (click)="removeItem(item)">
            <mat-icon class="notranslate">clear</mat-icon>
          </button>
        </div>
      </mat-chip-listbox>
    </div>
    <div class="col-2"></div>
  </div>

  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-form-field class="full-width" [hidden]="question.response.noMeds">
        <mat-label>Enter medication name</mat-label>
        <input
          matInput
          (keyup.enter)="addItem(question.response.selectedAnswers[-1])"
          [(ngModel)]="question.response.selectedAnswers[-1]"
          [matAutocomplete]="auto"
          #itemsSelect
          [formControl]="itemsCtrl"
        />
      </mat-form-field>
    </div>
    <div class="col-2"></div>
    <div class="col-8 offset-2 d-flex align-items-center mt-3">
      <mat-checkbox
        [(ngModel)]="question.response.noMeds"
        (click)="noMeds(question.response.noMeds)"
        class="center"
        >No Medications</mat-checkbox
      >
    </div>
    <div class="col-2"></div>
  </div>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let item of filteredItems | async"
      (click)="addItem(item)"
      [value]="item"
    >
      <mat-icon class="notranslate">add_circle_outline</mat-icon
      ><span class="notranslate">{{ item }}</span>
    </mat-option>
    <mat-option
      *ngIf="question.response.selectedAnswers[-1] != null"
      (click)="addItem(question.response.selectedAnswers[-1])"
      [value]="question.response.selectedAnswers[-1]"
    >
      <mat-icon class="notranslate">add_circle_outline</mat-icon
      ><span class="notranslate">{{
        question.response.selectedAnswers[-1]
      }}</span>
    </mat-option>
  </mat-autocomplete>
</div>

<div *ngIf="!question.allowMultiple">
  <div class="form-group row">
    <div class="col-2"></div>
    <div class="col-8">
      <mat-form-field class="full-width" [hidden]="question.response.noMeds">
        <mat-label>Enter medication name</mat-label>
        <input
          matInput
          [(ngModel)]="question.response.selectedAnswers[0]"
          [matAutocomplete]="auto"
          #itemsSelect
          [formControl]="itemsCtrl"
        />
      </mat-form-field>
    </div>
    <div class="col-2"></div>
    <div class="col-8 offset-2 d-flex align-items-center mt-3">
      <mat-checkbox
        [(ngModel)]="question.response.noMeds"
        (click)="noMeds(question.response.noMeds)"
        class="center"
        >No Medication</mat-checkbox
      >
    </div>
    <div class="col-2"></div>
  </div>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <span class="notranslate">{{ item }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
