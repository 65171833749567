<div class="row no-gutters m-0 p-0 kiosk-bg align-content-center">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="pb-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Who is being seen?
      </h4>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0 full-width">
      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="patientSelected.emit('myself')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Myself</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="patientSelected.emit('family')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Someone else</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
