/**
 * Insig Booking Flow APIs
 * The specification contains all booking flow APIs
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DoctorFacetSearchData } from '../model/doctorFacetSearchData';
// @ts-ignore
import { DoctorSearchData } from '../model/doctorSearchData';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { Province } from '../model/province';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DoctorDataSearchESService {

    protected basePath = 'http://localhost:8000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get All Doctor Data Based On Different Query Parameters
     * This API is used to GET the doctor and doctor\&#39;s slot data based on the faceted search parameters
     * @param province Province filter, uppercase two-letter abbreviation
     * @param billingType used to get doctors permitted in either the public or private flow. The values will be public or private. If no value is specified then the default value will be public.
     * @param companyId companyId parameter
     * @param doctorId doctorId parameter
     * @param planId planId parameter
     * @param groupBillingNumberIfAny Match with doctors that have this group billing number, or no group billing number
     * @param q Used to search Doctor\&#39;s Name Or Speciality on right top search text field
     * @param filters Filter query param can be used to aggregate the results and send the response back to the UI. The format of this query string is comma separated filter/facet values. example - {filterName}:[{Comma Separated Array Of Filter Values}]. The entire value must be URL encoded.
     * @param includeNoFutureAvailability Set to **1** if you want to include doctors in the result set that have no future availability. Otherwise set **0** or ignore. Currently only applies to finding doctors when including facets.
     * @param slotData Set to **1** if you want to get slot data. Otherwise Set **0** or ignore.
     * @param limit Is the count of Doctors needed for the query. If not specified the default limit is 20.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDoctorDataForParams(province: Province, billingType?: string, companyId?: string, doctorId?: string, planId?: string, groupBillingNumberIfAny?: string, q?: string, filters?: string, includeNoFutureAvailability?: number, slotData?: number, limit?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DoctorFacetSearchData>;
    public getDoctorDataForParams(province: Province, billingType?: string, companyId?: string, doctorId?: string, planId?: string, groupBillingNumberIfAny?: string, q?: string, filters?: string, includeNoFutureAvailability?: number, slotData?: number, limit?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DoctorFacetSearchData>>;
    public getDoctorDataForParams(province: Province, billingType?: string, companyId?: string, doctorId?: string, planId?: string, groupBillingNumberIfAny?: string, q?: string, filters?: string, includeNoFutureAvailability?: number, slotData?: number, limit?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DoctorFacetSearchData>>;
    public getDoctorDataForParams(province: Province, billingType?: string, companyId?: string, doctorId?: string, planId?: string, groupBillingNumberIfAny?: string, q?: string, filters?: string, includeNoFutureAvailability?: number, slotData?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (province === null || province === undefined) {
            throw new Error('Required parameter province was null or undefined when calling getDoctorDataForParams.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (province !== undefined && province !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>province, 'province');
        }
        if (billingType !== undefined && billingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>billingType, 'billingType');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'companyId');
        }
        if (doctorId !== undefined && doctorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>doctorId, 'doctorId');
        }
        if (planId !== undefined && planId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>planId, 'planId');
        }
        if (groupBillingNumberIfAny !== undefined && groupBillingNumberIfAny !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupBillingNumberIfAny, 'groupBillingNumberIfAny');
        }
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'filters');
        }
        if (includeNoFutureAvailability !== undefined && includeNoFutureAvailability !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeNoFutureAvailability, 'includeNoFutureAvailability');
        }
        if (slotData !== undefined && slotData !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>slotData, 'slotData');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/booking-availability/doctor/`;
        return this.httpClient.get<DoctorFacetSearchData>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get One Doctor\&#39;s search data
     * This API is used to GET one doctor\&#39;s search data
     * @param doctorId doctorId parameter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDoctorSearchData(doctorId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DoctorSearchData>;
    public getDoctorSearchData(doctorId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DoctorSearchData>>;
    public getDoctorSearchData(doctorId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DoctorSearchData>>;
    public getDoctorSearchData(doctorId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (doctorId === null || doctorId === undefined) {
            throw new Error('Required parameter doctorId was null or undefined when calling getDoctorSearchData.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/booking-availability/doctor/${this.configuration.encodeParam({name: "doctorId", value: doctorId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.get<DoctorSearchData>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
