// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { take } from 'rxjs/operators';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'insig-check-in-login-sign-up',
  templateUrl: './check-in-login-sign-up.component.html',
  styleUrls: ['./check-in-login-sign-up.component.scss'],
  providers: [PatientUserDataService],
})
export class CheckInLoginSignUpComponent implements OnInit {
  // graphql queries
  private patientDataMutation = gql`
    mutation patientDataMutation(
      $pid: ID!
      $token: ID!
      $data: PatientUserInput!
    ) {
      setPatientData(pid: $pid, token: $token, data: $data) {
        first
        last
        phone
        extension
        email
        address
        gender
        healthCardNumber
        pharmaName
        pharmaFax
        year
        month
        day
      }
    }
  `;

  public loading = false;
  @Input() companyData: any;

  public _loggedIn = false;

  @Input() get loggedIn() {
    // this.parseVirtualArray()
    return this._loggedIn;
  }
  set loggedIn(val) {
    this._loggedIn = val;
    this.loggedInChange.emit(this._loggedIn);
  }
  @Output() loggedInChange = new EventEmitter();

  @Output() patientLoggedIn = new EventEmitter();
  @Output() patientInfoEntered = new EventEmitter();
  @Output() goToForgotPassword = new EventEmitter();
  @Output() signedUpForFamilyMember = new EventEmitter();
  @Input() skipLogin = false;
  @Input() familyMemberNeeded = false;
  @Input() goToValidation = false;
  @Input() goToResetPassword = false;
  @Input() patientDevice = false;
  public forgotPasswordSubmitted = false;
  public signingUp = false;
  public forgotPassword = false;
  private familyUserData: any;

  public forgotPasswordEmail = '';
  public email = '';
  public password = '';
  public userID: string;
  public validateInsigInformation = false;
  public userData: any;
  public hideFieldsSignUp = {
    platformDesc: true,
    signUpDesc: true,
    middleName: true,
    phoneExtension: true,
    pharmacy: true,
    logo: true,
  };

  public hideFields = {
    phoneExtension: true,
    backButton: true,
    pharmacy: true,
  };
  public textReplacements = {
    updateButton: 'Confirm',
    title: 'Please Confirm Your Information',
  };
  private IDToken: string;
  private alreadyValidatedFamily = false;
  private cameFromSignUp = false;

  constructor(
    private snackbar: MatSnackBar,
    private patientUserDataService: PatientUserDataService,
    private firebaseAuthService: FirebaseAuthService,
    private gcpIpAuthService: GcpIpAuthService,
    private apollo: Apollo
  ) {}

  ngOnInit() {
    console.log(this.goToResetPassword);

    if (!!this.goToResetPassword && !!this.patientDevice) {
      this.forgotPassword = true;
    } else {
      this.forgotPassword = false;
    }

    if (this.skipLogin) {
      this.userData = {};
      this.validateInformation();
      this.scrollTop();
    } else if (this.goToValidation) {
      this.userID = this.gcpIpAuthService.getCurrentUser()?.uid;
      this.validateInformation();
    }

    if (!this.goToValidation) {
      this.gcpIpAuthService.signOut();
    }
  }

  async validateInformation() {
    console.log('validating information');
    if (!this.skipLogin) {
      try {
        this.loading = true;
        this.userData = await this.patientUserDataService
          .getPatientData(this.userID)
          .pipe(take(1))
          .toPromise();
        this.loading = false;
        this.validateInsigInformation = true;
      } catch (err) {
        console.log('error logging in: ', err);
        this.loading = false;
        this.snackbar.open(
          'You must log in with a patient account to check in!',
          null,
          { duration: 4000 }
        );
      }
    } else {
      this.validateInsigInformation = true;
    }
  }

  async informationValidated() {
    if (!this.skipLogin) {
      try {
        await this.updatePatientInfo();
      } catch (err) {
        console.log('error updating patient info: ', err);
      }
      const uid = await this.gcpIpAuthService.getCurrentUser()?.uid;
      this.loading = true;
      this.patientLoggedIn.emit(uid);
    } else {
      this.loading = true;
      this.patientInfoEntered.emit(this.userData);
    }
  }

  generateBlankPatientData(email) {
    return {
      first: '',
      last: '',
      address: '',
      gender: 'Male',
      phone: '',
      year: '',
      month: '',
      day: '',
      email,
    } as const;
  }

  async updatePatientInfo() {
    console.log(this.userData);
    const userData = this.userData;
    const newUserData = {};
    if (userData['first']) {
      newUserData['first'] = userData['first'];
    }
    if (userData['last']) {
      newUserData['last'] = userData['last'];
    }
    if (userData['address']) {
      newUserData['address'] = userData['address'];
    }
    if (userData['gender']) {
      newUserData['gender'] = userData['gender'];
    }
    if (userData['year']) {
      userData['year'] = userData['year'].toString().replace(/[^\d]/g, '');
      if (
        userData['year'].length === 4 &&
        userData['year'] > 1900 &&
        userData['year'] < new Date().getFullYear()
      ) {
        newUserData['year'] = userData['year'];
      }
    }
    if (userData['month']) {
      userData['month'] = userData['month'].toString().replace(/[^\d]/g, '');
      if (
        userData['month'].length > 0 &&
        userData['month'] >= 0 &&
        userData['month'] < 13
      ) {
        newUserData['month'] = userData['month'];
      }
    }
    if (userData['day']) {
      userData['day'] = userData['day'].toString().replace(/[^\d]/g, '');
      if (
        userData['day'].length > 0 &&
        userData['day'] >= 1 &&
        userData['day'] < 13
      ) {
        newUserData['day'] = userData['day'];
      }
    }
    if (userData['phone']) {
      userData['phone'] = userData['phone'].replace(/[^\d]/g, '');
      if (userData['phone'].length === 10) {
        newUserData['phone'] = userData['phone'];
      }
    }
    if (userData['pharmaFax']) {
      userData['pharmaFax'] = userData['pharmaFax'].replace(/[^\d]/g, '');
      if (userData['pharmaFax'].length === 10) {
        newUserData['pharmaFax'] = userData['pharmaFax'];
      } else {
        newUserData['pharmaFax'] = null;
      }
    } else {
      newUserData['pharmaFax'] = null;
    }
    if (userData['pharmaName']) {
      newUserData['pharmaName'] = userData['pharmaName'];
    } else {
      newUserData['pharmaName'] = null;
    }
    if (userData['healthCardNumber']) {
      newUserData['healthCardNumber'] = userData['healthCardNumber'];
    } else {
      newUserData['healthCardNumber'] = null;
    }
    if (userData['postalCode']) {
      if (
        userData['postalCode'].length > 4 &&
        userData['postalCode'].length < 8
      ) {
        newUserData['postalCode'] = userData['postalCode'];
      }
    }
    if (userData['province']) {
      newUserData['province'] = userData['province'];
    }
    if (userData['city']) {
      newUserData['city'] = userData['city'];
    }
    if (userData['extension']) {
      newUserData['extension'] = userData['extension'];
    } else {
      newUserData['extension'] = null;
    }

    console.log(newUserData);

    await this.mutatePatientData(this.IDToken, this.userID, newUserData);
  }

  async mutatePatientData(token, pid, newUserData) {
    // console.log(token, pid, newUserData);
    try {
      await this.apollo
        .mutate({
          mutation: this.patientDataMutation,
          variables: {
            pid,
            token,
            data: newUserData,
          },
        })
        .toPromise();
      this.snackbar.open('Information updated!', null, { duration: 3000 });
    } catch (error) {
      console.error(error);
      this.snackbar.open(
        'Error updating information! Please check all fields.',
        null,
        { duration: 3000 }
      );
    }
  }

  async signIn(): Promise<void> {
    const firebaseUser = this.gcpIpAuthService.getCurrentUser();
    console.log(firebaseUser);
    this.IDToken = await firebaseUser.getIdToken();
    this.userID = firebaseUser.uid;

    if (this.alreadyValidatedFamily) {
      console.log('create family account flow');
      try {
        await this.patientUserDataService.createFamilyMember(
          this.familyUserData,
          firebaseUser.uid
        );
      } catch (err) {
        console.error('error adding family member', err);
      }

      this.loading = true;
      this.signedUpForFamilyMember.emit(this.familyUserData);
    } else if (this.cameFromSignUp) {
      this.loading = true;
      this.patientLoggedIn.emit(firebaseUser.uid);
    } else if (!this.familyMemberNeeded) {
      this.validateInformation();
    } else {
      this.loading = true;
      this.patientLoggedIn.emit(firebaseUser.uid);
    }
    this.loading = false;
  } // end sign in

  triggerSignUp() {
    console.log('triggering sign up');
    this.password = null;
    this.email = null;
    this.signingUp = true;
  }

  scrollTop() {
    if (document.getElementById('topOfCheckInPage')) {
      document.getElementById('topOfCheckInPage').scrollIntoView(true);
    }
  }

  async onSignUp(signUpForm) {
    console.log(signUpForm);

    this.loading = true;
    this.snackbar.open('Please wait while we create your account...', null, {
      duration: 4000,
    });

    let signed;

    if (this.familyMemberNeeded) {
      const check: any = this.patientUserDataService.checkFormValid(signUpForm, {
        medium: 'inPerson',
      });
      if (!check.valid) {
        this.snackbar.open(
          'Error adding patient...Please check your information',
          null,
          { duration: 4000 }
        );
      }
      const patientUserData: any = {};
      for (const field of Object.keys(signUpForm.controls || {})) {
        patientUserData[field] = signUpForm.controls[field].value;
      }
      console.log('familyUserData: ', patientUserData);

      this.familyUserData = patientUserData;

      const fakePatientData = this.generateBlankPatientData(
        signUpForm.controls['email'].value
      );
      console.log(fakePatientData);
      try {
        const newAccountUid = await this.patientUserDataService.createPatientUserProxy(
          fakePatientData,
          fakePatientData.email,
          signUpForm.controls.password.value
        );
        signed = { valid: true };
        /** @firestore Firestore migration */
        // signed = await this.patientUserDataService.createPatientAccount(
        //   fakePatientData,
        //   signUpForm.controls['password'].value
        // );
        this.alreadyValidatedFamily = true;
        this.email = signUpForm.controls['email'].value;
        this.password = signUpForm.controls['password'].value;
        this.snackbar.open('Your account has been created!', null, {
          duration: 4000,
        });

      } catch (err) {
        this.snackbar.open('Error: Please check your information');
        this.loading = false;
      }
    } else {
      signed = await this.patientUserDataService.createPatientAccountFromForm(
        signUpForm,
        { medium: 'inPerson' }
      );
      if (signed.valid) {
        this.email = signed.email;
        this.password = signed.password;
        this.snackbar.open('Your account has been created!', null, {
          duration: 4000,
        });

      } else {
        this.snackbar.open('Error: ' + signed.message, null, {
          duration: 4000,
        });
        this.loading = false;
      }
    }
  } // end singup func

  backToLogin() {
    this.signingUp = false;
  }

  forgotPasswordClicked() {
    console.log(this.patientDevice);
    if (!this.patientDevice) {
      this.goToForgotPassword.emit();
    } else {
      this.forgotPassword = true;
    }
  }

  async requestPasswordReset(): Promise<void> {
    console.log('password reset clicked: ', this.forgotPasswordEmail);
    try {
      await this.firebaseAuthService.sendPasswordResetEmail(this.forgotPasswordEmail);
      this.snackbar.open('Email sent!', null, { duration: 4000 });
      this.forgotPasswordSubmitted = true;

      setTimeout(() => {
        this.forgotPassword = false;
        this.forgotPasswordSubmitted = false;
      }, 10000);
    } catch (error) {
      console.error(error);
      this.snackbar.open(error.message || 'Error, email not found!', null, {
        duration: 4000,
      });
    }
  }

  goBackForgotPassword(): void {
    this.forgotPassword = false;
  }

  showSignUp(event: boolean): void {
    this.signingUp = true;
  }

  showForgetPassword(event: boolean): void {
    this.forgotPassword = true;
  }

  redirect(event: string | null): void {
    if (event !== null) {
      this.signIn();
    }
  }
}
