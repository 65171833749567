<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Thank you, an SMS message was sent to your phone with a link to complete
        your check-in!
      </h4>

      <h4 class="px-4 mx-auto roboto-title">
        If you do not receive a message within a few minutes, please try again
        and ensure your phone number is entered correctly.
      </h4>
    </div>
  </div>
</div>
