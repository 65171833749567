<div class="row no-gutters m-0 p-0 kiosk-bg full-height align-content-center">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Your  check-in number  is:
      </h4>

      <h4 class="px-4 mx-auto roboto-title-large">
        {{ checkInID }}
      </h4>
    </div>
  </div>

  <div
    class="col-12 align-self-center justify-content-center kiosk-title"
    *ngIf="!!currentAppointment"
  >
    <div class="p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        {{
          !!currentAppointment.walkIn
            ? 'Number of patients in front of you: '
            : 'Approximate time until your appointment: '
        }}
      </h4>

      <h4 class="px-4 mx-auto roboto-title-large">
        {{ waitTime }}
      </h4>
    </div>
  </div>
</div>
