// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/compat/app';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OscarService {
  private createOscarIntegrationURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/oscar/createIntegration';
  private deleteOscarIntegrationURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/oscar/deleteIntegration';
  private getAllDoctorsForCompanyURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/oscar/getAllDoctorsForCompany';
  private oscarGetPatientIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/oscar/getPatientID';
  private checkInPatientURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/oscar/checkInPatient';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  getOscarCombinedEMRSettings(userID, companyID): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const companyEMRSettingsRef = firebase
        .firestore()
        .collection('companyEMRSettings')
        .doc(companyID);

      const companyEMRSettings: any = await new Promise(async (resolve) => {
        try {
          const doc = await companyEMRSettingsRef.get();
          if (doc.exists) {
            console.log('Document data:', doc.data());
            resolve(doc.data());
          } else {
            console.log('No such document!');
            // throw new Error("user not authenticated");
            resolve(false);
          }
        } catch (error) {
          console.error('Error getting document:', error);
          // throw error;
          resolve(false);
        }
      });

      const doctorEMRSettingsRef = firebase
        .firestore()
        .collection('emrSettings')
        .doc(userID);

      const doctorEMRSettings: any = await new Promise(async (resolve) => {
        try {
          const doc = await doctorEMRSettingsRef.get();
          if (doc.exists) {
            console.log('Document data:', doc.data());
            resolve(doc.data());
          } else {
            console.log('No such document!');
            // throw new Error("user not authenticated");
            resolve(false);
          }
        } catch (error) {
          console.error('Error getting document:', error);
          // throw error;
          resolve(false);
        }
      });

      if (companyEMRSettings) {
        if (doctorEMRSettings) {
          resolve({ ...companyEMRSettings, ...doctorEMRSettings });
        } else {
          resolve(companyEMRSettings);
        }
      } else {
        resolve(false);
      }
    });
  }

  getOscarCompanyEMRSettings(companyID): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const companyEMRSettingsRef = firebase
        .firestore()
        .collection('companyEMRSettings')
        .doc(companyID);

      const companyEMRSettings: any = await new Promise(async (resolve) => {
        try {
          const doc = await companyEMRSettingsRef.get();
          if (doc.exists) {
            console.log('Document data:', doc.data());
            resolve(doc.data());
          } else {
            console.log('No such document!');
            // throw new Error("user not authenticated");
            resolve(false);
          }
        } catch (error) {
          console.log('Error getting document:', error);
          // throw error;
          resolve(false);
        }
      });

      resolve(companyEMRSettings);
    });
  }

  getOscarDoctorEMRSettings(userID): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const doctorEMRSettingsRef = firebase
        .firestore()
        .collection('emrSettings')
        .doc(userID);

      const doctorEMRSettings: any = await new Promise(async (resolve) => {
        try {
          const doc = await doctorEMRSettingsRef.get();
          if (doc.exists) {
            console.log('Document data:', doc.data());
            resolve(doc.data());
          } else {
            console.log('No such document!');
            // throw new Error("user not authenticated");
            resolve(false);
          }
        } catch (error) {
          console.log('Error getting document:', error);
          // throw error;
          resolve(false);
        }
      });

      resolve(doctorEMRSettings);
    });
  }
  // TO DO function coming soon
  getAllDoctorsForCompany(companyID): Promise<{ doctors: any[] }> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.getAllDoctorsForCompanyURL,
          {
            companyID,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(JSON.parse(response));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func

  getOscarPatientID(
    companyID,
    locationID,
    patientData,
    create = false,
    update = true
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.oscarGetPatientIDURL,
          {
            companyID,
            locationID,
            patientData,
            create,
            update,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(JSON.parse(response));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func

  deleteOscarIntegration(userID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.deleteOscarIntegrationURL,
          {
            userID,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func

  createOscarIntegration(
    userID,
    oscarBaseURL,
    oscarDefaultProviderID,
    oscarPassword,
    oscarPin,
    oscarUserName
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.createOscarIntegrationURL,
          {
            userID,
            oscarBaseURL,
            oscarDefaultProviderID,
            oscarPassword,
            oscarPin,
            oscarUserName,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func

  getDoctorMetadata(
    doctorID,
    dateID
  ): Observable<firebase.firestore.DocumentData> {
    return new Observable<firebase.firestore.DocumentData>((observer) => {
      return firebase
        .firestore()
        .collection('doctorEMRData/')
        .doc(doctorID)
        .collection('metadata')
        .doc(dateID)
        .onSnapshot((snapshot) => observer.next(snapshot.data()));
    });
  }

  checkInPatient(
    IDToken,
    companyID,
    locationID,
    doctorID,
    patientData,
    time,
    date,
    checkInID,
    walkIn,
    currentTime,
    oscarPatientID,
    createAppointment: boolean,
    timeZoneOffset: number,
    start: number,
    familyID?: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.checkInPatientURL,
          {
            IDToken,
            companyID,
            locationID,
            doctorID,
            patientData,
            date,
            time,
            checkInID,
            walkIn,
            currentTime,
            oscarPatientID,
            createAppointment,
            familyID,
            start,
            timeZoneOffset,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

} // end service1
