// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as htmlToRTF from './rtf/rtf-gen';
import * as browserImageSize from 'browser-image-size';
import Pica from 'pica';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { InitNoteService } from 'insig-app/services/initNote.service';
import { NotesService } from 'insig-app/services/notes.service';

import { take } from 'rxjs/operators';
import { AdmittedPatient, NotePutBody, SpringNoteLargeStringKey } from 'insig-types/spring-api/notes';
import { NoteIdentifiers } from './notes.service.types';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Injectable({
  providedIn: 'root',
})
export class SaveNoteService {
  private pica = Pica();

  constructor(
    private http: HttpClient,
    private initNoteService: InitNoteService,
    private notesService: NotesService,
  ) {
    this.initNoteService = initNoteService;
    this.notesService = notesService;
  }

  async putNoteToSpring(identifiers: { companyId: string, noteId: string }, note: NotePutBody & { admittedPatient?: AdmittedPatient }): Promise<void> {
    if (note.admittedPatient) {
      note.admittedPatient.firstName = note.admittedPatient.first ?? note.admittedPatient.firstName;
      note.admittedPatient.lastName = note.admittedPatient.last ?? note.admittedPatient.lastName;
    }

    if (note.patientInfo) {
      note.patientInfo.firstName = note.patientInfo.first;
      note.patientInfo.lastName = note.patientInfo.last;
    }

    if (!!note.patientInfo?.uid && !!note.patientUid) {
      if (note.patientInfo.uid !== note.patientUid) {
        throw new Error('patientInfo and patientUid UID mismatch when posting note');
      }
    }
    
    const { companyId, noteId } = identifiers;

    note = await this.preNoteSaveOperations(identifiers, note);

    // check if note string has changed
    const isNoteStringChanged = await this.isNoteStringChanged(identifiers, note.note ?? '');

    await this.http.put(`${JAVA_BACKEND_ENDPOINT}company/${companyId}/note/${noteId}/`, note, {
      responseType: 'text',
      params: {
        isNoteStringChanged: `${isNoteStringChanged}`,
      },
    }).pipe(take(1)).toPromise();
  }

  private async preNoteSaveOperations(identifiers: { companyId: string, noteId: string }, note: NotePutBody): Promise<NotePutBody> {
    const { companyId, noteId } = identifiers;

    if (note.addedDocs) {
      const promiseArray = [];
      for (const document of note.addedDocs) {
        if (
          coerceBooleanProperty(document.custom) &&
          (document.type !== 'sti' && document.type !== 'fit')
        ) {
          promiseArray.push(
            this.notesService.generatePDFRequisition(
              document,
              companyId,
              noteId
            )
          );
        }
      }
      await Promise.all(promiseArray);
    }

    // regenrate rtf from note
    note.rtf = this.initNoteService.htmlToRTF(note.note);

    return note;
  }

  private async isNoteStringChanged(identifiers: NoteIdentifiers, newNoteString: string): Promise<boolean> {
    try {
      const oldNoteString = await this.notesService.getLargeStringFromSpringNote(identifiers, SpringNoteLargeStringKey.NOTE);
      return oldNoteString !== newNoteString;
    } catch (error) {
      if (error.status === 404) {
        return true;
      }
      throw error;
    }
  }

  /**
   * Converts the note html into an rtf readable format. Copied from initNote service to reduce injection redundancy.
   * @param html The string to convert from
   * @param compressed No idea why this param exists
   * @return The note as an rtf readable string
   */
  htmlToRTF(html?: string, compressed?: boolean) {
    if (!html) {
      html = '';
    }
    if (compressed) {
      html = html
        .split('&nbsp;')
        .join(' ')
        .split('✓')
        .join('\\uc0\\u10004');
    } else {
      html = html
        .split('&nbsp;')
        .join(' ')
        .split('<h3>')
        .join('<br><h3>')
        .split('✓')
        .join('\\uc0\\u10004');
    }
    let rtf = htmlToRTF.htmlToRTF(html);
    if (compressed) {
      rtf = rtf
        .split('fs20')
        .join('fs16')
        .split('fs22')
        .join('fs20');
    }
    return rtf;
  }

  async resizeImageFromDataUri(
    dataUri: string,
    maxSize: number
  ): Promise<string> {
    const dimensions = await browserImageSize(dataUri);
    if (dimensions.width > maxSize || dimensions.height > maxSize) {
      const inputImage = new Image();
      await new Promise((resolve) => {
        inputImage.src = dataUri;
        inputImage.onload = resolve;
      }); // Wait until image load completes

      const canvas = document.createElement('canvas');
      canvas.width =
        dimensions.width >= dimensions.height
          ? maxSize
          : Math.floor((dimensions.width * maxSize) / dimensions.height);
      canvas.height =
        dimensions.width < dimensions.height
          ? maxSize
          : Math.floor((dimensions.height * maxSize) / dimensions.width);

      await this.pica.resize(inputImage, canvas, {
        unsharpAmount: 160,
        unsharpRadius: 0.6,
        unsharpThreshold: 1,
      });

      // Create a new data URI using canvas
      const outputUri = canvas.toDataURL();

      return outputUri;
    } else {
      // No resize necessary
      return dataUri;
    }
  }

  processAllergyData(data) {
    const newData = data;
    // rhinitis field
    if (data.rhinitis) {
      newData['rhinitis' + this.capitalize(data.rhinitis)] = '✔';
    }

    // conjuctivitis
    if (data.conjunctivitis) {
      newData['conjunctivitis' + this.capitalize(data.conjunctivitis)] = '✔';
    }

    // asthma
    if (data.asthma) {
      newData['asthma' + this.capitalize(data.asthma)] = '✔';
    }

    if (data.timing) {
      newData['timing' + this.capitalize(data.timing)] = '✔';
    }

    // allergens
    if (data.allergens) {
      for (const a in data.allergens) {
        if (data.allergens[a].treatment) {
          newData[a + 'Treat'] = '✔';
          newData[a + 'Order'] =
            this.capitalize(data.allergens[a].treatment) +
            ' ' +
            data.allergens[a][data.allergens[a].treatment];
        }
      }
    }

    // treatment options
    if (data.treatmentOptions) {
      newData['treatment' + this.capitalize(data.treatmentOptions)] = '✔';
    }

    // payment method
    if (data.paymentMethod) {
      newData['payment' + this.capitalize(data.paymentMethod)] = '✔';
      if (data.paymentMethod === 'ohip') {
        newData.paymentOther = '✔';
        newData.otherPaymentMethod = 'OHIP+';
      }
    }

    // vials
    if (data.vialsInSet) {
      newData['vials' + data.vialsInSet] = '✔';
    }

    // treatment stage
    if (data.treatmentStage) {
      newData['treatmentStage' + this.capitalize(data.treatmentStage)] = '✔';
    }

    delete newData.setAArr;
    delete newData.setBArr;
    delete newData.setCArr;
    delete newData.allergens;

    return newData;
  }

  capitalize(inputString: string) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end service
