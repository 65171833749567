<section [hidden]="smsKiosk" class="page-with-tabs h-100 overflow-y-auto">
  <div class="box-body">
    <mat-slide-toggle
      (click)="changeLoopSurvey()"
      [(ngModel)]="loopSurvey"
      color="primary"
      >Loop Questionnaire</mat-slide-toggle
    >
    <button
      mat-raised-button
      color="primary"
      class="float-right"
      (click)="changeDoctor(doctorList)"
    >
      Change Practitioner
    </button>
  </div>
  <div class="page-title">
    <h2>Select a Questionnaire</h2>
  </div>

  <mat-tab-group>
    <mat-tab label="Library">
      <mat-form-field class="w-100 px-2 py-4">
        <mat-icon matPrefix class="material-icons">search</mat-icon>
        <mat-label>search</mat-label>
        <input matInput #searchLibrary />
      </mat-form-field>
      <div class="row mx-0">
        <div
          class="col-xl-4 col-lg-6"
          *ngFor="
            let survey of librarySurveys
              | searchpipe: 'name':searchLibrary.value
          "
        >
          <div class="card bg-color-white">
            <button
              mat-button
              (click)="startSMSKiosk('library', companyID, 'library', survey)"
            >
              <div class="card-content">
                <span class="card-title">{{ survey.name }}</span>
              </div>
            </button>
            <div class="card-action">
              <button
                mat-button
                matTooltip="Start Tablet Mode"
                [matTooltipPosition]="'above'"
                (click)="launchSurvey('library', companyID, 'library', survey)"
              >
                <mat-icon>tablet_mac</mat-icon>
              </button>
              <button
                mat-button
                matTooltip="Start SMS Kiosk Mode"
                class="float-right"
                [matTooltipPosition]="'above'"
                (click)="startSMSKiosk('library', companyID, 'library', survey)"
              >
                <mat-icon>textsms</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="{{
        selectedDoctor ? selectedDoctor.last : 'Practitioner'
      }}'s Questionnaires"
    >
      <div class="box-body text-center" *ngIf="!selectedDoctor">
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div class="divider"></div>
        <button
          mat-raised-button
          color="primary"
          class="btn-w-lg btn-lg"
          (click)="changeDoctor(doctorList)"
        >
          Select a Practitioner
        </button>
      </div>

      <div class="col-12" *ngIf="selectedDoctor">
        <article class="article">
          <div class="box box-transparent">
            <div class="box-body">
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="full-width mat-icon-left">
                    <mat-icon matPrefix class="material-icons">search</mat-icon>
                    <mat-label>search</mat-label>
                    <input matInput #searchSurveys />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </article>
        <div class="row">
          <div
            class="col-xl-4 col-lg-6"
            *ngFor="
              let survey of doctorSurveys
                | searchpipe: 'name':searchSurveys.value
            "
          >
            <div class="card bg-color-white">
              <button
                mat-button
                (click)="
                  startSMSKiosk('user', companyID, selectedDoctor.uid, survey)
                "
              >
                <div class="card-content">
                  <span class="card-title">{{ survey.name }}</span>
                </div>
              </button>
              <div class="card-action">
                <button
                  mat-button
                  matTooltip="Start Tablet Mode"
                  [matTooltipPosition]="'above'"
                  (click)="
                    launchSurvey('user', companyID, selectedDoctor.uid, survey)
                  "
                >
                  <mat-icon>tablet_mac</mat-icon>
                </button>
                <button
                  mat-button
                  matTooltip="Start SMS Kiosk Mode"
                  class="float-right"
                  [matTooltipPosition]="'above'"
                  (click)="
                    startSMSKiosk('user', companyID, selectedDoctor.uid, survey)
                  "
                >
                  <mat-icon>textsms</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>

<section
  *ngIf="smsKiosk"
  style="height: 100vh;background-repeat: no-repeat;background-position: bottom left;background-image: url(light.svg),linear-gradient(to bottom,rgba(33,150,255,.1),transparent);"
>
  <button
    (click)="smsKiosk = false; phoneNumber = null"
    mat-button
    class="ml-3 pl-0 mt-2 btn-w-md"
  >
    <mat-icon class="material-icons">keyboard_arrow_left</mat-icon> Return to
    Surveys
  </button>

  <div class="text-center mt-5">
    <img
      class="mr-3 mb-4"
      src="{{
        companyData?.branding
          ? companyData.branding
          : 'assets/images/global/logofullnormal.svg'
      }}"
      style="max-height:100px;max-width:250px;"
    />

    <div class="d-flex justify-content-center">
      <div class="w-fit-content">
        <div class="card mt-5">
          <div class="card-content">
            <div class="main-content text-center">
              <h4 style="line-height: 45px;" class="bold">
                Please enter your cell phone number to receive our Intake
                Questionnaire by SMS.
              </h4>
              <div class="form-group form-field mt-5">
                <mat-form-field *ngIf="!tempHide">
                  <span matPrefix>
                    <mat-icon class="notranslate material-icons">phone</mat-icon
                    >&nbsp;
                  </span>
                  <mat-label>Cell Phone Number</mat-label>
                  <input
                    id="smsInput"
                    matInput
                    insig-phone-mask
                    required
                    autocomplete="off"
                    minlength="10"
                    maxlength="14"
                    type="tel"
                    [(ngModel)]="phoneNumber"
                  />
                  <mat-error
                    >Please enter a valid 10-digit phone number</mat-error
                  >
                </mat-form-field>
              </div>
              <button
                mat-raised-button
                class="mt-3"
                color="primary"
                [disabled]="
                  tempHide ||
                  !phoneNumber ||
                  phoneNumber.replace(digitsRegex, '').length < 10
                "
                (click)="sendSurveySMS()"
              >
                Send SMS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
