<mat-card>
  <div class="dialogSize">
    <h1 matDialogTitle class="text-center">Launch</h1>
    <div mat-dialog-content>
      <h3 class="text-center">{{ surveyName }}</h3>
      <div class="divider"></div>
      <div class="divider"></div>
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        class="float-right"
        (click)="dialogRef.close(true)"
      >
        Confirm
      </button>
    </div>
  </div>
</mat-card>
