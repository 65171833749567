import { Routes, RouterModule } from '@angular/router';
import { CheckInComponent } from './check-in.component';

import { CheckInModeComponent } from 'insig-app/check-in/check-in-mode/check-in-mode.component';

export const CheckInRoutes: Routes = [
  {
    path: '',
    component: CheckInComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: 'place-in-line/:companyID', component: CheckInModeComponent },
      {
        path: 'check-in-mode/:type/:companyID/:locationID/:userID/:surveyID',
        component: CheckInModeComponent,
      },
    ],
  },
];

export const CheckInRoutingModule = RouterModule.forChild(CheckInRoutes);
