import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalPatientModule } from 'insig-app/global/patient/global-patient.module';
import { InsigStepperModule } from 'insig-app/global/insig-stepper/insig-stepper.module';
import { InsigAppLoaderModule } from '../global/loader/loader.module';
import { InsigMaterialModule } from '../insig-material/insig-material.module';
import { MaterialComponentsModule } from '../material-components.module';
import { PipesModule } from '../pipes/pipes.module';
import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';

import { CheckInRoutingModule } from './check-in-routing.module';

import { CheckInComponent } from './check-in.component';
import { CheckInModeComponent } from './check-in-mode/check-in-mode.component';
import { CheckInSelectOptionComponent } from './check-in-mode/check-in-select-option/check-in-select-option.component';
import { CheckInEnterPhoneComponent } from './check-in-mode/check-in-enter-phone/check-in-enter-phone.component';
import { CheckInPhoneConfirmationComponent } from './check-in-mode/check-in-phone-confirmation/check-in-phone-confirmation.component';
import { CheckInLoginSignUpComponent } from './check-in-mode/check-in-login-sign-up/check-in-login-sign-up.component';
import { CheckInPatientSelectComponent } from './check-in-mode/check-in-patient-select/check-in-patient-select.component';
import { CheckInNumberConfirmationComponent } from './check-in-mode/check-in-number-confirmation/check-in-number-confirmation.component';
import { CheckInDisplayComponent } from './check-in-mode/check-in-display/check-in-display.component';
import { CheckInResetPasswordComponent } from './check-in-mode/check-in-reset-password/check-in-reset-password.component';
import { CheckInFamilyMemberSelectComponent } from './check-in-mode/check-in-family-member-select/check-in-family-member-select.component';
import { CheckInPlaceInLineComponent } from './check-in-mode/check-in-place-in-line/check-in-place-in-line.component';
import { PlaceInLineSelectComponent } from './check-in-mode/check-in-place-in-line/place-in-line-select/place-in-line-select.component';
import { PlaceInLineCheckInInputComponent } from './check-in-mode/check-in-place-in-line/place-in-line-check-in-input/place-in-line-check-in-input.component';
import { PlaceInLineDisplayComponent } from './check-in-mode/check-in-place-in-line/place-in-line-display/place-in-line-display.component';
import { PlaceInLineNothingFoundComponent } from './check-in-mode/check-in-place-in-line/place-in-line-nothing-found/place-in-line-nothing-found.component';
import { CheckInErrorComponent } from './check-in-mode/check-in-error/check-in-error.component';

// Libs
import { UiModule } from '@insig-health/ui';
import { AuthModule } from 'insig-app/auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    GlobalPatientModule,
    InsigStepperModule,
    InsigAppLoaderModule,
    InsigMaterialModule,
    MaterialComponentsModule,
    PipesModule,
    InputMasksModule,
    FormsModule,
    ReactiveFormsModule,
    CheckInRoutingModule,
    UiModule,
    AuthModule,
  ],
  declarations: [
    CheckInComponent,
    CheckInModeComponent,
    CheckInSelectOptionComponent,
    CheckInEnterPhoneComponent,
    CheckInPhoneConfirmationComponent,
    CheckInLoginSignUpComponent,
    CheckInPatientSelectComponent,
    CheckInPatientSelectComponent,
    CheckInNumberConfirmationComponent,
    CheckInDisplayComponent,
    CheckInResetPasswordComponent,
    CheckInFamilyMemberSelectComponent,
    CheckInPlaceInLineComponent,
    PlaceInLineSelectComponent,
    PlaceInLineCheckInInputComponent,
    PlaceInLineDisplayComponent,
    PlaceInLineNothingFoundComponent,
    CheckInErrorComponent,
  ],
  exports: [
    CheckInModeComponent,
    CheckInSelectOptionComponent,
    CheckInEnterPhoneComponent,
    CheckInPhoneConfirmationComponent,
    CheckInLoginSignUpComponent,
    CheckInPatientSelectComponent,
    CheckInPatientSelectComponent,
    CheckInNumberConfirmationComponent,
    CheckInDisplayComponent,
    CheckInResetPasswordComponent,
    CheckInFamilyMemberSelectComponent,
    CheckInPlaceInLineComponent,
    PlaceInLineSelectComponent,
    PlaceInLineCheckInInputComponent,
    PlaceInLineDisplayComponent,
    PlaceInLineNothingFoundComponent,
  ],
})
export class CheckInModule {}
