// @ts-strict-ignore
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Apollo, gql } from 'apollo-angular';

import { Observable, zip, Subject } from 'rxjs';

import firebase from 'firebase/compat/app';
import { startOfMonth, endOfMonth } from 'date-fns';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { FirebaseAuthService } from './firebase-auth/firebase-auth.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { Appointment } from '@insig-health/insig-types/app/notes/patient-note';
import { MILLISECONDS_PER_DAY } from '@insig-health/services/date-and-time/date-and-time.constants';

const MINUTESMULTIPLIER = 60000;

interface Colleague {
  first: string;
  last: string;
  uid: string;
  company: string;
  valid: boolean;
  acceptVirtual: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        title
        first
        last
      }
    }
  `;

  private userLeadTimeQuery = gql`
    query UserLeadTimeQuery($userID: ID!) {
      getUserData(uid: $userID) {
        leadTime {
          value
          increment
        }
      }
    }
  `;

  private userDataNotifBookedMutation = gql`
    mutation UserDataNotifBookedMutation(
      $userID: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userID, token: $token, data: $data) {
        uid
        noNotifBookedAppt
      }
    }
  `;

  private userDataReferralRequiredMutation = gql`
    mutation userDataReferralRequiredMutation(
      $userId: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userId, token: $token, data: $data) {
        uid
        referralRequired
      }
    }
  `;

  private preCallUserMutation = gql`
    mutation preCallUserMutation(
      $userId: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userId, token: $token, data: $data) {
        noPreCall
      }
    }
  `;

  private autoCallMutation = gql`
    mutation autoCallMutation(
      $userId: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userId, token: $token, data: $data) {
        uid
        noAutoCall
      }
    }
  `;

  private userDataCancellationNotificationsMutation = gql`
    mutation userDataCancellationNotificationsMutation(
      $userId: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userId, token: $token, data: $data) {
        uid
        isReceivingCancellationNotifications
      }
    }
  `;

  private userDataNotifRemindersMutation = gql`
    mutation userDataNotifRemindersMutation(
      $userID: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userID, token: $token, data: $data) {
        uid
        noNotifAppointmentReminder
      }
    }
  `;

  private userDataLeadTimeMutation = gql`
    mutation UserDataLeadTimeMutation(
      $userID: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userID, token: $token, data: $data) {
        uid
        leadTime {
          value
          increment
        }
      }
    }
  `;

  private companyUserListQuery = gql`
    query CompanyUserListQuery($companyID: ID!) {
      getCompanyUserList(cid: $companyID) {
        first
        last
        uid
        valid
        acceptVirtual
        company
      }
    }
  `;

  private createAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/createSchedulerAppointment';
  private createGroupCodeAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/createGroupCodeAppointment';
  private rebookAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/rebookAppointment';
  private followUpAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'book-appointment/followUpAppointment';
  private getPublicAppointmentInfoURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/getPublicAppointmentInfo';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private timeNow = () => new Date().getTime();
  private leadTimeDefault = { value: 15, increment: 'Minutes' } as const;

  constructor(
    private http: HttpClient,
    private firestoreService: FirestoreService,
    private firebaseAuthService: FirebaseAuthService,
    private apollo: Apollo,
    private dateAndTimeService: DateAndTimeService,
  ) {}

  async getLeadTimeObject(userID) {
    try {
      const userLeadTimeQuery: any = await this.apollo
        .query({
          query: this.userLeadTimeQuery,
          variables: {
            userID,
          },
        })
        .toPromise();
      const userDataObject = userLeadTimeQuery.data.getUserData;
      if (
        userDataObject &&
        userDataObject.leadTime &&
        userDataObject.leadTime.value != null &&
        userDataObject.leadTime.value !== undefined &&
        userDataObject.leadTime.increment
      ) {
        return userDataObject.leadTime;
      } else {
        return this.leadTimeDefault;
      }
    } catch (error) {
      switch (error.code) {
        default:
          console.log(error);
          break;
        case 'PERMISSION_DENIED':
          console.log('Wrong uid. User may have logged out.');
          break;
      }
      return this.leadTimeDefault;
    }
  }

  getLeadTimeDefaultObject() {
    return this.leadTimeDefault;
  }

  getLeadTimeValue(
    leadTimeObj: {
      increment: 'Minutes' | 'Hours' | 'Days';
      value: number;
    } = this.leadTimeDefault
  ): number {
    try {
      if (leadTimeObj.increment === 'Minutes') {
        return this.timeNow() + leadTimeObj.value * MINUTESMULTIPLIER;
      } else if (leadTimeObj.increment === 'Hours') {
        return this.timeNow() + leadTimeObj.value * MINUTESMULTIPLIER * 60;
      } else if (leadTimeObj.increment === 'Days') {
        return this.timeNow() + leadTimeObj.value * MINUTESMULTIPLIER * 60 * 24;
      }
    } catch (error) {
      console.log(error);
    }
    return this.timeNow() + this.leadTimeDefault.value * MINUTESMULTIPLIER;
  }

  async followUpAppointment(
    bookingObj: object,
    IDToken: string,
    newDateTime: object,
    newProviderID: string
  ): Promise<any> {
    return this.http
      .post(
        this.followUpAppointmentURL,
        JSON.stringify({
          bookingObj,
          IDToken,
          newDateTime,
          newProviderID,
        }),
        { headers: this.headers }
      )
      .toPromise();
  }

  async rebookAppointment(
    IDToken,
    linkID,
    newDateTime,
    newProviderID?
  ): Promise<any> {
    return this.http
      .post(
        this.rebookAppointmentURL,
        JSON.stringify({
          IDToken,
          linkID,
          newDateTime,
          newProviderID,
        }),
        { headers: this.headers }
      )
      .toPromise();
  }

  async toggleNoNotifApptReminders(userID, value) {
    console.log(userID);

    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        noNotifAppointmentReminder: value,
      };
      const result: any = await this.apollo
        .mutate({
          mutation: this.userDataNotifRemindersMutation,
          variables: {
            userID,
            token,
            data,
          },
        })
        .toPromise();

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }

  async toggleNoNotifBookedAppt(userID, value) {
    console.log(userID);

    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        noNotifBookedAppt: value,
      };
      const result: any = await this.apollo
        .mutate({
          mutation: this.userDataNotifBookedMutation,
          variables: {
            userID,
            token,
            data,
          },
        })
        .toPromise();

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }

  async togglePreCallUser(userId: string, value: boolean): Promise<void> {
    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        noPreCall: value,
      };
      await this.apollo
        .mutate({
          mutation: this.preCallUserMutation,
          variables: {
            userId,
            token,
            data,
          },
        })
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async toggleAutoCall(userId: string, value: boolean): Promise<void> {
    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        noAutoCall: value,
      };
      await this.apollo
        .mutate({
          mutation: this.autoCallMutation,
          variables: {
            userId,
            token,
            data,
          },
        })
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async setReferralRequired(userId: string, value: boolean): Promise<void> {
    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        referralRequired: value,
      };
      await this.apollo
        .mutate({
          mutation: this.userDataReferralRequiredMutation,
          variables: {
            userId,
            token,
            data,
          },
        })
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async setCancellationNotificationPreference(userId: string, value: boolean): Promise<void> {
    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        isReceivingCancellationNotifications: value,
      };
      await this.apollo
        .mutate({
          mutation: this.userDataCancellationNotificationsMutation,
          variables: {
            userId,
            token,
            data,
          },
        })
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async saveLeadTime(userID, value, increment) {
    console.log(userID);
    const leadTimeObject = {
      value,
      increment,
    };
    console.log(leadTimeObject);

    try {
      const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      const data = {
        leadTime: leadTimeObject,
      };
      const result = await this.apollo
        .mutate({
          mutation: this.userDataLeadTimeMutation,
          variables: {
            userID,
            token,
            data,
          },
        })
        .toPromise();
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }

  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  createAppointment(appointmentConfig): any {
    return this.http
      .post(
        this.createAppointmentURL,
        {
          appointmentConfig,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createGroupCodeAppointment(appointmentConfig): any {
    return this.http
      .post(
        this.createGroupCodeAppointmentURL,
        {
          appointmentConfig,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCompanyBookingsMonth(companyID: string, date: Date): Observable<any[]> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('companyID', '==', companyID)
        .where('bookingDate', '>=', firstDay)
        .where('bookingDate', '<=', lastDay)
        .orderBy('bookingDate', 'desc')
    );
  }

  getCompanyAppointmentsMonth(
    companyID: string,
    date: Date
  ): Observable<any[]> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('companyID', '==', companyID)
        .where('event.start', '>=', firstDay)
        .where('event.start', '<=', lastDay)
        .orderBy('event.start', 'desc')
    );
  }

  getDoctorAppointmentsDayCID(
    doctorId: string,
    cid,
    date: Date
  ): Observable<any[]> {
    const today = new Date(date.getTime());
    today.setHours(0, 0, 0, 0);
    const todayTime = new Date(today.getTime()).getTime();

    const tomorrow = new Date(date.getTime());
    tomorrow.setHours(23, 59, 59, 999);
    const tomorrowTime = new Date(tomorrow.getTime()).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('doctorID', '==', doctorId)
        .where('doctorCompany', '==', cid)
        .where('event.start', '>=', todayTime)
        .where('event.start', '<=', tomorrowTime)
        .orderBy('event.start', 'desc')
    );
  }

  getDoctorAppointmentsDay(doctorId: string, date: Date): Observable<any[]> {
    const today = new Date(date.getTime());
    today.setHours(0, 0, 0, 0);
    const todayTime = new Date(today.getTime()).getTime();

    const tomorrow = new Date(date.getTime());
    tomorrow.setHours(23, 59, 59, 999);
    const tomorrowTime = new Date(tomorrow.getTime()).getTime();

    return this.firestoreService.getQueryAsObservable<{ event: { start: number } }>(
      firebase.firestore().collection('appointments')
        .where('doctorID', '==', doctorId)
        .where('event.start', '>=', todayTime)
        .where('event.start', '<=', tomorrowTime)
        .orderBy('event.start', 'desc')
    ).pipe(
      map((appointments) => {
        return appointments.filter((appointment) => {
          return new Date(appointment.event.start).getDate() === date.getDate();
        });
      }),
    );
  }

  /**
   * @param  doctorId  [description]
   * @param  companyId
   * @param  date      [description]
   * @return           [description]
   */
  // get surrounding days, to show appts on darkened part of calendar
  getDoctorAppointmentsByIdByMonthAndSurroundingDays(
    doctorId: string,
    companyId: string,
    date: Date
  ): Observable<Appointment[]> {
    const firstDay =
      startOfMonth(date).getTime() -
      1000 * 60 * 60 * 24 * startOfMonth(date).getDay();
    const lastDay =
      endOfMonth(date).getTime() +
      1000 * 60 * 60 * 24 * (6 - endOfMonth(date).getDay());
    try {
      return new Observable<firebase.firestore.QuerySnapshot>((observer) => {
        return firebase
          .firestore()
          .collection('appointments')
          .where('doctorID', '==', doctorId)
          .where('doctorCompany', '==', companyId)
          .where('event.start', '>=', firstDay)
          .where('event.start', '<=', lastDay)
          .orderBy('event.start', 'desc')
          .onSnapshot(
            (snapshot) => {
              observer.next(snapshot);
            },
            (error) => {
              observer.error(error);
            },
          );
      }).pipe(
        map(
          (snapshot) => snapshot.docs.map((doc) => doc.data() as Appointment)
        ),
        catchError((error) => {
          console.error(error);
          throw error;
        }),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * @param  doctorId  [description]
   * @param  companyId
   * @param  date      [description]
   * @return           [description]
   */
  getDoctorAppointmentsByIdByMonth(
    doctorId: string,
    companyId: string,
    date: Date
  ): Observable<any[]> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('doctorID', '==', doctorId)
        .where('doctorCompany', '==', companyId)
        .where('event.start', '>=', firstDay)
        .where('event.start', '<=', lastDay)
        .orderBy('event.start', 'desc')
    );
  }

  createKeywords = (name) => {
    const arrName = [];
    const curName = name.toLowerCase();
    arrName.push(curName);
    arrName.push(curName.toUpperCase());
    arrName.push(curName.charAt(0).toUpperCase() + curName.substring(1));
    arrName.push(curName + ' ');
    arrName.push(curName.toUpperCase() + ' ');
    arrName.push(curName.charAt(0).toUpperCase() + curName.substring(1) + ' ');

    return arrName;
  };

  generateKeywords = (patientSearch) => {
    const names = patientSearch.split(' ');
    let set = new Set(['']);

    // let currentStr = ``;
    for (const name of names) {
      const tempArray = this.createKeywords(`${name}`);
      set = new Set([...set, ...tempArray]);
    }
    return [...set];
  };

  generateSearchQueries(patientID: string, term: string) {
    const tempQueryUpperFirst = new Subject();
    const tempQueryUpperLast = new Subject();
    const tempQueryLowerFirst = new Subject();
    const tempQueryLowerLast = new Subject();

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.first', '>=', term.toUpperCase())
      .where('data.first', '<=', term.toUpperCase() + 'ZZ')
      .onSnapshot((snapshot) => tempQueryUpperFirst.next(snapshot));

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.last', '>=', term.toUpperCase())
      .where('data.last', '<=', term.toUpperCase() + 'ZZ')
      .onSnapshot((snapshot) => tempQueryUpperLast.next(snapshot));

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.first', '>=', term)
      .where('data.first', '<=', term + 'zz')
      .onSnapshot((snapshot) => tempQueryLowerFirst.next(snapshot));

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.last', '>=', term)
      .where('data.last', '<=', term + 'zz')
      .onSnapshot((snapshot) => tempQueryLowerLast.next(snapshot));

    return [
      tempQueryUpperFirst,
      tempQueryUpperLast,
      tempQueryLowerFirst,
      tempQueryLowerLast,
    ];
  }

  getSearchedPatientAppointments(
    patientID: string,
    patientSearch: string
  ): Observable<any[]> {
    let queryArray = this.generateKeywords(patientSearch);
    queryArray = queryArray.slice(Math.max(queryArray.length - 10, 1));
    console.log('queryArray: ', queryArray);

    // var queryFirst$ =
    const queryFirst$ = new Subject();
    const queryLast$ = new Subject();

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.first', 'in', queryArray)
      .onSnapshot((snapshot) => queryFirst$.next(snapshot));

    firebase
      .firestore()
      .collection('familyMembers')
      .where('patientID', '==', patientID)
      .where('data.last', 'in', queryArray)
      .onSnapshot((snapshot) => queryLast$.next(snapshot));

    const arrayOfTerms = patientSearch.split(' ');

    let otherQueries = [];

    for (const term of arrayOfTerms) {
      otherQueries = otherQueries.concat(
        this.generateSearchQueries(patientID, term)
      );
      // otherQueries.push(tempQuery);
    }

    //
    return zip(queryFirst$, queryLast$, ...otherQueries).pipe(
      map((latestQuery: any[]) => {
        const [firstNameQuery, lastNameQuery, ...otherQueries] = latestQuery;
        console.log(
          'firstNameQuery, lastNameQuery: ',
          firstNameQuery,
          lastNameQuery,
          otherQueries
        );

        let queries = [
          // spread the arrays out to combine as one array
          ...firstNameQuery.docs,
          ...lastNameQuery.docs,
        ];

        for (const query of otherQueries) {
          // console.log("query: ", query, query.docs)
          queries = queries.concat(query.docs);
        }

        return queries;
      }),
      map((familyMembersSnapshot) => {
        console.log('familyMembersSnapshot: ', familyMembersSnapshot);
        return Promise.all(
          familyMembersSnapshot.map((doc) => {
            const id = doc.id;
            const familyData = { ...doc.data(), id };
            console.log('familymember: ', familyData);
            return firebase
              .firestore()
              .collection('appointments')
              .where('patientID', '==', patientID)
              .where('familyID', '==', familyData.id)
              .orderBy('event.start', 'desc')
              .get()
              .then((apptSnapshot) => {
                const appointments = [];
                for (const appt of apptSnapshot.docs.map((doc) => doc.data())) {
                  appointments.push({
                    ...appt,
                    familyData,
                  });
                }
                return appointments;
              });
          })
        );
      }),
      switchMap(async (appointments) => {
        const flattenedAppts = await appointments.then((appts) => appts.flat());
        return Promise.all(
          flattenedAppts.map((appt) => {
            const doctorID = appt.doctorID;
            return this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: doctorID,
                },
              })
              .toPromise()
              .then((doctor: any) => ({
                ...appt,
                doctor: doctor.data.getUserData,
              }));
          })
        );
      }),
      map((appointments) => {
        console.log('appointments at end: ', appointments);
        return appointments.sort((a: any, b: any) => {
          return parseInt(b.event.start, 10) - parseInt(a.event.start, 10);
        });
      })
    );
  }

  getMorePatientAppointments(
    patientID: string,
    nextStart: firebase.firestore.QueryDocumentSnapshot
  ): Observable<{
    appts: any[];
    lastApptRef: firebase.firestore.QueryDocumentSnapshot;
  }> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientID)
        .orderBy('event.start', 'desc')
        .startAt(nextStart)
        .limit(30)
        .onSnapshot(async (snapshot) => {
          const appointmentArray = [];
          // Attach doctor name to data
          // let nextStart = snapshot.docs[snapshot.docs.length - 1];
          const doctorPromiseArray = [];
          for (const data of snapshot.docs.map((doc: any) => doc.data())) {
            appointmentArray.push(data);
            const doctorQuery: any = this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: data.doctorID,
                },
              })
              .toPromise();
            doctorPromiseArray.push(doctorQuery);
          }
          try {
            const resolvedArray = await Promise.all(doctorPromiseArray);
            for (let i = 0; i < resolvedArray.length; i++) {
              appointmentArray[i].doctor = resolvedArray[i].data.getUserData;

              // get family member for appointment
              if (appointmentArray[i].familyID) {
                appointmentArray[i].familyData = (
                  await firebase
                    .firestore()
                    .collection('familyMembers/')
                    .doc(appointmentArray[i].familyID)
                    .get()
                ).data();
              }
              // end get family member
            }
            observer.next({ appts: appointmentArray, lastApptRef: nextStart });
          } catch (error) {
            console.error(error); // Keep error trace
            observer.error(error); // Pass error up; should not be handled on service level
          }
        });
    });
  }

  getAllPatientAppointments(
    patientID: string
  ): Observable<{
    appts: any[];
    lastApptRef: firebase.firestore.QueryDocumentSnapshot;
  }> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientID)
        .orderBy('event.start', 'desc')
        .limit(30)
        .onSnapshot(async (snapshot) => {
          const appointmentArray = [];
          // Attach doctor name to data
          const nextStart = snapshot.docs[snapshot.docs.length - 1];
          const doctorPromiseArray = [];
          for (const data of snapshot.docs.map((doc: any) => doc.data())) {
            appointmentArray.push(data);
            const doctorQuery: any = this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: data.doctorID,
                },
              })
              .toPromise();
            doctorPromiseArray.push(doctorQuery);
          }
          try {
            const resolvedArray = await Promise.all(doctorPromiseArray);
            for (let i = 0; i < resolvedArray.length; i++) {
              appointmentArray[i].doctor = resolvedArray[i].data.getUserData;

              // get family member for appointment
              if (appointmentArray[i].familyID) {
                appointmentArray[i].familyData = (
                  await firebase
                    .firestore()
                    .collection('familyMembers/')
                    .doc(appointmentArray[i].familyID)
                    .get()
                ).data();
              }
              // end get family member
            }
            observer.next({ appts: appointmentArray, lastApptRef: nextStart });
          } catch (error) {
            console.error(error); // Keep error trace
            observer.error(error); // Pass error up; should not be handled on service level
          }
        });
    });
  }

  getPatientAppointmentsByDay(
    patientID: string,
    date: Date
  ): Observable<any[]> {
    const dateStart = new Date(date.getTime());
    dateStart.setHours(0, 0, 0, 0);

    const dateEnd = new Date(date.getTime());
    dateEnd.setHours(23, 59, 59, 999);

    return new Observable<firebase.firestore.QuerySnapshot>((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientID)
        .where('event.start', '>=', dateStart.getTime())
        .where('event.start', '<=', dateEnd.getTime())
        .orderBy('event.start', 'desc')
        .onSnapshot((snapshot) => observer.next(snapshot));
    }).pipe(
      map(
        (snapshot) =>
          snapshot.docs
            .map((doc) => doc.data()) // Convert to field data
            .filter(
              (data) => new Date(data.event.start).getDate() === date.getDate()
            ) // Check date in local time
      ),
      switchMap((appointmentDatas) => {
        return Promise.all(
          appointmentDatas.map((appointmentData) => {
            const doctorQuery: any = this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: appointmentData.doctorID,
                },
              })
              .toPromise();
            return doctorQuery.then((doctorData) => ({
              ...appointmentData,
              doctor: doctorData.data.getUserData,
            }));
          })
        );
      })
    );
  }

  getPatientBookingsByMonthDashboard(
    patientID: string,
    date: Date
  ): Observable<any[]> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('patientID', '==', patientID)
        .where('bookingDate', '>=', firstDay)
        .where('bookingDate', '<=', lastDay)
        .orderBy('bookingDate', 'desc')
    );
  }

  getPatientAppointmentsByMonthDashboard(
    patientID: string,
    date: Date
  ): Observable<any[]> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('patientID', '==', patientID)
        .where('event.start', '>=', firstDay)
        .where('event.start', '<=', lastDay)
        .orderBy('event.start', 'desc')
    );
  }

  getPatientAppointmentsByMonth(
    patientID: string,
    date: Date
  ): Observable<any> {
    const firstDay = startOfMonth(date).getTime();
    const lastDay = endOfMonth(date).getTime();

    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientID)
        .where('event.start', '>=', firstDay)
        .where('event.start', '<=', lastDay)
        .orderBy('event.start', 'asc')
        .onSnapshot(async (snapshot) => {
          const appointmentArray = [];
          // Attach doctor name to data
          const doctorPromiseArray = [];
          for (const data of snapshot.docs.map((doc) => doc.data())) {
            appointmentArray.push(data);

            const doctorQuery: any = this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: data.doctorID,
                },
              })
              .toPromise();
            doctorPromiseArray.push(doctorQuery);
          }
          try {
            const resolvedArray = await Promise.all(doctorPromiseArray);
            for (let i = 0; i < resolvedArray.length; i++) {
              appointmentArray[i].doctor = resolvedArray[i].data.getUserData;
            }
            observer.next(appointmentArray);
          } catch (error) {
            console.error(error); // Keep error trace
            observer.error(error); // Pass error up; should not be handled on service level
          }
        });
    });
  } // end func

  getPatientAppointmentsAfterUnixMs(
    patientID: string,
    unixMs: number
  ): Observable<any> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientID)
        .where('event.start', '>=', unixMs)
        .orderBy('event.start', 'asc')
        .onSnapshot(async (snapshot) => {
          const appointmentArray = [];
          // Attach doctor name to data
          const doctorPromiseArray = [];
          for (const data of snapshot.docs.map((doc) => doc.data())) {
            appointmentArray.push(data);

            const doctorQuery: any = this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: data.doctorID,
                },
              })
              .toPromise();
            doctorPromiseArray.push(doctorQuery);
          }
          try {
            const resolvedArray = await Promise.all(doctorPromiseArray);
            for (let i = 0; i < resolvedArray.length; i++) {
              appointmentArray[i].doctor = resolvedArray[i].data.getUserData;
            }
            observer.next(appointmentArray);
          } catch (error) {
            console.error(error); // Keep error trace
            observer.error(error); // Pass error up; should not be handled on service level
          }
        });
    });
  }

  getAppointmentByKeyPatient(
    linkID: string,
    patientID: string
  ): Observable<any[]> {
    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
      .where('linkID', '==', linkID)
      .where('patientID', '==', patientID)
    );
  }

  getAppointmentByKeyDoctor(
    linkID: string,
    doctorID: string
  ): Observable<any[]> {
    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('appointments')
        .where('linkID', '==', linkID)
        .where('doctorID', '==', doctorID)
    );
  }

  getAppointmentByKeyDoctorPromise(
    linkID: string,
    doctorID: string
  ): Promise<any> {
    return this.getAppointmentByKeyDoctor(linkID, doctorID).pipe(take(1)).toPromise();
  }

  getPublicAppointmentInfo(linkID: string): Promise<any> {
    return this.http
      .post(
        this.getPublicAppointmentInfoURL,
        {
          linkID,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getFullColleagueList(cid: string): Promise<Colleague[]> {
    return this.apollo
      .query({
        query: this.companyUserListQuery,
        variables: {
          companyID: cid,
        },
      })
      .toPromise()
      .then((response: any) => {
        return response.data.getCompanyUserList
          .filter(this.filterVirtualDoctors)
          .sort(this.sortByLastName);
      })
      .catch((_) => {
        return [];
      });
  } // end func

  sortByLastName(a: { last: string }, b: { last: string }): number {
    return a.last.localeCompare(b.last);
  } // end func

  filterVirtualDoctors(doctor: Colleague): boolean {
    return doctor.acceptVirtual;
  } // end func

  getDoctorAppointmentsByWeek$(doctorId: string, companyId: string, date: Date): Observable<Appointment[]> {
    const timeZone = this.dateAndTimeService.getLocalTimeZone();
    const startOfWeek = this.dateAndTimeService.getStartOfWeek(date, timeZone).getTime();
    const endOfWeek = this.dateAndTimeService.getEndOfWeek(date, timeZone).getTime();
    return new Observable<Appointment[]>((observer) => {
      return firebase.firestore()
        .collection('appointments')
        .where('doctorID', '==', doctorId)
        .where('doctorCompany', '==', companyId)
        .where('event.start', '>=', startOfWeek)
        .where('event.start', '<=', endOfWeek)
        .onSnapshot((snapshot) => {
          return observer.next(snapshot.docs.map((document) =>  {
            return document.data() as Appointment;
          }));
        });
    });
  }

  getDoctorAppointmentsByDay$(doctorId: string, companyId: string, date: Date): Observable<Appointment[]> {
    const timeZone = this.dateAndTimeService.getLocalTimeZone();
    const startOfDay = this.dateAndTimeService.getStartOfDay(date, timeZone).getTime();
    const endOfDay = this.dateAndTimeService.getEndOfDay(date, timeZone).getTime();
    return new Observable<Appointment[]>((observer) => {
      return firebase.firestore()
        .collection('appointments')
        .where('doctorID', '==', doctorId)
        .where('doctorCompany', '==', companyId)
        .where('event.start', '>=', startOfDay)
        .where('event.start', '<=', endOfDay)
        .onSnapshot((snapshot) => {
          return observer.next(snapshot.docs.map((document) =>  {
            return document.data() as Appointment;
          }));
        });
    });
  }

  getPatientAppointmentsByMonth$(patientUid: string, date: Date): Observable<Appointment[]> {
    const firstOfMonth = startOfMonth(date);
    const lastOfMonth = endOfMonth(date);

    const sundayOnFirstWeekOfMonth = firstOfMonth.getTime() - MILLISECONDS_PER_DAY * firstOfMonth.getDay();
    const saturdayOnLastWeekOfMonth = lastOfMonth.getTime() + MILLISECONDS_PER_DAY * (6 - lastOfMonth.getDay());
    return new Observable<Appointment[]>((observer) => {
      return firebase
        .firestore()
        .collection('appointments')
        .where('patientID', '==', patientUid)
        .where('event.start', '>=', sundayOnFirstWeekOfMonth)
        .where('event.start', '<=', saturdayOnLastWeekOfMonth)
        .orderBy('event.start', 'desc')
        .onSnapshot((snapshot) => {
          return observer.next(snapshot.docs.map((document) =>  {
            return document.data() as Appointment;
          }));
        });
    });
  }

  getPatientAppointmentsByWeek$(patientUid: string, date: Date): Observable<Appointment[]> {
    const timeZone = this.dateAndTimeService.getLocalTimeZone();
    const startOfWeek = this.dateAndTimeService.getStartOfWeek(date, timeZone).getTime();
    const endOfWeek = this.dateAndTimeService.getEndOfWeek(date, timeZone).getTime();
    return new Observable<Appointment[]>((observer) => {
      return firebase.firestore()
        .collection('appointments')
        .where('patientID', '==', patientUid)
        .where('event.start', '>=', startOfWeek)
        .where('event.start', '<=', endOfWeek)
        .onSnapshot((snapshot) => {
          return observer.next(snapshot.docs.map((document) =>  {
            return document.data() as Appointment;
          }));
        });
    });
  }

  getPatientAppointmentsByDay$(patientUid: string, date: Date): Observable<Appointment[]> {
    const timeZone = this.dateAndTimeService.getLocalTimeZone();
    const startOfDay = this.dateAndTimeService.getStartOfDay(date, timeZone).getTime();
    const endOfDay = this.dateAndTimeService.getEndOfDay(date, timeZone).getTime();
    return new Observable<Appointment[]>((observer) => {
      return firebase.firestore()
        .collection('appointments')
        .where('patientID', '==', patientUid)
        .where('event.start', '>=', startOfDay)
        .where('event.start', '<=', endOfDay)
        .onSnapshot((snapshot) => {
          return observer.next(snapshot.docs.map((document) =>  {
            return document.data() as Appointment;
          }));
        });
    });
  }
}
