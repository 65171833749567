import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-place-in-line-select',
  templateUrl: './place-in-line-select.component.html',
  styleUrls: ['./place-in-line-select.component.scss'],
})
export class PlaceInLineSelectComponent {
  @Output() placeInLineOptionSelected = new EventEmitter();
}
