import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InsigButtonComponent } from './insig-button/insig-button.component';
import { InsigCardComponent } from './insig-card/insig-card.component';
import { InsigCardIconComponent } from './insig-card-icon/insig-card-icon.component';
import { InsigDialogComponent } from './insig-dialog/insig-dialog.component';
import { InsigDropdownComponent } from './insig-dropdown/insig-dropdown.component';
import { InsigFormErrorComponent } from './insig-form-error/insig-form-error.component';
import { InsigRadioInputComponent } from './insig-radio-input/insig-radio-input.component';
import { InsigCheckboxButtonComponent } from './insig-checkbox-button/insig-checkbox-button.component';
import { InsigRadioButtonComponent } from './insig-radio-button/insig-radio-button.component';
import { InsigTextInputComponent } from './insig-text-input/insig-text-input.component';
import { InsigTableComponent } from './insig-table/insig-table.component';
import { InsigPopupMenuComponent } from './insig-popup-menu/insig-popup-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InsigSelectComponent } from './insig-select/insig-select.component';
import { InsigHeaderDropdownComponent } from './insig-header-dropdown/insig-header-dropdown.component';
import { InsigDatePickerComponent } from './insig-date-picker/insig-date-picker.component';
import { InsigProfilePictureComponent } from './insig-profile-picture/insig-profile-picture.component';
import { InsigExpansionPanelComponent } from './insig-expansion-panel/insig-expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InsigLineClampedTextComponent } from './insig-line-clamped-text/insig-line-clamped-text.component';
import { InsigImageContainerComponent } from './insig-image-container/insig-image-container.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InsigOneTimePasswordInputComponent } from './insig-one-time-password-input/insig-one-time-password-input.component';

const exportedComponents = [
  InsigButtonComponent,
  InsigCardComponent,
  InsigCardIconComponent,
  InsigDatePickerComponent,
  InsigDialogComponent,
  InsigHeaderDropdownComponent,
  InsigDropdownComponent,
  InsigFormErrorComponent,
  InsigPopupMenuComponent,
  InsigRadioInputComponent,
  InsigRadioButtonComponent,
  InsigCheckboxButtonComponent,
  InsigTextInputComponent,
  InsigTableComponent,
  InsigSelectComponent,
  InsigProfilePictureComponent,
  InsigExpansionPanelComponent,
  InsigLineClampedTextComponent,
  InsigImageContainerComponent,
  InsigOneTimePasswordInputComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
  ],
  declarations: exportedComponents,
  exports: exportedComponents,
})
export class UiComponentsModule { }
