<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Please enter your phone number
      </h4>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0 full-width">
      <form
        name="material_login_form"
        class="md-form-auth form-validation full-width"
        [formGroup]="signUpForm"
        (keydown.enter)="enterClicked()"
      >
        <div class="form-group mx-auto col-lg-4 col-md-8 col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Cell Phone Number</mat-label>
            <input
              matInput
              required
              insig-phone-mask
              minlength="10"
              maxlength="14"
              type="tel"
              formControlName="phone"
              (ngModelChange)="phoneValueChange($event)"
            />
            <mat-error>Please enter a 10-digit phone number</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
