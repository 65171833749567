<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Let's get you ready for your visit!
      </h4>
    </div>
  </div>

  <div class="col-12 action-row">
    <div class="row m-0 full-width">
      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="checkInOption.emit('video')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Check-In On This Kiosk</span>
            </div>
            <div class="row mx-0 mt-4 mb-0">
              <span class="description">
                Use this option if you prefer to complete check-in on this
                kiosk</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div
          class="my-4 action-button primary-color-full"
          (click)="checkInOption.emit('phone')"
        >
          <div class="justify-content-center align-items-center">
            <div class="row mx-0 mb-2 mt-0">
              <span class="title mx-auto">&nbsp;Check-In On Your Phone</span>
            </div>
            <div class="row mx-0 mt-4 mb-0">
              <span class="description">
                Use this option if you prefer to receive an SMS message to
                complete check-in on the convenience of your own phone</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
