<div
  id="stepper"
  class="my-auto"
  [ngStyle]="{
    'min-width.px':
      innerWidth < 800 ? stepsArray.length * 18 : stepsArray.length * 48
  }"
>
  <svg class="step-next" *ngFor="let step of stepsArray; let i = index">
    <rect
      class="step-next-svg"
      [ngClass]="{ 'active-step': currentStep >= i }"
      rx="4.5"
      ry="4.5"
      x="0"
      y="0"
      [attr.width]="innerWidth < 800 ? '10' : '40'"
      height="9"
    ></rect>
  </svg>
</div>
