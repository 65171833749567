// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';

import { FamilyMemberProfile } from 'insig-types/user-data';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'insig-check-in-family-member-select',
  templateUrl: './check-in-family-member-select.component.html',
  styleUrls: ['./check-in-family-member-select.component.scss'],
  providers: [PatientUserDataService],
})
export class CheckInFamilyMemberSelectComponent implements OnInit, OnDestroy {
  @Input() skipLogin = false;
  @Input() familyMemberNeeded = false;
  @Output() familyMemberSelected = new EventEmitter();
  @Output() familyMemberInfoEntered = new EventEmitter();
  public thisYear: any = new Date().getFullYear();
  public loading = false;
  private unsubscribeAuth: any;
  public provinces = [];

  public familyMembersList = [];
  public addingFamilyMember = false;
  public isFamilyMemberSelected = false;
  public createFamilyMemberForm: UntypedFormGroup;
  public selectedFamilyMember = {
    first: 'First Name',
    last: 'Last Name',
    phone: 'phone',
    extension: 'x',
    address: 'address',
    province: 'ON',
    postalCode: 'postal code',
    city: 'city',
  };
  public familyMembersSubscription = null;
  public editingFamilyMember: any = false;
  private userID: any;
  public validatingFamilyMember = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private patientUserDataService: PatientUserDataService,
    private firebaseAuthService: FirebaseAuthService
  ) {}

  ngOnInit() {
    this.provinces = this.patientUserDataService.getProvinces();

    this.buildCreateFamilyMemberForm();
    if (!this.skipLogin) {
      this.unsubscribeAuth = this.firebaseAuthService
        .onIdTokenChanged()
        .subscribe(async (user) => {
          if (user) {
            // console.log(user)
            this.userID = user.uid;
            this.loadFamilyMembers(user.uid);
          }
        });
    } else {
      this.familyMembersList = [];
    }
  }

  ngOnDestroy() {
    if (this.familyMembersSubscription) {
      this.familyMembersSubscription.unsubscribe();
    }
    if (this.unsubscribeAuth) {
      this.unsubscribeAuth.unsubscribe();
    }
  }

  buildCreateFamilyMemberForm() {
    // console.log(this.orthoPatientInfo);

    this.createFamilyMemberForm = this.formBuilder.group(
      {
        first: this.formBuilder.control(null, [Validators.required]),
        last: this.formBuilder.control(null, [Validators.required]),
        healthCardNumber: this.formBuilder.control(null),
        year: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1900),
          Validators.max(new Date().getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(null, [Validators.required]),
        phone: this.formBuilder.control(null, [Validators.required]),
        extension: this.formBuilder.control(null),
        address: this.formBuilder.control(null, [Validators.required]),
        province: this.formBuilder.control(null, [Validators.required]),
        city: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        postalCode: this.formBuilder.control(null, [
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
      },
      {}
    );
  }

  checkFamilyMemberFormValid(form) {
    if (form.controls['year'].value) {
      form.controls['year'].setValue(
        form.controls['year'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['month'].value) {
      form.controls['month'].setValue(
        form.controls['month'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['day'].value) {
      form.controls['day'].setValue(
        form.controls['day'].value.replace(/[^\d]/g, '')
      );
    }
    let message = '';
    if (!form.controls['first'].valid) {
      message = 'Please enter a first name!';
    } else if (!form.controls['last'].valid) {
      message = 'Please enter a last name!';
    } else if (
      !form.controls['year'].valid ||
      !form.controls['month'].valid ||
      !form.controls['day'].valid
    ) {
      message = 'Please enter a valid birthdate!';
    } else if (!form.controls['city'].valid) {
      message = 'Please enter a city!';
    } else if (!form.controls['address'].valid) {
      message = 'Please enter a valid address!';
    } else if (!form.controls['province'].valid) {
      message = 'Please enter a province!';
    } else if (!form.controls['postalCode'].valid) {
      message = 'Please enter a valid postal code!';
    } else if (!form.controls['gender'].valid) {
      message = 'Please enter a gender!';
    } else if (!form.controls['phone'].valid) {
      message = 'Please enter a valid phone number!';
    } else {
      // form is valid so return true
      return true;
    }
    this.snackbar.open(message, null, { duration: 2000 });
    return false;
  }

  bookMyself() {
    this.isFamilyMemberSelected = false;
    this.editingFamilyMember = false;
    if (this.skipLogin) {
      this.familyMemberInfoEntered.emit('myself');
    } else {
      this.familyMemberSelected.emit('myself');
    }
  }

  addFamilyMember() {
    console.log('adding family member');
    this.addingFamilyMember = true;
    this.editingFamilyMember = false;
  }
  createFamilyMember() {
    console.log('creating family member');

    if (!this.checkFamilyMemberFormValid(this.createFamilyMemberForm)) {
      return false;
    }

    this.snackbar.open('Please wait while we add this patient...', null, {
      duration: 4000,
    });
    this.loading = true;
    const familyMemberUserData = {
      id: null,
    } as FamilyMemberProfile & { id: string | null };
    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {}
    )) {
      familyMemberUserData[field] = this.createFamilyMemberForm.controls[
        field
      ].value;
    }
    const thisComponent = this;

    if (!this.skipLogin) {
      this.patientUserDataService
        .createFamilyMember(familyMemberUserData, this.userID)
        .then((result) => {
          console.log(result);
          thisComponent.snackbar.open('Member added!', null, {
            duration: 4000,
          });

          thisComponent.selectFamilyMember(familyMemberUserData);
          thisComponent.loading = false;
          thisComponent.addingFamilyMember = false;
        })
        .catch((error) => {
          console.error(error);
          thisComponent.snackbar.open('Error adding patient...', null, {
            duration: 4000,
          });
          thisComponent.loading = false;
        });
    } else {
      this.familyMemberInfoEntered.emit(familyMemberUserData);
    }
  }

  editFamilyMember() {
    this.snackbar.open('Please wait while we update this patient...', null, {
      duration: 4000,
    });
    this.loading = true;
    const familyMemberUserData = {} as FamilyMemberProfile & { id: string | null };
    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {}
    )) {
      familyMemberUserData[field] = this.createFamilyMemberForm.controls[
        field
      ].value;
    }
    const thisComponent = this;

    this.patientUserDataService
      .editFamilyMember(this.editingFamilyMember, familyMemberUserData)
      .then((result) => {
        console.log(result);
        thisComponent.snackbar.open('Patient saved!', null, { duration: 4000 });

        thisComponent.selectFamilyMember(familyMemberUserData);
        thisComponent.loading = false;
        thisComponent.addingFamilyMember = false;
      })
      .catch((error) => {
        console.error(error);
        thisComponent.snackbar.open('Error adding patient...', null, {
          duration: 4000,
        });
        thisComponent.loading = false;
      });
  }

  selectFamilyMember(member) {
    console.log('selecting family member');
    this.selectedFamilyMember = member;
    this.isFamilyMemberSelected = true;
    this.familyMemberSelected.emit(member);
  }

  openEditFamilyMember(member) {
    console.log(member);
    this.addingFamilyMember = true;
    this.editingFamilyMember = member.id;
    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {}
    )) {
      this.createFamilyMemberForm.controls[field].setValue(member[field]);
    }
  }

  validateFamilyMember(member) {
    console.log(member);
    this.addingFamilyMember = true;
    this.editingFamilyMember = member.id;
    this.validatingFamilyMember = true;
    for (const field of Object.keys(
      this.createFamilyMemberForm.controls || {}
    )) {
      this.createFamilyMemberForm.controls[field].setValue(member[field]);
    }
  }

  deleteFamilyMember(member) {
    console.log(member);
    this.patientUserDataService.deleteFamilyMember(member.id);
  }

  loadFamilyMembers(patientID) {
    this.familyMembersSubscription = this.patientUserDataService
      .loadFamilyMembers(patientID)
      .subscribe((snapshot) => {
        this.familyMembersList = [];
        if (snapshot && snapshot.length > 0) {
          snapshot.forEach((member: any) => {
            if (!member.deleted) {
              member.data.id = member.id;
              member.data.fullName = member.data.first + ' ' + member.data.last;
              this.familyMembersList.push(member.data);
            }
          });
        }
      });
  }

  selectFamilyMemberToggle(_event) {
    this.isFamilyMemberSelected = false;
  }

  scrollTop() {
    // TODO implement
  }
}
