// @ts-strict-ignore
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'insig-place-in-line-check-in-input',
  templateUrl: './place-in-line-check-in-input.component.html',
  styleUrls: ['./place-in-line-check-in-input.component.scss'],
})
export class PlaceInLineCheckInInputComponent implements OnInit {
  @Output() checkInIDSubmitted = new EventEmitter();

  public checkInIDForm: UntypedFormGroup;
  public checkInIDValid = false;
  public checkInIDInput;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.checkInIDForm = this.buildCheckInIDForm();
  }

  buildCheckInIDForm() {
    return this.formBuilder.group(
      {
        checkInID: this.formBuilder.control(null, [Validators.required]),
      },
      {
        validators: [],
      }
    );
  }

  checkInIDValueChange(checkInID) {
    console.log(checkInID, this.checkInIDForm.valid);
    this.checkInIDInput = event;
    this.checkInIDValid = this.checkInIDForm.valid;
  }

  checkInInputSubmitted() {
    this.checkInIDSubmitted.emit(
      this.checkInIDForm.controls['checkInID'].value
    );
  }
}
