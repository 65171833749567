// @ts-strict-ignore
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';

import { differenceInMinutes, addHours } from 'date-fns';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';

@Component({
  selector: 'insig-check-in-display',
  templateUrl: './check-in-display.component.html',
  styleUrls: ['./check-in-display.component.scss'],
  providers: [PatientUserDataService],
})
export class CheckInDisplayComponent implements OnInit, OnChanges {
  @Input() locationID: string;
  @Input() locationData: any;
  @Input() companyID: string;
  @Input() emrDoctors = [];
  @Input() doctorAppointments = {};
  @Input() parsedAppointments = { walkIn: [], appointmentBased: [] };
  public checkedInAppointments = { walkIn: [], appointmentBased: [] };
  public readyAppointments = { walkIn: [], appointmentBased: [] };
  public validCheckInStatuses = ['Here', 'Daysheet Printed'];
  public notValidReadyStatuses = ['Here', 'Daysheet Printed', 'To Do'];

  constructor(
    private patientUserDataService: PatientUserDataService,
    private dateAndTimeService: DateAndTimeService,
  ) {}

  ngOnInit(): void {
    this.loadDashboardData();

    setInterval(() => {
      this.loadDashboardData();
    }, 60000);
  }

  ngOnChanges(_changes): void {
    if (_changes.parsedAppointments) {
      this.loadDashboardData();
    }
  }

  getTiming(appt): number {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const clinicTimeZoneOffset = Math.abs(this.dateAndTimeService.getUtcOffsetMinutes(new Date(), 'America/Vancouver'));

    const now = new Date();
    let apptTime = new Date(
      new Date(now).setHours(
        parseInt(appt.time.split(':')[0], 10),
        parseInt(appt.time.split(':')[1], 10),
      ),
    );

    if (timeZoneOffset < clinicTimeZoneOffset) {
      const numberOfHours = Math.round(
        (clinicTimeZoneOffset - timeZoneOffset) / 60,
      );
      // now = new Date(subHours(now, numberOfHours))
      apptTime = new Date(addHours(apptTime, numberOfHours));
    }

    const difference = differenceInMinutes(apptTime, now);

    return difference;
  }

  schedulePatientCommunication(body, patientID, apptID): void {
    try {
      this.patientUserDataService.createPatientCommunication(
        'sms',
        { body },
        new Date().getTime(),
        true,
        patientID,
        true,
        apptID,
      );
    } catch (err) {
      console.log('error sendign message');
    }
  }

  loadDashboardData(): void {
    this.checkedInAppointments = { walkIn: [], appointmentBased: [] };
    this.readyAppointments = { walkIn: [], appointmentBased: [] };
    for (const w in this.parsedAppointments.walkIn) {
      if (this.parsedAppointments.walkIn[w]) {
        const walkIn = this.parsedAppointments.walkIn[w];
        if (
          !!walkIn.checkInID &&
          !!walkIn.status &&
          !!this.validCheckInStatuses.includes(walkIn.status)
        ) {
          walkIn.timingStr = 'Please wait to be called';

          this.checkedInAppointments.walkIn.push(walkIn);

          if (parseInt(w, 10) === 2 && !!walkIn.userID && !!walkIn.apptID) {
            const reminderStr = 'Thank you for your patience! You are now #3 in the walk-in queue. Please make your way back to the clinic waiting room. We look forward to serving you shortly.';
            this.schedulePatientCommunication(
              reminderStr,
              walkIn.userID,
              walkIn.apptID,
            );
          } else if (
            parseInt(w, 10) === 0 &&
            !!walkIn.userID &&
            !!walkIn.apptID
          ) {
            const reminderStr = 'Almost there! You are now #1 in the walk-in queue. Please ensure you are ready for your visit. We look forward to serving you shortly.';
            this.schedulePatientCommunication(
              reminderStr,
              walkIn.userID,
              walkIn.apptID,
            );
          }
        } else if (
          !!walkIn.checkInID &&
          !!walkIn.status &&
          !!walkIn.status.toLowerCase().includes('room')
        ) {
          if (walkIn.statusLastUpdated) {
            const utc = new Date().setMilliseconds(0);

            const difference = differenceInMinutes(
              new Date(utc),
              new Date(walkIn.statusLastUpdated),
            );

            if (difference < 2) {
              this.readyAppointments.walkIn.push(walkIn);

              if (!!walkIn.userID && !!walkIn.apptID) {
                const reminderStr = `Here we go! Please immediately make your way to ${walkIn.status}, the front desk staff can direct you appropriately.`;
                this.schedulePatientCommunication(
                  reminderStr,
                  walkIn.userID,
                  walkIn.apptID,
                );
              }
            }
          }
        }
      }
    }

    for (const appt of this.parsedAppointments.appointmentBased) {
      if (
        !!appt.checkInID &&
        !!appt.status &&
        !!this.validCheckInStatuses.includes(appt.status)
      ) {
        appt.timingStr = 'Please wait to be called';
      } else if (
        !!appt.checkInID &&
        !!appt.status &&
        !!appt.status.toLowerCase().includes('room')
      ) {
        if (appt.statusLastUpdated) {
          const utc = new Date().setMilliseconds(0);

          const difference = differenceInMinutes(
            new Date(utc),
            new Date(appt.statusLastUpdated),
          );
          // console.log("difference in minutes: ", difference)
          if (difference < 2) {
            this.readyAppointments.appointmentBased.push(appt);

            if (!!appt.userID && !!appt.apptID) {
              const reminderStr = `Here we go! Please immediately make your way to ${appt.status}, the front desk staff can direct you appropriately.`;
              this.schedulePatientCommunication(
                reminderStr,
                appt.userID,
                appt.apptID,
              );
            }
          }
        }
        // this.readyAppointments.appointmentBased.push(appt)
      }
    }

    console.log(this.checkedInAppointments, this.readyAppointments);
  }
}
