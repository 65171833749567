// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-check-in-number-confirmation',
  templateUrl: './check-in-number-confirmation.component.html',
  styleUrls: ['./check-in-number-confirmation.component.scss'],
})
export class CheckInNumberConfirmationComponent implements OnInit {
  @Output() resetScreen = new EventEmitter();
  @Output() launchSurvey = new EventEmitter();
  @Input() generatedID: string;
  @Input() patientDevice: boolean;
  @Input() skipLogin = false;

  ngOnInit() {
    setTimeout(() => {
      console.log('reset');
      // if (!!this.patientDevice || !!this.skipLogin) {
      //   this.launchSurvey.emit();
      // } else {
      //   this.resetScreen.emit();
      // }
      this.launchSurvey.emit();
    }, 10000);
  }
}
