<div class="p-3">
  <div class="row">
    <div class="col-12">
      <p class="mb-3" *ngIf="!tiaHealth && !hideFields?.platformDesc">
        Our platform connects you to medical practitioners through
        video chat, phone, or secure messaging. Please sign up below.
      </p>
      <p
        class="mb-3"
        *ngIf="(tiaHealth || !!specialTitle) && !hideFields?.signUpDesc"
      >
        Don't have an account? Please sign up below!
      </p>

      <form
        name="material_login_form"
        class="md-form-auth form-validation"
        [formGroup]="signUpForm"
        onkeypress="return event.keyCode != 13;"
      >
        <div *ngIf="!partialPatient" class="row m-0">
          <div class="col-md-6 col-xs-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="mb-0 full-width" appearance="outline">
                <mat-label>Legal First Name</mat-label>
                <input
                  matInput
                  required
                  formControlName="first"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6 col-xs-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Legal Last Name</mat-label>
                <input
                  matInput
                  required
                  formControlName="last"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="partialPatient" class="col-12 pb-3">
          You are logged in as {{ partialPatient.email }}
        </div>
        <div *ngIf="!partialPatient" class="row m-0">
          <div class="col-md-6 col-xs-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  required
                  pattern="^.+@.+\..+$"
                  type="email"
                  formControlName="email"
                />
                <mat-error>Please enter a valid email (joe@example.com)</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6 col-xs-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field
                class="full-width no-margin-bottom"
                appearance="outline"
              >
                <mat-label>Password</mat-label>
                <input
                  required
                  matInput
                  type="password"
                  name="password"
                  formControlName="password"
                />
                <mat-error>Password must contain at least 8 characters, an uppercase and lowercase character, a number, and a symbol</mat-error>
              </mat-form-field>
            </div>
          </div>

        </div>

        <div class="row m-0">
          <div class="col-md-6 col-xs-12">
            <div
              class="mb-0 form-group"
              [ngClass]="{ 'form-field': blockStyle }"
            >
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Birthdate (YYYY-MM-DD)</mat-label>
                <input
                  type="tel"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  required
                  matInput
                  autocomplete="off"
                  maxlength="10"
                  formControlName="birthdate"
                  (change)="handleBirthdateFieldChange($event)"
                />
                <mat-error>Please enter a valid birthdate (YYYY-MM-DD)</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 d-flex justify-content-center align-items-center">
            <div>
              <label class="placeholder">Designated Gender At Birth *</label>
              <br>
              <mat-radio-group
                required
                class="mat-radio-group-spacing"
                formControlName="gender"
              >
                <mat-radio-button value="Female">Female</mat-radio-button>
                <span class="space"></span>
                <mat-radio-button value="Male">Male</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>

        <div class="row mx-0 mt-3">
          <div class="col-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Address</mat-label>
                <input
                  matInput
                  required
                  #searchAddressSignUp
                  formControlName="address"
                  (change)="addressChanged()"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row m-0" [hidden]="!signUpForm.controls.address.value">
          <div class="col-6">
            <div class="mb-0 form-field">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select
                  required
                  formControlName="country"
                  (selectionChange)="countryChanged($event)"
                >
                  <mat-option
                    *ngFor="let country of countryList"
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Province / State</mat-label>
                <mat-select
                  formControlName="province"
                  required
                >
                  <ng-container
                    *ngIf="signUpForm.controls.country.value === 'Canada'"
                    ><mat-option
                      (click)="addressChanged()"
                      *ngFor="let province of canadianProvinces"
                      [value]="province.id"
                      >{{ province.name }}</mat-option
                    ></ng-container
                  >
                  <ng-container
                    *ngIf="
                      signUpForm.controls.country.value === 'United States'
                    "
                    ><mat-option
                      (click)="addressChanged()"
                      *ngFor="let state of americanStates"
                      [value]="state.id"
                      >{{ state.name }}</mat-option
                    ></ng-container
                  >
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-error>Required</mat-error>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row m-0" [hidden]="!signUpForm.controls.address.value">
          <div class="col-6">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Postal / ZIP Code</mat-label>
                <input
                  matInput
                  required
                  (change)="addressChanged()"
                  formControlName="postalCode"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>City</mat-label>
                <input
                  matInput
                  required
                  (change)="addressChanged()"
                  formControlName="city"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="!partialPatient" class="row m-0">
          <div class="col-md-6 col-xs-12">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Cell Phone Number</mat-label>
                <input
                  matInput
                  required
                  insig-phone-mask
                  minlength="10"
                  maxlength="14"
                  type="tel"
                  formControlName="phone"
                />
                <mat-error>Please enter a 10-digit phone number</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div [ngClass]="{ 'col-md-6 col-xs-12': !partialPatient, 'col-12': partialPatient }">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Health Card Number</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  formControlName="healthCardNumber"
                />
              </mat-form-field>
            </div>
          </div>

        </div>

        <div class="row m-0">
          <div class="col-12 col-lg-6 col-xl-4">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Family Doctor First Name</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  formControlName="familyDoctorFirstName"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Family Doctor Last Name</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  formControlName="familyDoctorLastName"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-xl-4">
            <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Family Doctor Fax Number</mat-label>
                <input
                  matInput
                  insig-phone-mask
                  autocomplete="off"
                  formControlName="familyDoctorFaxNumber"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <hr/>

        <span *ngIf="!hideFields?.pharmacy && !pharmacyNotRequired">
          <div class="row mt-4">
            <div class="col-12">
              <h4 class="mb-0 text-left bold placeholder">Preferred Pharmacy</h4>
            </div>
          </div>
          <p class="mb-2">
            Any prescriptions for your visit may be faxed to your preferred
            pharmacy.
            <span *ngIf="!hidePharmacy && patientInfo.postalCode && patientInfo.city
            && patientInfo.address && patientInfo.province">
              Select a pharmacy here, or enter one manually below.
            </span>
          </p>

          <span [hidden]="hidePharmacy"
          *ngIf="patientInfo.postalCode && patientInfo.city
          && patientInfo.address && patientInfo.province"
          >
          <span *ngIf="!geolocatePharmacy && defaultPharmacy && defaultPharmacy.pharmaName">

            <span *ngIf="!tiaHealth">
              <h4 class="bold">Pharmacy Delivery (it's free!)</h4>
              <hr class="horizontal-hr" />
              <div class="pb-2">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <img
                      class="w-100 mb-2"
                      src="{{ defaultPharmacy.img2 }}"
                      alt="Pharmacy Rx"
                    />
                  </div>
                  <div class="col-md-6 my-auto">
                    <img
                      src="{{ defaultPharmacy.img }}"
                      style="height: 60px;"
                      alt="Pharmacy Logo"
                    />
                    <h5 style="color:#e34429;" class="bold mt-0">{{ defaultPharmacy.line2 }}</h5>
                    <h6 class="my-3">{{ defaultPharmacy.line1 }}</h6>
                    <div
                      *ngIf="defaultPharmacy.id === 'pillway'"
                    >
                      <a style="color:#e34429;" (click)="openPillwayInfo()"
                      href="javascript:;" class="p-0">
                       Learn More ▶
                     </a>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <span *ngIf="tiaHealth">
              <h4 class="bold">Tia Health's Free Pharmacy Delivery Service</h4>
              <hr class="horizontal-hr" />
              <div class="pb-2">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <img
                      class="w-100 mb-2"
                      src="{{ defaultPharmacy.img2 }}"
                      alt="Pharmacy Rx"
                    />
                  </div>
                  <div class="col-md-6 my-auto">
                    <img
                      class="mb-2"
                      src="./assets/images/logo/tiahealth-logo.png"
                      style="height: 55px;"
                      alt="Pharmacy Logo"
                    />
                    <h5 style="color:#009fde;" class="bold mt-0">{{ defaultPharmacy.line2 }}</h5>
                    <h6 class="mb-3 mt-1">{{ defaultPharmacy.line1 }}</h6>
                    <div
                      *ngIf="defaultPharmacy.id === 'pillway'"
                    >
                      <a style="color:#009fde;" (click)="openPillwayInfo()"
                      href="javascript:;" class="p-0 mb-3">
                       Fulfilled by Pillway ▶
                     </a>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <div
              class="filled-btn full-width d-flex flex-column p-0 mx-auto mb-3"
              (click)="useDefaultPharmacy()"
            >
              <div class="mt-auto mb-auto">
                Get Free Prescription Delivery!
              </div>
            </div>
            <h4 class="mt-5 mb-2 bold">Pharmacy Pick-Up</h4>
            <hr class="horizontal-hr" />
          </span>

            <insig-referral-patient
              class="full-width"
              [editMode]="false"
              [(patientInfo)]="patientInfo"
              (referralSent)="setPreferredPharmacy($event)"
              [doctorMode]="false"
              [referralType]="'pharmacy'"
              [geolocatePharmacy]="geolocatePharmacy"
              [pharmacySelect]="true"
            ></insig-referral-patient>
          </span>

          <div class="row my-4">
            <div class="col-md-6 col-xs-12">
              <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Pharmacy Name</mat-label>
                  <input
                    matInput
                    required
                    type="text"
                    autocomplete="off"
                    formControlName="pharmaName"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="mb-0 form-group" [ngClass]="{ 'form-field': blockStyle }">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Pharmacy Fax Number</mat-label>
                  <input
                    matInput
                    minlength="10"
                    maxlength="14"
                    required
                    insig-phone-mask
                    autocomplete="off"
                    type="tel"
                    formControlName="pharmaFax"
                  />
                  <mat-error>Please enter a 10-digit fax number</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <hr class="mt-0" />
        </span>

        <div class="divider"></div>

        <div class="row">
          <div class="col-12 text-center">
            <mat-checkbox
              required
              color="primary"
              formControlName="terms"
            ></mat-checkbox>
            <span class="space"></span>I read and accept the
            <a
              href="javascript:;"
              class="d-inline"
              (click)="triggerPatientTerms()"
              ><i>Terms & Conditions</i></a
            >
          </div>
        </div>

        <div class="row" [hidden]="!tiaHealth || specialTitle">
          <div class="col-12 text-center">
            <mat-checkbox
              color="primary"
              formControlName="promo"
            ></mat-checkbox>
            <span class="space"></span>
            <span>I agree to receive promo emails from Tia Health.
              <br>
              You can unsubscribe at any time.
            </span>
          </div>
        </div>

        <div class="divider"></div>

        <div class="row mb-5 bottom-margin">
          <div class="col-6 pl-0">
            <button
              class="float-right full-width empty-btn"
              (click)="backToLogin.emit()"
              *ngIf="!patientLoginSignUp"
            >
              {{ backButtonText }}
            </button>

            <button
              class="float-right full-width empty-btn"
              *ngIf="patientLoginSignUp"
              (click)="backToLogin.emit()"
            >
              Back to Login
            </button>
          </div>
          <div class="col-6 pr-0">
            <button *ngIf="!partialPatient"
              (click)="handleSignUpButtonClicked(signUpForm)"
              class="float-right filled-btn full-width"
            >
              Sign Up
            </button>
            <button *ngIf="partialPatient"
              (click)="handleCompleteSignUpButtonClicked(signUpForm)"
              class="float-right filled-btn full-width"
            >
              Complete Sign Up
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<p>
  550 - 375 Water Street, Vancouver, British Columbia, Canada, V6B 5C6
</p>
