<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div
    class="align-self-center justify-content-center 
    kiosk-title d-flex flex-column justify-content-between full-height full-width"
    [ngClass]="{
      'col-12': !locationData?.walkIn,
      'col-6': !!locationData?.walkIn
    }"
    *ngIf="!!locationData.appointmentBased"
  >
    <div class="text-center d-flex">
      <h4 class="px-4 mx-auto roboto-title">
        Appointments
      </h4>
    </div>

    <div class="d-flex flex flex-grow-1 full-width">
      <div
        class="appointment-details-container row p-4 m-0 full-width align-content-start"
      >
        <div
          class="col-12 p-0 fit-content"
          *ngFor="let appt of readyAppointments.appointmentBased"
        >
          <!--  -->
          <div class="appointment-details-ready highlight-border p-0 m-2">
            <div class="row m-0 p-0 full-height text-center">
              <!-- <div class="appointment-details-img" *ngIf="!!category.image">
                                <img src="" alt="" >
                                </div> -->

              <h5 class="appointment-details-title col-12 mx-auto">
                Patient {{ appt.checkInID }}
              </h5>
              <h5 class="appointment-details-desc col-12 mx-auto">
                {{ appt.status }}
              </h5>
            </div>
          </div>
        </div>

        <hr
          class="divider"
          *ngIf="readyAppointments.appointmentBased.length > 0"
        />

        <div
          class="col-12 p-0 fit-content"
          *ngFor="let appt of checkedInAppointments.appointmentBased"
        >
          <!--  -->
          <div class="appointment-details p-0 m-2">
            <div class="row m-0 p-0 full-height text-center">
              <!-- <div class="appointment-details-img" *ngIf="!!category.image">
                          <img src="" alt="" >
                        </div> -->

              <h5 class="appointment-details-title col-12 mx-auto">
                Patient {{ appt.checkInID }}
              </h5>
              <h5 class="appointment-details-desc col-12 mx-auto">
                {{ appt.timingStr || 'Approx. 15 min.' }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="align-self-center justify-content-center 
  kiosk-title d-flex flex-column justify-content-between full-height full-width"
    [ngClass]="{
      'col-12': !locationData?.appointmentBased,
      'col-6': !!locationData?.appointmentBased
    }"
    *ngIf="!!locationData.walkIn"
  >
    <div class="text-center d-flex">
      <h4 class="px-4 mx-auto roboto-title">
        Walk-In
      </h4>
    </div>

    <div class="d-flex flex flex-grow-1 full-width">
      <div
        class="appointment-details-container row p-4 m-0 full-width align-content-start"
      >
        <div
          class="col-12 p-0 fit-content"
          *ngFor="let appt of readyAppointments.walkIn"
        >
          <!--  -->
          <div class="appointment-details-ready highlight-border p-0 m-2">
            <div class="row m-0 p-0 full-height text-center">
              <!-- <div class="appointment-details-img" *ngIf="!!category.image">
                                          <img src="" alt="" >
                                          </div> -->

              <h5 class="appointment-details-title col-12 mx-auto">
                Patient {{ appt.checkInID }}
              </h5>
              <h5 class="appointment-details-desc col-12 mx-auto">
                {{ appt.status }}
              </h5>
            </div>
          </div>
        </div>

        <hr class="divider" *ngIf="readyAppointments.walkIn.length > 0" />

        <div
          class="col-12 p-0 fit-content"
          *ngFor="let appt of checkedInAppointments.walkIn"
        >
          <!--  -->
          <div class="appointment-details p-0 m-2">
            <div class="row m-0 p-0 full-height text-center">
              <!-- <div class="appointment-details-img" *ngIf="!!category.image">
                        <img src="" alt="" >
                      </div> -->

              <h5 class="appointment-details-title col-12 mx-auto">
                Patient {{ appt.checkInID }}
              </h5>
              <h5 class="appointment-details-desc col-12 mx-auto">
                {{ appt.timingStr || 'Approx. 15 min.' }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
