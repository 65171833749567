<div class="h-100 overflow-y-auto">
  <ng-container *ngIf="!!userDataForm?.value">
    <form [formGroup]="userDataForm">
      <!-- begin top profile summary -->
      <mat-card class="w-100 p-2 sticky-top">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="profile-image" *ngIf="isDoctor">
              <img [src]="profilePictureUrl || '/assets/images/profile.png'" />
              <button
                mat-raised-button
                class="overlay d-flex justify-content-center align-items-center h-100 w-100"
                color="primary"
                (click)="selectProfileImage()"
              >
                <mat-icon class="notranslate">mode_edit</mat-icon
                ><span>&nbsp;Profile Image</span>
              </button>
            </div>
            <div class="ml-2">
              <h3 class="text-black font-weight-bold font-size-30px m-0">
                {{ userDataForm.value.first }} {{ userDataForm.value.last }}
              </h3>
              <span
                class="mt-2"
                *ngIf="companyDataObservable | async as companyData"
                >{{ companyData.name }}</span
              >
            </div>
          </div>

          <div class="save-button">
            <button
              class="primary-color-full"
              mat-raised-button
              (click)="saveData()"
            >
              Save
            </button>
          </div>
        </div>
      </mat-card>
      <!-- end top profile summary -->

      <!-- begin tabs -->
      <div class="w-100 row no-gutters">
        <div class="col-md-10 offset-md-1 col-sm-12">
          <section class="card">
            <div class="card-block">

              <ng-container *ngIf="!(isDoctorObservable | async)">
                <ng-container *ngTemplateOutlet="userProfileForm"></ng-container>
              </ng-container>

              <mat-tab-group #tabGroup>
                <!-- begin user tab -->
                <mat-tab *ngIf="isDoctorObservable | async" label="User">
                  <div class="divider"></div>

                  <ng-container *ngTemplateOutlet="userProfileForm"></ng-container>

                  <span *ngIf="isDoctorObservable | async">
                    <h6>Signature</h6>
                    <div (click)="openSignatureDialogComponent()">
                      <canvas
                        *ngIf="!signature"
                        class="signatureStyle"
                      ></canvas>
                      <img
                        *ngIf="signature"
                        class="signatureStyle"
                        src="{{ signature }}"
                      />
                    </div>
                  </span>

                  <br>
                  <div class="text-right">
                    <div>
                      <a
                        href="javascript:void(0);"
                        class="email mt-2 pb-0"
                        title="Close my account"
                        (click)="sendCloseAccountEmail()">
                        Close my account
                      </a>
                    </div>
                    <div>
                      *Your account closure request must be sent from<br>the email address associated with this account.
                    </div>
                  </div>

                </mat-tab>

                <!-- end user tab -->

                <!-- begin integrations tab -->
                <mat-tab
                  label="Integrations"
                  *ngIf="(userDataObservable | async)?.type?.admin"
                >
                  <div class="container">
                    <div class="divider"></div>

                    <div class="row">
                      <div class="col-10 offset-1">
                        <h4>Your IDs</h4>
                        <div>
                          These IDs are used by Insig to uniquely identify you
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <span>User ID:&nbsp;</span>
                          </div>
                          <div
                            class="col-12 col-md-8 d-flex justify-content-start justify-content-md-end"
                          >
                            <span>{{ (userObservable | async)?.uid }}</span>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <span>Company ID:&nbsp;</span>
                          </div>
                          <div
                            class="col-12 col-md-8 d-flex justify-content-start justify-content-md-end"
                          >
                            <span>{{
                              (userDataObservable | async)?.company
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="!!companyEMRForm?.value">
                      <form [formGroup]="companyEMRForm">
                        <hr />

                        <div class="row">
                          <div class="col-10 offset-1">
                            <h4 class="mb-0">EMR Inbox</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-10 offset-1">
                            <h6>
                              Insig can connect to your EMR through the EMR's
                              Inbox. To send all questionnaire results to your
                              EMR's inbox, check the box below and enter the
                              email for your EMR's inbox. Then click save in the
                              top right corner.
                            </h6>
                          </div>
                        </div>

                        <div class="row">
                          <div
                            class="col-10 offset-1 col-md-5 d-flex align-items-center"
                          >
                            <mat-checkbox
                              color="primary"
                              formControlName="EMREmailEnabled"
                              >Send Questionnaires to EMR Inbox</mat-checkbox
                            >
                          </div>
                          <div class="col-10 offset-1 col-md-5 offset-md-0">
                            <mat-form-field class="full-width">
                              <mat-label>EMR Inbox Email</mat-label>
                              <input
                                matInput
                                pattern="^.+@.+\..+$"
                                formControlName="EMRInboxEmail"
                              />
                              <mat-error
                                >Please enter a valid email
                                (joe@example.com)</mat-error
                              >
                            </mat-form-field>
                          </div>
                        </div>
                      </form>
                    </ng-container>

                    <div class="divider"></div>
                  </div>
                </mat-tab>
                <!-- end integrations tab -->

                <!-- begin company settings tab -->
                <mat-tab
                  label="Company Settings"
                  *ngIf="(userDataObservable | async)?.type?.admin"
                >
                  <ng-container *ngIf="!!companySettingsForm?.value">
                    <form class="container" [formGroup]="companySettingsForm">
                      <div class="divider"></div>

                      <div class="row">
                        <div class="col-6">
                          <img
                            *ngIf="!!branding"
                            class="d-block mx-auto mw-100"
                            style="max-height: 10em;"
                            [src]="branding"
                            alt="branding"
                          />
                        </div>
                      </div>

                      <div class="divider"></div>

                      <div class="row">
                        <div class="col-6 text-center">
                          <button
                            mat-raised-button
                            *ngIf="!!branding"
                            (click)="removeBranding()"
                          >
                            Remove Image
                          </button>
                          <div *ngIf="!branding">
                            <p>Upload your company logo below</p>
                            <ngx-file-drop
                              (onFileDrop)="brandingAdded($event)"
                              multiple="false"
                              accept=".png,.jpg,.jpeg"
                              dropZoneClassName="ngxFileDropZone"
                              contentClassName="ngxFileDropContent"
                            >
                              <ng-template
                                ngx-file-drop-content-tmp
                                let-openFileSelector="openFileSelector"
                              >
                                <span>Drop file here</span>
                                <span class="px-3"><!-- divider --></span>
                                <button
                                  mat-raised-button
                                  color="primary"
                                  type="button"
                                  (click)="openFileSelector()"
                                >
                                  Browse
                                </button>
                              </ng-template>
                            </ngx-file-drop>
                          </div>
                        </div>
                        <div class="col-4 offset-1 text-center">
                          <mat-form-field class="center-object full-width">
                            <mat-label>Company Name</mat-label>
                            <input
                              matInput
                              formControlName="name"
                            />
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="divider"></div>

                      <hr />
                      <div class="row">
                        <div class="col-10 offset-1">
                          <h4 class="text-center">
                            Appointment Notifications Email
                          </h4>
                          <h6>
                            This email address will receive an email
                            notification for each new appointment that is booked
                            for any practitioner in the organization.
                          </h6>
                          <mat-form-field class="center-object full-width">
                            <mat-label>Appointment Notifications Email</mat-label>
                            <input
                              matInput
                              formControlName="apptEmail"
                              autocomplete="off"
                              pattern="^.+@.+\..+$"
                              type="email"
                            />
                            <mat-error
                              >Please enter a valid email
                              (joe@example.com)</mat-error
                            >
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="divider"></div>

                      <hr />
                      <div class="row">
                        <div class="col-10 offset-1">
                          <h4 class="text-center">
                            Fax Notifications Email
                          </h4>
                          <h6>
                            This email address will receive a notification each
                            time a fax is sent that is not successfully received
                            by the receiving fax machine.
                          </h6>
                          <mat-form-field class="center-object full-width">
                            <mat-label>Fax Notifications Email</mat-label>
                            <input
                              matInput
                              formControlName="faxEmail"
                              autocomplete="off"
                              pattern="^.+@.+\..+$"
                              type="email"
                            />
                            <mat-error
                              >Please enter a valid email
                              (joe@example.com)</mat-error
                            >
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="divider"></div>

                      <hr />
                      <div class="row">
                        <div class="col-10 offset-1">
                          <h4 class="text-center">Virtual Care URL</h4>
                          <h6>
                            This is the url that patients can use to access
                            virtual care appointment booking
                          </h6>
                          <mat-form-field class="center-object full-width">
                            <mat-label>URL Suffix</mat-label>
                            <input
                              matInput
                              formControlName="companyUrl"
                            />
                            <mat-error>
                              This URL is already taken by another company
                            </mat-error>
                          </mat-form-field>
                          <div class="col-10 offset-1 text-center">
                            <h5>
                              <b>
                                Virtual Care URL: {{ APPURL }}virtual/{{
                                  companySettingsForm.value.companyUrl
                                }}</b
                              >
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="divider"></div>

                      <hr />
                      <div class="row">
                        <div class="col-10 offset-1">
                          <h4 class="text-center">Company Website</h4>
                          <h6>
                            This is the link to your website is linked to your
                            logo in the virtual care booking platform. Please
                            include the full URL (https:// ...)
                          </h6>
                          <mat-form-field class="center-object full-width">
                            <mat-label>Company Website</mat-label>
                            <input
                              matInput
                              formControlName="website"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="divider"></div>

                      <ng-container
                        *ngIf="companyDataObservable | async as companyData"
                      >
                        <hr />
                        <div class="row">
                          <div class="col-10 offset-1">
                            <h4 class="text-center">Company Plan</h4>
                            <h6>
                              This enables the ability to create company plans
                              for patients, groups of doctors, etc.
                            </h6>
                            <mat-slide-toggle
                              [ngModel]="companyData.companyPlan"
                              (ngModelChange)="toggleCompanyPlan($event)"
                              [ngModelOptions]="{ standalone: true }"
                              #companyPlanToggle
                            >
                              {{
                                companyPlanToggle.checked
                                  ? 'Enabled'
                                  : 'Disabled'
                              }}
                            </mat-slide-toggle>
                          </div>
                        </div>
                        <div class="divider"></div>
                      </ng-container>
                    </form>
                  </ng-container>
                </mat-tab>
                <!-- end company settings tab -->

                <!-- begin group bank account tab -->
                <mat-tab
                  label="Group Bank Account"
                  *ngIf="(userDataObservable | async)?.type?.admin"
                >
                  <ng-container
                    *ngIf="stripeConnectObservable | async; else loading"
                  >
                    <div class="card-block">
                      <bank-info [companyBank]="true"></bank-info>
                    </div>
                  </ng-container>
                </mat-tab>
                <!-- end group bank account tab -->
              </mat-tab-group>
            </div>
          </section>
        </div>
      </div>
      <!-- end tabs -->
    </form>
  </ng-container>
  <div>
    <ng-template #loading>
      <div class="spacer"></div>
      <div class="w-100">
        <mat-spinner class="mx-auto"></mat-spinner>
      </div>
      <div class="divider"></div>
    </ng-template>
  </div>
</div>

<ng-template #userProfileForm>
  <form [formGroup]="userDataForm" class="fixedHeightInputsAndButtons">
    <div class="container">
      <div class="row">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>First Name</mat-label>
            <input
              required
              matInput
              formControlName="first"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Last Name</mat-label>
            <input
              required
              matInput
              formControlName="last"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isDoctor">
        <div class="col-sm-4 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Title</mat-label>
            <input
              matInput
              formControlName="title"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-4 px-1">
          <mat-form-field class="full-width">
            <mat-label>Qualifications (M.D., years in practice, etc.)</mat-label>
            <input
              matInput
              maxlength="30"
              formControlName="qualifications"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-4 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Booking Instructions</mat-label>
            <input
              matInput
              maxlength="250"
              formControlName="bookingInstructions"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isDoctor">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Provider Number (eg. OHIP)</mat-label>
            <input
              matInput
              formControlName="providerNumber"
            />
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>License Number (eg. CPSO)</mat-label>
            <input
              matInput
              formControlName="licenseCode"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isDoctor">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Languages Spoken</mat-label>
            <input
              matInput
              placeholder="English, French, etc."
              formControlName="languages"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Specialty</mat-label>
            <mat-select formControlName="specialty">
              <mat-option
                *ngFor="let spec of availableSpecialties"
                [value]="spec"
              >
                {{ spec }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isDoctor">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Cell Phone Number</mat-label>
            <input
              required
              matInput
              insig-phone-mask
              type="tel"
              minLength="10"
              maxLength="14"
              formControlName="phone"
            />
            <mat-error>Please enter a valid phone number</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Clinic Phone Number</mat-label>
            <input
              required
              matInput
              insig-phone-mask
              type="tel"
              minLength="10"
              maxLength="14"
              formControlName="clinicPhone"
            />
            <mat-error>Please enter a valid phone number</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="!isDoctor">
        <div class="col-12 col-md-3 pr-md-1">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input
              required
              matInput
              #address
              formControlName="address"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-9">
          <div class="row">
            <div class="col-4 pr-1 pl-md-1">
              <mat-form-field class="full-width">
                <mat-label>City</mat-label>
                <input
                  required
                  matInput
                  formControlName="city"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>

            <div class="col-4 px-1">
              <mat-form-field class="full-width">
                <mat-label>Province</mat-label>
                <mat-select
                  formControlName="province"
                >
                  <ng-container
                    *ngIf="
                      userDataForm.controls.country.value === 'Canada'
                    "
                    ><mat-option
                      *ngFor="let province of canadianProvinces"
                      [value]="province.id"
                      >{{ province.name }}</mat-option
                    ></ng-container
                  >
                  <ng-container
                    *ngIf="
                      userDataForm.controls.country.value ===
                      'United States'
                    "
                    ><mat-option
                      *ngFor="let state of americanStates"
                      [value]="state.id"
                      >{{ state.name }}</mat-option
                    ></ng-container
                  >
                  <mat-option value="N/A">N/A</mat-option>
                  <mat-error>Required</mat-error>
                </mat-select>
              </mat-form-field>
            </div>
    
            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Country</mat-label>
                <mat-select
                  formControlName="country"
                  (selectionChange)="countryChanged($event)"
                >
                  <mat-option
                    *ngFor="let country of countryList"
                    [value]="country"
                    >{{ country }}</mat-option
                  >
                </mat-select>
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 pr-1">
              <mat-form-field class="full-width">
                <mat-label>Postal Code / Zip Code</mat-label>
                <input
                  required
                  matInput
                  minlength="5"
                  maxlength="7"
                  formControlName="postalCode"
                />
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-6 pl-1 pr-md-1">
              <mat-form-field class="full-width">
                <mat-label>Health Card Number</mat-label>
                <input
                  matInput
                  formControlName="healthCardNumber"
                  autocomplete="off"
                />
                <mat-error>Please enter a valid health card number for the selected province</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 pr-1 pl-md-1">
              <mat-form-field class="full-width">
                <mat-label>Cell Phone Number</mat-label>
                <input
                  required
                  matInput
                  insig-phone-mask
                  type="tel"
                  minLength="10"
                  maxLength="14"
                  formControlName="phone"
                />
                <mat-error>Please enter a valid phone number</mat-error>
              </mat-form-field>
            </div>
            <div class="col-6 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Ext.</mat-label>
                <input
                  matInput
                  pattern="[0-9#*w]+"
                  formControlName="extension"
                />
                <mat-error>(0-9, #, *)</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12">
          <label for="birthdayInputFields">Birthday</label>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-8 pr-1">
              <div class="row mx-0" id="birthdayInputFields">
                <div class="col-4 px-0">
                  <mat-form-field class="full-width">
                    <mat-label>YYYY</mat-label>
                    <input
                      type="tel"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="4"
                      minlength="4"
                      max="{{ thisYear }}"
                      min="1900"
                      matInput
                      autocomplete="off"
                      formControlName="year"
                    />
                    <mat-error>Please enter a valid year</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-4 px-0">
                  <mat-form-field class="full-width">
                    <mat-label>MM</mat-label>
                    <input
                      type="tel"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="2"
                      minlength="1"
                      matInput
                      autocomplete="off"
                      max="12"
                      min="1"
                      formControlName="month"
                    />
                    <mat-error>Please enter a valid month</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-4 px-0">
                  <mat-form-field class="full-width">
                    <mat-label>DD</mat-label>
                    <input
                      type="tel"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      maxlength="2"
                      minlength="1"
                      matInput
                      autocomplete="off"
                      max="31"
                      min="1"
                      formControlName="day"
                    />
                    <mat-error>Please enter a valid day</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Gender At Birth</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12">
          <label for="familyDoctorInputFields">Family Doctor</label>
        </div>

        <div class="col-12">
          <div class="row" id="familyDoctorInputFields">
            <div class="col-4 pr-1">
              <mat-form-field class="full-width">
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  formControlName="familyDoctorFirstName"
                />
              </mat-form-field>
            </div>

            <div class="col-4 px-1">
              <mat-form-field class="full-width">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  formControlName="familyDoctorLastName"
                />
              </mat-form-field>
            </div>

            <div class="col-4 pl-1">
              <mat-form-field class="full-width">
                <mat-label>Fax Number</mat-label>
                <input
                  matInput
                  insig-phone-mask
                  type="tel"
                  minLength="10"
                  maxLength="14"
                  formControlName="familyDoctorFaxNumber"
                />
                <mat-error>Please enter a valid fax number</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isDoctor">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input
              required
              matInput
              #address
              formControlName="address"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Fax Number</mat-label>
            <input
              matInput
              insig-phone-mask
              minLength="10"
              maxLength="14"
              formControlName="fax"
            />
          </mat-form-field>
        </div>
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Province / State</mat-label>
            <mat-select
              formControlName="province"
            >
              <ng-container
                *ngIf="
                  (companyDataObservable | async)?.country
                    ?.name === 'Canada'
                "
                ><mat-option
                  *ngFor="let province of canadianProvinces"
                  [value]="province.id"
                  >{{ province.name }}</mat-option
                ></ng-container
              >
              <ng-container
                *ngIf="
                  (companyDataObservable | async)?.country
                    ?.name === 'United States'
                "
                ><mat-option
                  *ngFor="let state of americanStates"
                  [value]="state.id"
                  >{{ state.name }}</mat-option
                ></ng-container
              >
              <mat-option value="N/A">N/A</mat-option>
              <mat-error>Required</mat-error>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>City</mat-label>
            <input
              required
              matInput
              formControlName="city"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="!isDoctor" id="preferredPharmacyInputFields">
        <div class="col-12">
          <label for="preferredPharmacyInputFields">Preferred Pharmacy</label>
        </div>

        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Name</mat-label>
            <input
              required
              matInput
              formControlName="pharmaName"
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <mat-form-field class="full-width">
            <mat-label>Fax Number</mat-label>
            <input
              required
              matInput
              insig-phone-mask
              minLength="10"
              maxLength="14"
              formControlName="pharmaFax"
            />
            <mat-error>Please enter a valid fax number</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-1">
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
            />
          </mat-form-field>
        </div>
        <div class="col-6 pl-1 text-center">
          <button
            class="w-100"
            mat-raised-button
            (click)="handleChangeEmailButtonClicked()"
          >
            Change Email
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-1 text-center">
          <button
            class="w-100"
            mat-raised-button
            (click)="handleChangePasswordButtonClicked()"
          >
            Change Password
          </button>
        </div>
        <div class="col-6 pl-1 text-center">
          <button
            class="w-100"
            mat-raised-button
            (click)="handleAddMfaButtonClicked()"
          >
            <span>Manage Multifactor Auth</span><span class="d-none d-lg-inline">entication</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
