// @ts-strict-ignore
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { APPCONFIG } from '@insig-health/config/config';
// services
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { VirtualVisitService } from 'insig-app/services/virtual-visit.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';


@Component({
  selector: 'my-app-header',
  templateUrl: './header.component.html',
  providers: [TranslateService, VirtualVisitService],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public AppConfig: any = APPCONFIG;
  public flag: string;
  public languageList: string[];
  public userNotifications: any = [];
  private unsubscribe = null;
  private messagesSubscription = null;
  public unreadConvosLength = 0;
  public unreadConvos = [];
  private IDToken = null;
  public disableHelp = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private virtualVisitService: VirtualVisitService,
    private firebaseAuthService: FirebaseAuthService
  ) {}

  async ngOnInit() {
    this.languageList = await this.translateService.getLanguageList();
    this.unsubscribe = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
          this.checkUnreadMessages(user.uid);
        }
      });

    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.disableHelp = true;
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  } // end ngOnDestroy

  checkUnreadMessages(userID) {
    this.messagesSubscription = this.virtualVisitService
      .checkUnreadMessages(userID)
      .subscribe((unreadConvos) => {
        this.unreadConvos = [];
        unreadConvos.map(async (doc) => {
          const unreadConvo = doc.data();
          unreadConvo.id = doc.id;
          try {
            const conversationAdditionalDetails: any = await this.virtualVisitService.loadConversationAdditionalDetails(
              this.IDToken,
              doc.id,
              unreadConvo.lastMessageBy
            );
            if (
              conversationAdditionalDetails &&
              conversationAdditionalDetails.body &&
              conversationAdditionalDetails.statusCode === 200 &&
              conversationAdditionalDetails.body.name
            ) {
              unreadConvo.name = conversationAdditionalDetails.body.name;
            }
          } catch (err) {
            console.log(err);
          }
          this.unreadConvos.push(unreadConvo);
          this.unreadConvos = this.sortNotifications(this.unreadConvos);
        });
      });
  } // end func

  sortNotifications(array) {
    return array.sort((a, b) => {
      return b.lastMessage - a.lastMessage;
    });
  }

  openMessage(convo: { id: string }): void {
    // Remove the opened convo from unread convos
    const convoIndex = this.unreadConvos.indexOf(convo);
    this.unreadConvos.splice(convoIndex, 1);

    this.router.navigate(['app/virtual/dashboard']);
    // time out so can properly move between appointments and reload everything
    setTimeout(async () => {
      this.router.navigate([`app/virtual/messaging/${convo.id}`]);
    }, 1000);
  } // end func

  capitalize(value: any) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  async switchLanguage(language: any) {
    this.translateService.changeLanguage(language);
  }

  async logout() {
    await this.firebaseAuthService.signOutThenReturnToLoginPage();
  }
}
