// @ts-strict-ignore
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';

import { Apollo, gql } from 'apollo-angular';

import { APPCONFIG, APPURL, JAVA_BACKEND_ENDPOINT, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';

// Services
import { GeneralService, ShortCode } from '../services/general.service';
import { FileUploadService } from '../services/fileUpload.service';
import { DoctorSettingsService } from '../services/doctorSettings.service';
import { PaymentService } from '../services/payment.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { RegexService } from '@insig-health/services/regex/regex.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChangeProfileImageDialogComponent } from './dialogs/change-profile-image-dialog.component';
import { SignatureDialogComponent } from 'insig-app/surveys/launch-survey/question-types/signature/signature-dialog.component';

import firebase from 'firebase/compat/app';
import {
  of as observableOf,
  from as observableFrom,
  Observable,
  Subscription,
  firstValueFrom,
  lastValueFrom,
  from,
} from 'rxjs';
import { take, filter, shareReplay, map, switchMap, tap } from 'rxjs/operators';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { CompanyData } from 'insig-types/company-data';
import { UserData } from 'insig-types/user-data';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { ChangeEmailDialogComponent } from './dialogs/change-email-dialog.component';
import { MfaEnrollmentDialogComponent } from './dialogs/mfa-enrollment-dialog/mfa-enrollment-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { DoctorService } from '@insig-health/services/doctor/doctor.service';
import { DoctorUpdateRequest } from '@insig-health/api/doctor-api';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';
import { HttpErrorResponse } from '@angular/common/http';

export enum UserDataFormState {
  DIRTY,
  CLEAN,
}

export enum ProfileErrorMessage {
  HEALTH_CARD_INVALID = 'Health card number invalid for the province selected.',
  DEFAULT = 'Error updating information! Please check all fields.',
}

@Component({
  selector: 'my-profile',
  templateUrl: './profile.component.html',
  providers: [
    GeneralService,
    FileUploadService,
    DoctorSettingsService,
    PaymentService,
    PatientUserDataService,
  ],
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  public static userDataFormState = UserDataFormState.CLEAN;
  public UserDataFormState = UserDataFormState;

  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        first
        last
        phone
        clinicPhone
        email
        company
        address
        province
        city
        signature
        fax
        providerNumber
        title
        qualifications
        bookingInstructions
        languages
        licenseCode
        specialty
        type {
          admin
        }
        image
        apiToken
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        name
        companyUrl
        country {
          name
        }
        branding
        companyPlan
        EMREmailEnabled
        EMRInboxEmail
        apptEmail
        faxEmail
        email
        website
      }
    }
  `;

  private companyDataMutation = gql`
    mutation CompanyDataMutation(
      $companyID: ID!
      $token: ID!
      $data: CompanyInput!
    ) {
      setCompanyData(cid: $companyID, token: $token, data: $data) {
        id
      }
    }
  `;

  private companyPlanMutation = gql`
    mutation CompanyPlanMutation(
      $companyID: ID!
      $token: ID!
      $data: CompanyInput!
    ) {
      setCompanyData(cid: $companyID, token: $token, data: $data) {
        companyPlan
      }
    }
  `;

  private companyBrandingMutation = gql`
    mutation companyBrandingMutation(
      $companyID: ID!
      $token: ID!
      $data: CompanyInput!
    ) {
      setCompanyData(cid: $companyID, token: $token, data: $data) {
        branding
      }
    }
  `;

  private userDataMutation = gql`
    mutation UserDataMutation($userID: ID!, $token: ID!, $data: UserInput!) {
      setUserData(uid: $userID, token: $token, data: $data) {
        uid
      }
    }
  `;

  private userImageMutation = gql`
    mutation UserImageMutation($userID: ID!, $token: ID!, $data: UserInput!) {
      setUserData(uid: $userID, token: $token, data: $data) {
        image
      }
    }
  `;

  //

  public AppConfig: any;
  public doneCheckingStripe = false;
  public isPatient = false;
  public isAdmin = false;
  private today = new Date();
  public APPURL = APPURL;

  public canadianProvinces = this.patientUserDataService.provinces;
  public americanStates = this.patientUserDataService.americanStates;
  public countryList = ['Canada', 'United States', 'Mexico', 'Grenada'];

  @ViewChild('tabGroup') public tabGroup: MatTabGroup;
  @ViewChildren('address') public addressElements: QueryList<ElementRef>;
  private addressElementsSubscription: Subscription;
  private availableSpecialtiesSubcription: Subscription;

  public userObservable = this.firebaseAuthService.onIdTokenChanged().pipe(
    map((user) => {
      if (!user) {
        this.router.navigate(['/auth/login']);
      }
      return user;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    filter((user) => !!user),
    shareReplay({ refCount: true, bufferSize: 1 }),
    // tap((x) => console.log(x))
  );

  public isDoctorObservable = this.userObservable.pipe(
    switchMap((user) => {
      return observableFrom(this.firebaseAuthService.checkIfUserIsDoctor(user));
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    // tap((x) => console.log(x))
  );

  public userDataObservable = this.isDoctorObservable.pipe(
    switchMap((isDoctor) => {
      return this.userObservable.pipe(
        switchMap((user) =>
          isDoctor
            ? this.getDoctorData(user)
            : observableFrom(this.getPatientData(user)),
        ),
      );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    tap((x) => console.log(x)),
  );

  public companyDataObservable = this.isDoctorObservable.pipe(
    switchMap((isDoctor) => {
      if (isDoctor) {
        return this.userDataObservable.pipe(
          switchMap((userData) => this.getCompanyData(userData.company)),
        );
      } else {
        return observableOf({} as CompanyData);
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    // tap((x) => console.log(x))
  );

  public stripeConnectObservable = this.isDoctorObservable.pipe(
    switchMap((isDoctor) => {
      if (isDoctor) {
        return this.userObservable.pipe(
          switchMap((user) => observableFrom(user.getIdToken())),
          switchMap((idToken) => observableFrom(this.connectStripe(idToken))),
          map(() => true),
        );
      } else {
        return observableOf(false);
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    // tap((x) => console.log(x))
  );

  public availableSpecialtiesObservable = this.generalService
    .getAvailableSpecialties()
    .pipe(
      map((specialties) => {
        return specialties.map((specialty) => specialty.name);
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
      // tap((x) => console.log(x))
    );

  public userDataFormSubscription: Subscription | undefined;

  public availableSpecialties: any;
  public userDataForm: UntypedFormGroup;
  public companyEMRForm: UntypedFormGroup;
  public companySettingsForm: UntypedFormGroup;

  public branding: any;
  public signature: any;
  public profilePictureUrl: string;
  public isDoctor = true;

  constructor(
    public firebaseAuthService: FirebaseAuthService,
    private generalService: GeneralService,
    private regexService: RegexService,
    private router: Router,
    private paymentService: PaymentService,
    public snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
    private patientUserDataService: PatientUserDataService,
    private formBuilder: UntypedFormBuilder,
    private apollo: Apollo,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
    private patientProfileService: PatientProfileService,
    private formValidatorsService: FormValidatorsService,
    private doctorService: DoctorService,
    private googlePlaceService: GooglePlaceService,
  ) {
    this.AppConfig = APPCONFIG;
  }

  async ngOnInit(): Promise<void> {
    // construct and resolve all initialization Promises asynchronously
    const formBuildPromises = [
      this.buildUserDataForm(),
      this.buildCompanyEMRForm(),
      this.buildCompanySettingsForm(),
    ];
    const initDataPromises = [
      this.userDataObservable.pipe(take(1)).toPromise(),
      this.companyDataObservable.pipe(take(1)).toPromise(),
    ];

    // specialties subscription
    this.availableSpecialtiesSubcription = this.availableSpecialtiesObservable.subscribe(
      (specialties) => {
        this.availableSpecialties = specialties.sort();
      },
    );

    const initPromises = [
      Promise.all(formBuildPromises),
      Promise.all(initDataPromises),
    ];
    let initUserData;
    let initCompanyData;
    [
      [this.userDataForm, this.companyEMRForm, this.companySettingsForm],
      [initUserData, initCompanyData],
    ] = await Promise.all(initPromises);

    console.log('promises resolved');

    this.signature = initUserData.signature;
    this.branding = initCompanyData.branding;
    this.profilePictureUrl = initUserData.image;

    this.userDataFormSubscription = this.userDataForm.valueChanges.subscribe(() => {
      if (this.userDataForm.dirty && ProfileComponent.userDataFormState === UserDataFormState.CLEAN) {
        window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
        ProfileComponent.userDataFormState = UserDataFormState.DIRTY;
      }
    });
  }

  ngAfterViewInit(): void {
    try {
      this.addressElementsSubscription = this.addressElements.changes.subscribe(
        (components: QueryList<ElementRef>) => {
          // attach listener to Places Autocomplete
          this.googlePlaceService.getPlaceResultObservable(components.first.nativeElement).subscribe((placeResult) => {
            if (this.isDoctor) {
              this.autocompleteDoctorAddressFields(placeResult);
            } else {
              this.autocompletePatientAddressFields(placeResult);
            }
          });
        },
      );
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    if (this.addressElementsSubscription) {
      this.addressElementsSubscription.unsubscribe();
    }

    if (this.availableSpecialtiesSubcription) {
      this.availableSpecialtiesSubcription.unsubscribe();
    }

    this.userDataFormSubscription?.unsubscribe();
    ProfileComponent.userDataFormState = UserDataFormState.CLEAN;
    window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
  }

  async autocompleteDoctorAddressFields(placeResult: google.maps.places.PlaceResult): Promise<void> {
    this.userDataForm.controls.address.setValue(this.getStreetAddressFromPlaceResult(placeResult));
    const companyCountry = (await firstValueFrom(this.companyDataObservable))?.country?.name;

    const placeResultCountry = this.getCountryFromPlaceResult(placeResult);
    if ((companyCountry === 'Canada' && placeResultCountry === 'Canada') || (companyCountry === 'United States' && placeResultCountry === 'United States')) {
      this.userDataForm.controls.province.setValue(
        this.getLevelOneAdministrativeAreaAbbreviationFromPlaceResult(placeResult));
    } else {
      this.userDataForm.controls.province.setValue('N/A');
    }

    this.userDataForm.controls.city.setValue(this.getLocalityFromPlaceResult(placeResult));
  }

  autocompletePatientAddressFields(placeResult: google.maps.places.PlaceResult): void {
    this.userDataForm.controls.address.setValue(this.getStreetAddressFromPlaceResult(placeResult));

    const country = this.getCountryFromPlaceResult(placeResult);
    this.userDataForm.controls.country.setValue(country);

    if (country === 'Canada' || country === 'United States') {
      this.userDataForm.controls.province.setValue(this.getLevelOneAdministrativeAreaAbbreviationFromPlaceResult(placeResult));
    } else {
      this.userDataForm.controls.province.setValue('N/A');
    }
    this.userDataForm.controls.postalCode.setValue(this.getPostalCodeFromPlaceResult(placeResult));
    this.userDataForm.controls.city.setValue(this.getLocalityFromPlaceResult(placeResult));
  }

  getStreetAddressFromPlaceResult(placeResult: google.maps.places.PlaceResult): string {
    const streetNumber = placeResult.address_components.find((component) =>
      component.types.includes('street_number'),
    );

    const route = placeResult.address_components.find((component) =>
      component.types.includes('route'),
    );

    if (streetNumber === undefined && route === undefined) {
      return '';
    }

    if (streetNumber === undefined) {
      return route.long_name;
    }

    if (route === undefined) {
      return streetNumber.long_name;
    }

    return `${streetNumber.long_name} ${route.long_name}`;
  }

  getPostalCodeFromPlaceResult(placeResult: google.maps.places.PlaceResult): string {
    const postalCode = placeResult.address_components.find((component) =>
      component.types.includes('postal_code'),
    );
    return postalCode?.long_name ?? '';
  }

  getLocalityFromPlaceResult(placeResult: google.maps.places.PlaceResult): string {
    const city = placeResult.address_components.find((component) =>
      component.types.includes('locality'),
    );
    return city?.long_name ?? '';
  }

  getLevelOneAdministrativeAreaAbbreviationFromPlaceResult(placeResult: google.maps.places.PlaceResult): string {
    const provinceComponent = placeResult.address_components.find((component) =>
      component.types.includes('administrative_area_level_1'),
    );
    return provinceComponent?.short_name ?? '';
  }

  getCountryFromPlaceResult(placeResult: google.maps.places.PlaceResult): string {
    const countryComponent = placeResult.address_components.find((component) =>
      component.types.includes('country'),
    );
    return countryComponent?.long_name ?? '';
  }

  countryChanged(_event): void {
    // If the country changed reset the province field value
    this.userDataForm.controls.province.setValue(null);
  }

  async buildUserDataForm(): Promise<UntypedFormGroup> {
    const userData = await firstValueFrom(this.userDataObservable);
    this.isDoctor = await firstValueFrom(this.isDoctorObservable);
    console.log(userData);
    if (this.isDoctor) {
      return this.formBuilder.group({
        first: this.formBuilder.control(userData.first, [Validators.required]),
        last: this.formBuilder.control(userData.last, [Validators.required]),
        email: this.formBuilder.control(
          { value: userData.email, disabled: true },
          [Validators.required],
        ),
        phone: this.formBuilder.control(userData.phone, [
          Validators.required,
          this.validatePhoneNumber(),
        ]),
        clinicPhone: this.formBuilder.control(userData.clinicPhone, [
          Validators.required,
          this.validatePhoneNumber(),
        ]),
        address: this.formBuilder.control(userData.address, [
          Validators.required,
        ]),
        province: this.formBuilder.control(userData.province, [
          Validators.required,
        ]),
        city: this.formBuilder.control(userData.city, [
          Validators.required,
          Validators.minLength(2),
        ]),
        title: this.formBuilder.control(userData.title),
        providerNumber: this.formBuilder.control(userData.providerNumber),
        fax: this.formBuilder.control(userData.fax),
        qualifications: this.formBuilder.control(userData.qualifications, [
          Validators.maxLength(30),
        ]),
        bookingInstructions: this.formBuilder.control(userData.bookingInstructions, [
          Validators.maxLength(250),
        ]),
        languages: this.formBuilder.control(userData.languages, [
          Validators.required,
        ]),
        licenseCode: this.formBuilder.control(userData.licenseCode),
        specialty: this.formBuilder.control(userData.specialty),
      });
    } else {
      return this.formBuilder.group({
        first: this.formBuilder.control(userData.first, [Validators.required]),
        last: this.formBuilder.control(userData.last, [Validators.required]),
        email: this.formBuilder.control(
          { value: userData.email, disabled: true },
          [Validators.required],
        ),
        phone: this.formBuilder.control(userData.phone, [
          Validators.required,
          this.validatePhoneNumber(),
        ]),
        address: this.formBuilder.control(userData.address, [
          Validators.required,
        ]),
        country: this.formBuilder.control(userData.country, [
          Validators.required,
        ]),
        province: this.formBuilder.control(userData.province, [
          Validators.required,
        ]),
        city: this.formBuilder.control(userData.city, [
          Validators.required,
          Validators.minLength(2),
        ]),
        postalCode: this.formBuilder.control(userData.postalCode, [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
        pharmaName: this.formBuilder.control(userData.pharmaName, [Validators.required]),
        pharmaFax: this.formBuilder.control(userData.pharmaFax, [Validators.required, this.validatePhoneNumber()]),
        healthCardNumber: this.formBuilder.control(userData.healthCardNumber),
        extension: this.formBuilder.control(userData.extension),
        year: this.formBuilder.control(userData.year, [
          Validators.required,
          Validators.min(1900),
          Validators.max(this.today.getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(userData.month, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(userData.day, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(userData.gender, [
          Validators.required,
        ]),
        familyDoctorFirstName: this.formBuilder.control(userData.familyDoctorFirstName),
        familyDoctorLastName: this.formBuilder.control(userData.familyDoctorLastName),
        familyDoctorFaxNumber: this.formBuilder.control(userData.familyDoctorFaxNumber, [
          this.formValidatorsService.isPhoneNumberValidValidator(true),
        ]),
      });
    }
  }

  async buildCompanyEMRForm(): Promise<UntypedFormGroup> {
    const companyData = await this.companyDataObservable
      .pipe(take(1))
      .toPromise();
    return this.formBuilder.group({
      EMREmailEnabled: this.formBuilder.control(companyData.EMREmailEnabled),
      EMRInboxEmail: this.formBuilder.control(companyData.EMRInboxEmail),
    });
  }

  async buildCompanySettingsForm(): Promise<UntypedFormGroup> {
    const companyData = await this.companyDataObservable
      .pipe(take(1))
      .toPromise();
    console.log(companyData);
    return this.formBuilder.group({
      name: this.formBuilder.control(companyData.name),
      apptEmail: this.formBuilder.control(companyData.apptEmail),
      faxEmail: this.formBuilder.control(companyData.faxEmail),
      companyUrl: this.formBuilder.control(companyData.companyUrl),
      website: this.formBuilder.control(companyData.website),
    });
  }

  async connectStripe(idToken: string): Promise<void> {
    const response = await this.paymentService.connectStripeAccount(
      idToken,
      true,
    );
    if (response) {
      console.log('Connecting Stripe response:');
      console.log(response);
      this.snackBar.open(response.body, null, { duration: 4000 });
      this.tabGroup.selectedIndex = 3;
      // needed to remove code from URL so it doesnt try again on refresh
      this.router.navigate(['/app/profile'], { replaceUrl: true });
    }
  }

  getDoctorData(user: firebase.User): Observable<UserData> {
    return observableFrom(user.getIdToken()).pipe(
      switchMap((idToken) =>
        this.apollo.query<{ getUserData: UserData }>({
          fetchPolicy: 'no-cache',
          query: this.userDataQuery,
          variables: {
            userID: user.uid,
            token: idToken,
          },
        }),
      ),
      map((result) => result.data.getUserData),
    );
  }

  async getPatientData(user: firebase.User): Promise<any> {
    const patientProfile = await firstValueFrom(this.patientProfileService.getCurrentUserPatientProfile());
    return {
      uid: user.uid,
      first: patientProfile.firstName,
      firstName: patientProfile.firstName,
      last: patientProfile.lastName,
      lastName: patientProfile.lastName,
      address: patientProfile.address,
      city: patientProfile.city,
      birthday: patientProfile.birthdate,
      day: patientProfile.day,
      month: patientProfile.month,
      year: patientProfile.year,
      email: patientProfile.email,
      gender: patientProfile.gender,
      phone: patientProfile.phone,
      extension: patientProfile.extension,
      province: patientProfile.province,
      country: patientProfile.country,
      postalCode: patientProfile.postalCode,
      pharmaFax: patientProfile.pharmacyFax,
      pharmaName: patientProfile.pharmacyName,
      healthCardNumber: patientProfile.healthCardNumber,
      familyDoctorFirstName: patientProfile.familyDoctorFirstName,
      familyDoctorLastName: patientProfile.familyDoctorLastName,
      familyDoctorFaxNumber: patientProfile.familyDoctorFaxNumber,
    };
  }

  getCompanyData(cid: string): Observable<CompanyData> {
    return from(this.firebaseAuthService.getIdToken()).pipe(
      switchMap((firebaseIdToken) => {
        return this.apollo.query<{ getCompanyData: CompanyData }>({
          fetchPolicy: 'no-cache',
          query: this.companyDataQuery,
          variables: {
            companyID: cid,
            token: firebaseIdToken,
          },
        })
      }),
      map((result) => result.data.getCompanyData),
    );
  }

  async companyDataUrlTaken(url: string): Promise<boolean> {
    if (!url) {
      return false;
    }
    const shortCode: ShortCode = await this.generalService
      .getShortcodeByShortcode(url)
      .pipe(take(1))
      .toPromise();
    const companyId = shortCode ? shortCode['cid'] : null;
    const cid = (await this.userDataObservable.pipe(take(1)).toPromise())
      .company;
    return companyId ? companyId !== cid : false;
  }

  async saveData(): Promise<void> {
    const isCompanyAdmin = await this.isCompanyAdmin();
    const uid = (await firstValueFrom(this.userObservable)).uid;

    if (!this.userDataForm.valid) {
      this.tabGroup.selectedIndex = 0;
      if(this.userDataForm.controls['qualifications']?.errors) {
        this.snackBar.open('Qualifications must be less than 30 characters', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else if (this.userDataForm.controls['bookingInstructions']?.errors) {
        this.snackBar.open('Booking Instructions must be less than 250 characters', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      } else {
        this.snackBar.open(
          'Please fix the errors in the User Data form before saving!',
          null,
          { duration: 3000 },
        );
      }
      console.log('Userdata error');
      console.log(this.userDataForm);
      // if patient account
    } else if (!this.isDoctor) {
      const formValues = this.userDataForm.value;
      this.mutatePatientData(formValues, this.userDataForm);
    } else if (isCompanyAdmin && !this.companyEMRForm.valid) {
      this.tabGroup.selectedIndex = 1;
      this.snackBar.open(
        'Please fix the errors in the \'Integrations\' tab before saving!',
        null,
        { duration: 3000 },
      );
      console.log('companyEMR From error');
      console.log(this.companyEMRForm);
    } else if (isCompanyAdmin && !this.companySettingsForm.valid) {
      this.tabGroup.selectedIndex = 2;
      this.snackBar.open(
        'Please fix the errors in the \'Company Settings\' tab before saving!',
        null,
        { duration: 3000 },
      );
      console.log('company settings form error');
      console.log(this.companySettingsForm);
    } else {
      // All forms validated
      const cid = (await firstValueFrom(this.userDataObservable))
        .company;
      const idToken = await (
        await firstValueFrom(this.userObservable)
      ).getIdToken();

      const userDataFormValues = this.userDataForm.value;
      const doctorUpdateRequest: DoctorUpdateRequest = {
        firstName: userDataFormValues.first,
        lastName: userDataFormValues.last,
        title: userDataFormValues.title,
        qualifications: userDataFormValues.qualifications,
        providerNumber: userDataFormValues.providerNumber,
        licenseNumber: userDataFormValues.licenseCode,
        languages: userDataFormValues.languages,
        specialty: userDataFormValues.specialty,
        cellPhoneNumber: userDataFormValues.phone.replace(this.regexService.getNonDigitGlobalRegex(), ''),
        clinicPhoneNumber: userDataFormValues.clinicPhone.replace(this.regexService.getNonDigitGlobalRegex(), ''),
        address: userDataFormValues.address,
        faxNumber: userDataFormValues.fax,
        province: userDataFormValues.province,
        city: userDataFormValues.city,
        bookingInstructions: userDataFormValues.bookingInstructions,
        signature: this.signature,
      };

      const companyEMRFormValues = this.companyEMRForm.value;
      const companySettingsFormValues = this.companySettingsForm.value;
      const companyData: any = {
        EMREmailEnabled: companyEMRFormValues.EMREmailEnabled || false,
        EMRInboxEmail: companyEMRFormValues.EMRInboxEmail || '',

        name: companySettingsFormValues.name,
        apptEmail: companySettingsFormValues.apptEmail,
        faxEmail: companySettingsFormValues.faxEmail,
        website: companySettingsFormValues.website,
      };

      // if value for companyURL and not taken then set it
      if (
        !(await this.companyDataUrlTaken(
          companySettingsFormValues.companyUrl,
        )) &&
        companySettingsFormValues.companyUrl &&
        (await firstValueFrom(this.userDataObservable)
          .then((data) => data.type.admin))
      ) {
        companyData.companyUrl = companySettingsFormValues.companyUrl;
        // save company shortcode
        const oldCompanyData = await firstValueFrom(this.companyDataObservable);
        await this.generalService.saveCompanyIdToShortcode(
          oldCompanyData.id,
          companySettingsFormValues.companyUrl,
          idToken,
        );
      }

      try {
        await this.doctorService.updateDoctor(uid, doctorUpdateRequest);

        if (isCompanyAdmin) {
          const mutateCompanyData: any = await firstValueFrom(this.apollo
            .mutate({
              mutation: this.companyDataMutation,
              variables: {
                companyID: cid,
                token: idToken,
                data: companyData,
              },
            }));
          const companyDataResponse = mutateCompanyData.data.setCompanyData;
          if (!companyDataResponse) {
            this.snackBar.open('Error saving! Please try again.', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
          }
        }

        this.snackBar.open('Saved successfully!', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        ProfileComponent.userDataFormState = UserDataFormState.CLEAN;
        window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      } catch (error) {
        console.error(error);
        this.snackBar.open('Error saving! Please try again.', null, {
          duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
        });
      }

      await this.doctorScheduleReindexService.reindexSchedule(uid, cid);
    }
  }

  /** @firestore Firestore migration */
  async mutatePatientData(newUserData: {
    address: string,
    city: string,
    country: string,
    day: string,
    extension: string,
    familyDoctorFaxNumber: string,
    familyDoctorFirstName: string,
    familyDoctorLastName: string,
    first: string,
    gender: string,
    healthCardNumber: string,
    last: string,
    month: string,
    pharmaFax: string,
    pharmaName: string,
    phone: string,
    postalCode: string,
    province: string,
    year: string,
  }, userDataForm: FormGroup<{
    healthCardNumber: FormControl<string | null>;
  }>): Promise<void> {
    try {
      await this.patientProfileService.setCurrentUserPatientProfile({
        firstName: newUserData.first,
        lastName: newUserData.last,
        birthdate: this.patientProfileService.getBirthdayFromYearMonthDay(
          parseInt(newUserData.year, 10),
          parseInt(newUserData.month, 10),
          parseInt(newUserData.day, 10)),
        gender: this.patientProfileService.isGenderType(newUserData.gender) ? newUserData.gender : undefined,
        address: newUserData.address,
        city: newUserData.city,
        province: newUserData.province,
        country: newUserData.country,
        postalCode: newUserData.postalCode,
        healthCardNumber: newUserData.healthCardNumber,
        phone: newUserData.phone,
        pharmacyName: newUserData.pharmaName,
        pharmacyFax: newUserData.pharmaFax,
        extension: newUserData.extension,
        familyDoctorFirstName: newUserData.familyDoctorFirstName,
        familyDoctorLastName: newUserData.familyDoctorLastName,
        familyDoctorFaxNumber: newUserData.familyDoctorFaxNumber,
      });
      this.snackBar.open('Information updated!', null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      ProfileComponent.userDataFormState = UserDataFormState.CLEAN;
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const errorMessage = error.error?.errorMessage ?? ProfileErrorMessage.DEFAULT;
        this.snackBar.open(errorMessage, null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
        if (errorMessage === ProfileErrorMessage.HEALTH_CARD_INVALID) {
          userDataForm.controls.healthCardNumber.setErrors({ invalid: true });
        }
      } else {
        this.snackBar.open(ProfileErrorMessage.DEFAULT, null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
      }
    }
  }

  async isCompanyAdmin(): Promise<boolean> {
    const userData = await this.userDataObservable.pipe(take(1)).toPromise();
    return !!userData && !!userData.type && userData.type.admin;
  }

  async removeBranding(): Promise<void> {
    const cid = (await this.userDataObservable.pipe(take(1)).toPromise())
      .company;
    const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
    const data = {
      branding: null,
    };
    const companyBrandingMutation: any = await this.apollo
      .mutate({
        mutation: this.companyBrandingMutation,
        variables: {
          companyID: cid,
          token,
          data,
        },
      })
      .toPromise();

    const companyData = companyBrandingMutation.data.setCompanyData;
    if (!!companyData && !companyData.branding) {
      this.branding = null;
      this.snackBar.open('Company branding removed successfully!', null, {
        duration: 4000,
      });
    } else {
      this.snackBar.open(
        'There was a problem removing the branding. Please try again',
        null,
        { duration: 4000 },
      );
    }
  }

  async brandingAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const company = (await this.userDataObservable.pipe(take(1)).toPromise())
      .company;
    console.log(company);
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    const uploadTask = this.fileUploadService.uploadFile(
      'company/branding/' + company + '/' + company + extension,
      fileData,
    );
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (_snapshot) => {
        // do nothing
      },
      (error) => {
        console.log(error);
        this.snackBar.open(
          `There was a problem uploading your file: ${error.message || error}.`,
          null,
          { duration: 4000 },
        );
      },
      async () => {
        console.log(uploadTask);
        console.log(uploadTask.snapshot);
        console.log(await uploadTask.snapshot.ref.getDownloadURL());

        try {
          const cid = company;
          const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
          const data = {
            branding: await uploadTask.snapshot.ref.getDownloadURL(),
          };
          const companyBrandingMutation: any = await this.apollo
            .mutate({
              mutation: this.companyBrandingMutation,
              variables: {
                companyID: cid,
                token,
                data,
              },
            })
            .toPromise()
            .catch((error) => {
              console.error(error);
              throw error;
            });

          const companyData = companyBrandingMutation.data.setCompanyData;

          if (
            !!companyData &&
            companyData.branding ===
              (await uploadTask.snapshot.ref.getDownloadURL())
          ) {
            this.branding = await uploadTask.snapshot.ref.getDownloadURL();
            this.snackBar.open('Company branding saved successfully!', null, {
              duration: 4000,
            });
          } else {
            console.log(companyData);
            this.branding = null;
            this.snackBar.open(
              'There was a problem uploading your file. Please try again',
              null,
              { duration: 4000 },
            );
          }
        } catch (error) {
          console.log(error);
          this.branding = null;
          this.snackBar.open(
            `There was a problem uploading your file: ${error.message ||
              error}.`,
            null,
            { duration: 4000 },
          );
        }
      },
    );
  }

  async selectProfileImage(): Promise<void> {
    const dialogRef = this.dialog.open(ChangeProfileImageDialogComponent, {
      data: {
        uid: (await this.userObservable.pipe(take(1)).toPromise()).uid,
        imageUrl: this.profilePictureUrl,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const userID = (await this.userObservable.pipe(take(1)).toPromise())
          .uid;
        const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
        const data = {
          image: result.remove ? null : `${result.imageUrl}`,
        };
        const userImageMutation: any = await this.apollo
          .mutate({
            mutation: this.userImageMutation,
            variables: {
              userID,
              token,
              data,
            },
          })
          .toPromise()
          .catch((error) => {
            console.error(error);
            throw error;
          });

        const userData = userImageMutation.data.setUserData;
        console.log('userdata after save: ', userData);
        if (!!userData && userData.image === result.imageUrl) {
          this.profilePictureUrl = result.imageUrl;
          this.snackBar.open('Profile image saved successfully!', null, {
            duration: 4000,
          });
        } else {
          this.snackBar.open(
            'There was a problem saving your profile picture. Please try again.',
            null,
            { duration: 4000 },
          );
        }
      }
    });
  }

  async toggleCompanyPlan(state: boolean): Promise<void> {
    const companyID = (await this.userDataObservable.pipe(take(1)).toPromise())
      .company;
    const token = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
    const data = {
      companyPlan: state,
    };
    const companyPlanMutation: any = await this.apollo
      .mutate({
        mutation: this.companyPlanMutation,
        variables: {
          companyID,
          token,
          data,
        },
      })
      .toPromise()
      .catch((error) => {
        console.error(error);
        throw error;
      });
    const companyData = companyPlanMutation.data.setCompanyData;
    if (!!companyData && companyData.companyPlan === state) {
      this.snackBar.open(
        `Company plan ${state ? 'enabled' : 'disabled'} successfully!`,
        null,
        { duration: 4000 },
      );
    } else {
      this.snackBar.open(
        `There was a problem ${
          state ? 'enabling' : 'disabling'
        } your company plan status. Please try again.`,
        null,
        { duration: 4000 },
      );
    }
  }

  validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const strippedPhoneNumber = control.value?.replace(this.regexService.getNonDigitGlobalRegex(), '') ?? '';
      const validPhoneNumberLength = 10;
      return strippedPhoneNumber.length === validPhoneNumberLength ?
        null :
        { isValid: { value: false } };
    };
  }

  openSignatureDialogComponent(): void {
    const dialogRef = this.dialog.open(SignatureDialogComponent);
    const signature: any = {};
    dialogRef.componentInstance.question = signature;
    dialogRef.afterClosed().subscribe(() => {
      this.signature = signature.canvas.toDataURL();
      console.log(this.signature);
    });
  } // end func

  handleChangePasswordButtonClicked(): void {
    this.dialog.open(ChangePasswordDialogComponent);
  }

  resetPassword(): void {
    window.location.href = `${JAVA_BACKEND_ENDPOINT}password-reset`;
  }

  async sendCloseAccountEmail(): Promise<void> {
    const isDoctor = await this.isDoctorObservable.pipe(take(1)).toPromise();
    const userData = await this.userDataObservable.pipe(take(1)).toPromise();
    if (isDoctor) {
      window.open('mailto:info@insighealth.com?subject=Close my account&body=Please close my account. My practitioner account email is: ' + userData.email, '_blank');
    } else {
      window.open('mailto:info@insighealth.com?subject=Close my account&body=Please close my account. My patient account email is: ' + userData.email, '_blank');
    }
  } // end function

  async handleChangeEmailButtonClicked(): Promise<void> {
    const emailUpdated = await this.openChangeEmailDialog();
    if (emailUpdated) {
      window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });

      const newUserData = await lastValueFrom(this.userDataObservable.pipe(take(2)));
      this.userDataForm.controls['email'].setValue(newUserData.email);

      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    }
  }

  async openChangeEmailDialog(): Promise<boolean> {
    const uid = (await firstValueFrom(this.userObservable)).uid;
    const dialogRef = this.dialog.open(ChangeEmailDialogComponent, {
      data: {
        userId: uid,
      },
    });

    return firstValueFrom<boolean>(dialogRef.afterClosed());
  }

  beforeUnloadListener(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';
  }

  handleAddMfaButtonClicked(): void {
    this.dialog.open(MfaEnrollmentDialogComponent, {
      maxWidth: '600px',
    });
  }

} // end class
