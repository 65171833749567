// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { EmailLinkToNoteRequest, NoteManagementService as NoteManagementOpenApiService } from '@insig-health/api/note-management-api';
import { EmailNoteRequest } from '@insig-health/api/note-management-api/model/emailNoteRequest';
import { NoteDocumentUploadType } from '@insig-health/api/note-management-api/model/noteDocumentUploadType';
import { firstValueFrom } from 'rxjs';

interface BaseNoteDocument {
  name: string;
  noteId: string;
  uploadType: NoteDocumentUploadType;
  index: number;
}

export type HtmlNoteDocument = BaseNoteDocument & {
  html: string;
  type: 'html';
}

export type UrlNoteDocument = BaseNoteDocument & {
  url: string;
  type: 'url';
}

export type NoteDocument = HtmlNoteDocument | UrlNoteDocument;

export interface NoteDocumentMetadata {
  uploadType: NoteDocumentUploadType;
  index: number;
}

export { NoteDocumentUploadType };

@Injectable({
  providedIn: 'root',
})
export class NoteManagementService {
  constructor(private noteManagementOpenApiService: NoteManagementOpenApiService) {}

  async getAppointmentNoteDocuments(companyId: string, appointmentId: string, patientUid: string): Promise<NoteDocument[]> {
    const documents = await firstValueFrom(this.noteManagementOpenApiService.getAppointmentNoteDocuments(companyId, appointmentId, patientUid));
    return documents.filter((document) => {
      return (document.html !== undefined && document.html !== null) || (document.url !== undefined && document.url !== null);
    }).map((document) => {
      if (document.html !== undefined && document.html !== null) {
        return { ...document, type: 'html' } as HtmlNoteDocument;
      }
      if (document.url !== undefined && document.url !== null) {
        return { ...document, type: 'url' } as UrlNoteDocument;
      }
    });
  }

  async emailNote(companyId: string, noteId: string, toAddress: string, attachedDocuments: NoteDocumentMetadata[] = [], message?: string): Promise<void> {
    const emailNoteRequest: EmailNoteRequest = {
      toAddress,
      attachedDocuments: attachedDocuments.map(({ uploadType, index }) => ({ documentUploadType: uploadType, documentIndex: index })),
      message,
    };
    await firstValueFrom(this.noteManagementOpenApiService.emailNote(companyId, noteId, emailNoteRequest));
  }

  async emailLinkToNote(companyId: string, noteId: string, toAddress: string, message?: string): Promise<void> {
    const emailLinkToNoteRequest: EmailLinkToNoteRequest = {
      toAddress,
      message,
    };
    await firstValueFrom(this.noteManagementOpenApiService.emailLinkToNote(companyId, noteId, emailLinkToNoteRequest));
  }

  async emailNoteDocument(companyId: string, noteId: string, toAddress: string, document: NoteDocumentMetadata, message?: string) {
    await firstValueFrom(this.noteManagementOpenApiService.emailNoteDocument(
      companyId,
      noteId,
      document.uploadType,
      document.index,
      {
        toAddress,
        message,
      },
    ));
  }

  async getNotePdf(companyId: string, noteId: string): Promise<Blob> {
    return firstValueFrom(this.noteManagementOpenApiService.getNotePdf(companyId, noteId));
  }

  async getNoteDocumentPdf(companyId: string, noteId: string, documentUploadType: NoteDocumentUploadType, documentIndex: number): Promise<Blob> {
    return firstValueFrom(this.noteManagementOpenApiService.getNoteDocumentPdf(companyId, noteId, documentUploadType, documentIndex));
  }
}
