// @ts-strict-ignore
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { SendSMSService } from 'insig-app/services/sendSMS.service';

import { APPURL } from '@insig-health/config/config';

import { SelectDoctorDialogComponent } from '../dialogs/select-doctor-dialog.component';
import { ConfirmLaunchDialogComponent } from '../dialogs/confirm-launch-dialog/confirm-launch-dialog.component';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import screenfull from 'screenfull';

import { Survey } from 'insig-types/surveys/survey';
import { Subscription } from 'rxjs';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Component({
  selector: 'my-tablet-mode',
  templateUrl: './tablet-mode.component.html',
  styleUrls: ['./tablet-mode.component.scss'],
})
export class TabletModeComponent implements OnInit, OnDestroy {
  // graphql queries
  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        branding
        name
      }
    }
  `;

  private doctorListQuery = gql`
    query DoctorListQuery($companyID: ID!) {
      getCompanyUserList(cid: $companyID) {
        first
        last
        company
        uid
      }
    }
  `;

  //

  private doctorSurveys = null;
  public librarySurveys: Survey[] = null;
  public doctorList = null;
  public selectedDoctor = null;
  public loopSurvey = true;
  private companyID = null;
  private sub: any;
  public smsKiosk = false;
  public companyData: any = {};
  public phoneNumber = null;
  public digitsRegex = /[^\d]/g;
  public smsSurveyURL: any = '';
  public tempHide = false;

  private librarySurveysSubscription: Subscription;

  // endpoints

  constructor(
    public loadSurveyService: LoadSurveyService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private gcpIpAuthService: GcpIpAuthService,
    private sendSMSService: SendSMSService,
    public translateService: TranslateService,
    public cookieService: CookieService,
    private dialog: MatDialog,
    private apollo: Apollo,
  ) {} // end constructor

  async ngOnInit() {
    // only set it to false if they set it to false
    if (this.cookieService.get('loopSurvey') === 'false') {
      this.loopSurvey = false;
    } else {
      this.cookieService.set(
        'loopSurvey',
        'true',
        undefined,
        undefined,
        undefined,
        true,
        'None'
      );
      this.loopSurvey = true;
    }
    // sign out to make sure patient's cant see patient data
    await this.gcpIpAuthService.signOut();
    this.cookieService.set(
      'tabletMode',
      'true',
      undefined,
      undefined,
      undefined,
      true,
      'None'
    );
    
    // load the doctor list
    this.sub = this.route.params.subscribe((params) => {
      this.companyID = params['companyID'];
      this.getDoctorList(this.companyID);
    });
    this.loadLibrarySurveys();

    // Check if the cookie exists
    try {
      const selectedDoctorCookie = this.cookieService.get('selectedDoctor');
      if (selectedDoctorCookie && selectedDoctorCookie !== '') {
        // check if a doctor has been selected before, then load them
        try {
          const parsedSelectedDoctorCookie = JSON.parse(selectedDoctorCookie);
          if (parsedSelectedDoctorCookie) {
            this.selectedDoctor = parsedSelectedDoctorCookie;
            this.getSurveysForDoctor(this.selectedDoctor.uid);
          }
        } catch (error) {
          console.error("Error loading previously selected doctor:", error);
        }
      }
    } catch (cookieError) {
      console.error("Error getting the selected doctor cookie")
    }

    this.switchLanguage('English');
    this.loadCompanyData(this.companyID);

    try {
      if (screenfull.isEnabled) {
        await screenfull.request();
      }
    } catch (error) {
      console.error("Error entering fullscreen mode:", error);
    }
  } // end ngOnInit

  async loadCompanyData(cid) {
    const companyDataQuery: any = await this.apollo
      .query({
        query: this.companyDataQuery,
        variables: {
          companyID: cid,
          token: await this.gcpIpAuthService.getCurrentUser()?.getIdToken(),
        },
      })
      .toPromise();
    this.companyData = companyDataQuery.data.getCompanyData;
  }

  switchLanguage(language: any) {
    console.log('Language changed', language);
    this.translateService.changeLanguage(language);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();

    this.librarySurveysSubscription?.unsubscribe();
  }

  // get surveys for any userId
  async getSurveysForDoctor(userId: string): Promise<void> {
    const getAllUserSurveyNamesQuery = gql`
      query GetAllUserSurveyNamesQuery($userId: ID!) {
        getAllUserSurveyNames(uid: $userId) {
          id
          name
        }
      }
    `;
    try {
      const getAllUserSurveyNamesQueryResult = await this.apollo
        .query<{ getAllUserSurveyNames?: Array<{ name: string }> }>({
          query: getAllUserSurveyNamesQuery,
          variables: {
            userId,
          },
        })
        .toPromise();
      this.doctorSurveys =
        getAllUserSurveyNamesQueryResult.data.getAllUserSurveyNames?.slice().sort(
          (a, b) => {
            return a.name.localeCompare(b.name);
          }
        ) || [];
    } catch (error) {
      console.log(error);
      this.doctorSurveys = [];
    }
  } // end get surveys func

  loadLibrarySurveys(): void {
    this.librarySurveysSubscription = this.loadSurveyService
      .watchLibrarySurveysFromFirestore()
      .subscribe((surveys) => {
        this.librarySurveys = this.sortLibraryFunc(surveys);
      });
  }

  sortFunc(array) {
    return array.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  sortLibraryFunc(array: Survey[]): Survey[] {
    // Alphabetical sorting by 'name'
    return array.sort((a, b) => {
      if (b.name.includes('START Insig')) {
        console.log(a.name);
        return 1;
      } else if (a.name.includes('START Insig')) {
        console.log(a.name);
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  async getDoctorList(companyID) {
    try {
      const doctorListQuery: any = await this.apollo
        .query({
          query: this.doctorListQuery,
          variables: {
            companyID,
          },
        })
        .toPromise();
      let userList = doctorListQuery.data.getCompanyUserList?.slice().sort(this.sortByLastName);
      const doctorList = [];
      userList.forEach((user) => {
        user = {
          ...user,
          fullName: `${user.first} ${user.last}`,
        };
        doctorList.push(user);
      });
      this.doctorList = doctorList;
    } catch (error) {
      console.log(error);
    }
  }

  sortByLastName(a, b) {
    return a.last.localeCompare(b.last);
  }

  changeDoctor(doctors) {
    const dialogRef = this.dialog.open(SelectDoctorDialogComponent, {
      data: { doctorList: doctors },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedDoctor = result;
        this.getSurveysForDoctor(this.selectedDoctor.uid);
        this.cookieService.set(
          'selectedDoctor',
          JSON.stringify(this.selectedDoctor),
          undefined,
          undefined,
          undefined,
          false,
          'Lax'
        );
      }
    });
  }

  changeLoopSurvey() {
    let message = '';
    if (this.loopSurvey) {
      this.cookieService.set(
        'loopSurvey',
        'false',
        undefined,
        undefined,
        undefined,
        true,
        'None'
      );
      // this.loopSurvey = false;
      message = 'Survey loop OFF';
    } else {
      this.cookieService.set(
        'loopSurvey',
        'true',
        undefined,
        undefined,
        undefined,
        true,
        'None'
      );
      // this.loopSurvey = true;
      message = 'Questionnaire loop ON, select a questionnaire to loop';
    }
    this.snackBar.open(message, null, { duration: 2000 });
  }

  launchSurvey(type, companyID, userID, survey) {
    const dialogRef = this.dialog.open(ConfirmLaunchDialogComponent);
    dialogRef.componentInstance.surveyName = survey.name;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type === 'user') {
          this.router.navigate([
            '/surveys/launch/tablet-mode',
            'user',
            companyID,
            userID,
            survey.id,
          ]);
        } else if (type === 'library') {
          this.router.navigate([
            '/surveys/launch/tablet-mode',
            'library',
            companyID,
            'library',
            survey.id,
          ]);
        }
      }
    });
  } // end launcsurvey func

  startSMSKiosk(type, companyID, userID, survey) {
    if (type === 'library') {
      this.smsSurveyURL =
        APPURL +
        'surveys/launch/library/' +
        companyID +
        '/' +
        userID +
        '/' +
        survey.id;
    } else {
      this.smsSurveyURL =
        APPURL +
        'surveys/launch/user/' +
        companyID +
        '/' +
        userID +
        '/' +
        survey.id;
    }
    this.smsKiosk = true;
    console.log('Here');
  }

  sendSurveySMS() {
    const phoneNumber = this.phoneNumber.replace(/[^\d]/g, '');
    if (phoneNumber.length !== 10) {
      this.snackBar.open('Please enter a valid phone number!', null, {
        duration: 4000,
      });
      return false;
    } else {
      this.tempHide = true;
      try {
        this.snackBar.open('Sending text message ...', null, {
          duration: 4000,
        });
        const message =
          this.companyData.name +
          ' - Please complete your intake form: ' +
          this.smsSurveyURL;
        this.sendSMSService.sendSMS(phoneNumber, message).then((response) => {
          console.log(response);
          if (response.statusCode === 200) {
            this.snackBar.open('Text message Sent!', null, { duration: 5000 });
            this.phoneNumber = null;
          } else {
            this.snackBar.open('Erorr sending message!', null, {
              duration: 5000,
            });
          }
          this.tempHide = false;
        });
      } catch (err) {
        console.log(err);
        this.tempHide = false;
      }
    }
  }
} // end component
