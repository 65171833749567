<div class="w-100 h-100 call-to-action card-white">
  <div class="cta-inner">
    <div class="row m-0 w-100">
      <div class="col-1"></div>
      <div class="col-10">
        <span class="cta-text bold"
          >If you are using this device for Tablet Mode, remember to NEVER save
          your password, or use autofill, on the login page.</span
        >
      </div>
      <div class="col-1"></div>
    </div>
    <div class="divider"></div>
    <div class="cta-btn">
      <button
        mat-raised-button
        color="primary"
        class="btn-w-lg btn-lg"
        (click)="startTabletMode.next(undefined)"
      >
        Start Kiosk Mode!
      </button>
    </div>
    <div class="divider"></div>
    <div class="divider"></div>
    <div class="cta-text">
      To exit Kiosk Mode, return to
      <a href="{{ APPURL }}">{{ APPURL }}</a> and sign-in again.
    </div>
  </div>
</div>
