import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-place-in-line-nothing-found',
  templateUrl: './place-in-line-nothing-found.component.html',
  styleUrls: ['./place-in-line-nothing-found.component.scss'],
})
export class PlaceInLineNothingFoundComponent {
  @Output() retryPlaceInLine = new EventEmitter();
}
