import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-check-in-phone-confirmation',
  templateUrl: './check-in-phone-confirmation.component.html',
  styleUrls: ['./check-in-phone-confirmation.component.scss'],
})
export class CheckInPhoneConfirmationComponent implements OnInit {
  @Output() resetScreen = new EventEmitter();

  ngOnInit() {
    setTimeout(() => {
      this.resetScreen.emit();
    }, 10000);
  }
}
