<ul class="nav primary-color-text">
  <li
    [ngClass]="{
      'nav-item-selected': selectedTab === 'library',
      'nav-item-not-selected': selectedTab !== 'library'
    }"
    (click)="changeTab('library')"
  >
    <mat-icon class="notranslate">local_library</mat-icon>
    <div [ngClass]="{ 'mobile-nav-text': mobile }">LIBRARY</div>
  </li>

  <li
    [ngClass]="{
      'nav-item-selected': selectedTab == 'user',
      'nav-item-not-selected': selectedTab != 'user'
    }"
    (click)="changeTab('user')"
  >
    <mat-icon class="notranslate">person</mat-icon>
    <div [ngClass]="{ 'mobile-nav-text': mobile }">MY QUESTIONNAIRES</div>
  </li>

  <li
    [ngClass]="{
      'nav-item-selected': selectedTab == 'organization',
      'nav-item-not-selected': selectedTab != 'organization'
    }"
    (click)="changeTab('organization')"
  >
    <mat-icon class="notranslate">people</mat-icon>
    <div [ngClass]="{ 'mobile-nav-text': mobile }">GROUP</div>
  </li>

  <li
    [ngClass]="{
      'nav-item-selected': selectedTab == 'tablet',
      'nav-item-not-selected': selectedTab != 'tablet'
    }"
    (click)="changeTab('tablet')"
  >
    <mat-icon class="notranslate">tablet</mat-icon>
    <div [ngClass]="{ 'mobile-nav-text': mobile }">KIOSK MODE</div>
  </li>

  <li
    *ngIf="!!companyData?.checkIn"
    [ngClass]="{
      'nav-item-selected': selectedTab == 'checkin',
      'nav-item-not-selected': selectedTab != 'checkin'
    }"
    (click)="changeTab('checkin')"
  >
    <mat-icon class="notranslate">check_circle</mat-icon>
    <div [ngClass]="{ 'mobile-nav-text': mobile }">CHECK-IN MODE</div>
  </li>
</ul>
