import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-launch-dialog',
  templateUrl: './confirm-launch-dialog.component.html',
  styles: [
    `
      .dialogSize {
        min-width: 60vw;
      }
    `,
  ],
})
export class ConfirmLaunchDialogComponent {
  public surveyName = null;
  public preview = false;

  constructor(public dialogRef: MatDialogRef<ConfirmLaunchDialogComponent>) {}
}
