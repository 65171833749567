<div class="row no-gutters m-0 p-0 kiosk-bg" id="topOfCheckInPage">
  <div *ngIf="!!loading" class="text-center m-auto">
    <mat-progress-spinner
      class="my-auto mx-auto"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <div class="mx-auto my-auto" *ngIf="!loading">

    <div
      *ngIf="!loggedIn && !signingUp && !validateInsigInformation && !forgotPassword"
    >
      <insig-health-login
        [loggingInForVirtualVisit]="virtualCareLogin"
        [patientId]="patientID"
        (loginOutput)="redirect($event)"
      >
      </insig-health-login>
    </div>
    <div
      *ngIf="!!signingUp && !loading"
      class="patient-sign-up-width mx-auto"
      style="margin-top:60px;margin-bottom:60px;"
    >
      <patient-sign-up
        (backToLogin)="backToLogin()"
        [hidePharmacy]="true"
        (onSignUp)="onSignUp($event)"
        [specialLogo]="companyData.branding"
        [specialTitle]="companyData.brandingName || companyData.name"
        [hideFields]="hideFieldsSignUp"
      ></patient-sign-up>
    </div>

    <div
      *ngIf="!!validateInsigInformation && !loading"
      class="patient-sign-up-width mx-auto"
      style="margin-top:60px;margin-bottom:60px;"
    >
      <patient-validate-info
        [(userData)]="userData"
        (updatePatientInfo)="informationValidated()"
        [hideFields]="hideFields"
        [textReplacements]="textReplacements"
      ></patient-validate-info>
    </div>

    <div
      class="forgot-password-container full-height full-width"
      *ngIf="!!forgotPassword && !loading"
    >
      <div
        class="col-12 align-self-center justify-content-center kiosk-title"
        *ngIf="!!forgotPasswordSubmitted"
      >
        <div class="py-5 p-sm-5 text-center">
          <h4 class="px-4 mx-auto roboto-title">
            A password reset was sent to your email. Please check your email and
            reset your password.
          </h4>

          <h4 class="px-4 mx-auto roboto-title">
            You will be redirected to the login screen in a few seconds. Once
            you reset your password, please login here!
          </h4>
        </div>
      </div>
      <div
        class="container p-5 forgot-password-box my-auto"
        *ngIf="!forgotPasswordSubmitted"
      >
        <h1 class="h2 text-center">Password Reset</h1>

        <div class="form-wrapper clearfix-md">
          <div name="material_login_form" class="md-form-auth form-validation">
            <p class="text-center text-small no-margin">
              Enter the email address that you used to register. We will send
              you an email with a link to reset your password.
            </p>
            <div class="form-group form-field mt-4">
              <mat-form-field
                class="full-width mat-icon-left no-margin-bottom"
                appearance="outline"
              >
                <mat-icon matPrefix class="notranslate">mail_outline</mat-icon>
                <mat-label>Email</mat-label>
                <input
                  required
                  matInput
                  type="email"
                  name="email"
                  [(ngModel)]="forgotPasswordEmail"
                />
              </mat-form-field>
            </div>

            <div class="row mt-5">
              <div
                class="empty-btn col-5 d-flex flex-column p-0 mx-auto"
                (click)="goBackForgotPassword()"
              >
                <!-- *ngIf="appointmentConfig.medium != 'inPerson' && !userData.pharmaFax" -->
                <div class="mt-auto mb-auto">Back</div>
              </div>

              <div
                class="filled-btn col-5 float-right d-flex flex-column p-0 mx-auto"
                (click)="requestPasswordReset()"
              >
                <div class="mt-auto mb-auto">Reset</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-fade"></div>
</div>
