<div class="row no-gutters m-0 p-0 kiosk-bg full-height">
  <div class="col-12 align-self-center justify-content-center kiosk-title">
    <div class="py-5 p-sm-5 text-center">
      <h4 class="px-4 mx-auto roboto-title">
        Sorry there was an issue finding your appointment!
      </h4>

      <h4 class="px-4 mx-auto roboto-title">
        Please talk to the clinic staff at the front desk! This screen will
        reset shortly!
      </h4>
    </div>
  </div>
</div>
