// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewRef,
} from '@angular/core';
import { NotesService } from 'insig-app/services/notes.service';

import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { InitNoteService } from 'insig-app/services/initNote.service';
import { SchedulerService } from 'insig-app/services/scheduler.service';

import firebase from 'firebase/compat/app';
import 'firebase/database';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import asyncPool from 'tiny-async-pool';

import { Apollo, gql } from 'apollo-angular';
import { SpringNote } from 'insig-types/spring-api/notes';
import { Question } from 'insig-types/surveys';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'cpp',
  templateUrl: './cpp.component.html',
  providers: [
    NotesService,
    PatientUserDataService,
    InitNoteService,
    SchedulerService,
  ],
})
export class CPPComponent implements OnInit, OnDestroy {
  public cppElements = [];
  public cppData = null;

  public notes: any[] = [];
  public loadingNotes = true;
  public numberOfNotesAvailable: number;

  private userID: string;
  private authSubscription: Subscription;
  public editing = {};

  private changeDetector: ViewRef;

  constructor(
    private notesService: NotesService,
    private firebaseAuthService: FirebaseAuthService,
    private initNoteService: InitNoteService,
    private patientUserDataService: PatientUserDataService,
    private schedulerService: SchedulerService,
    private _changeDetector: ChangeDetectorRef,
    private apollo: Apollo
  ) {
    this.changeDetector = this._changeDetector as ViewRef;
  }

  ngOnInit() {
    this.cppElements = this.initNoteService.getCPPElements();
    this.authSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userID = user.uid;

          // #region load patient notes
          const loadNotes = async () => {
            this.notes = [];

            // Patient notes should be attached to an appointment, so we can query all the patients appointments to retrieve note references
            const appointments = await firebase
              .firestore()
              .collection('appointments')
              .where('patientID', '==', user.uid)
              .get()
              .then((snapshot) => snapshot.docs.map((doc) => doc.data()));

            const appointmentIdentifiers = appointments.map((appointment) => ({
              companyId: appointment.companyID,
              appointmentId: appointment.linkID.split('-')[1],
            }));

            const noteIdentifiersPromises = appointmentIdentifiers.map((ids) =>
              firebase
                .firestore()
                .collection('companies')
                .doc(ids.companyId)
                .collection('notes')
                .where('locked', '==', false)
                .where('patientUid', '==', user.uid)
                .where('apptID', '==', ids.appointmentId)
                .get()
                .then((snapshot) =>
                  snapshot.empty
                    ? []
                    : snapshot.docs.map((doc) => ({
                        appointmentId: ids.appointmentId,
                        companyId: ids.companyId,
                        noteId: doc.id,
                      }))
                )
            );

            const identifiers = await Promise.all(
              noteIdentifiersPromises
            ).then((arrays) => arrays.flat());
            this.numberOfNotesAvailable = identifiers.length;

            // Parallelize promise resolution;
            await asyncPool(5, identifiers, (ids) =>
              this.notesService.getNoteFromSpring( // Load note from Spring
                ids.companyId,
                ids.noteId,
              )
                .then((springNote) => springNote as SpringNote & { // Type conversion
                  doctorData: any;
                  companyID: string;
                  companyData: {
                    branding: string;
                    name: string;
                  };
                })
                .then((note) => {
                  if (!note.doctorData) {
                    return this.schedulerService
                      .getAppointmentByKeyPatient(
                        `${ids.companyId}-${ids.appointmentId}`,
                        user.uid
                      )
                      .pipe(take(1))
                      .toPromise()
                      .then((snapshot) => snapshot[0].doctorID)
                      .then((doctorId) => {
                        const doctorDataQuery = gql`
                          query DoctorDataQuery($userID: ID!) {
                            getUserData(uid: $userID) {
                              uid
                              title
                              first
                              last
                            }
                          }
                        `;

                        return this.apollo
                          .query({
                            query: doctorDataQuery,
                            variables: {
                              userID: doctorId,
                            },
                          })
                          .toPromise();
                      })
                      .then(
                        (graphqlResponse: any) =>
                          graphqlResponse.data.getUserData
                      )
                      .then((doctorData) => {
                        note.doctorData = doctorData;
                        return note;
                      });
                  } else {
                    return note;
                  }
                })
                .then((note) => {
                  // console.log(note);
                  note.companyID = ids.companyId;

                  if (note.companyID === 'tiaHealth' && note.companyData
                      && (window.location.origin.includes('app.well.company')
                          || window.location.origin.includes('app.wellclinics.ca'))
                    ) {
                    note.companyData.branding = 'assets/images/global/well/well-logo-normal.svg';
                    note.companyData.name = 'WELL Health';
                  } else if (note.companyID === 'tiaHealth' && note.companyData
                      && window.location.origin.includes('app.jacknathanhealth.com')
                    ) {
                    note.companyData.branding = 'assets/images/global/jnh/jnh-logo.png';
                    note.companyData.name = 'Jack Nathan Health';
                  } else if (note.companyID === 'tiaHealth' && note.companyData
                      && window.location.origin.includes('app.thevirtualdoctor.org')
                    ) {
                    note.companyData.branding = 'assets/images/global/tvd/tvd.jpeg';
                    note.companyData.name = 'The Virtual Doctor';
                  } else if (note.companyID === 'tiaHealth' && note.companyData
                      && window.location.origin.includes('virtual.highmark.tech')
                    ) {
                    note.companyData.branding = 'assets/images/global/eq/eq-logo.png';
                    note.companyData.name = 'EQ Virtual';
                  } //end if

                  this.notes.push(note);
                })
                .catch((error) => {
                  if (!!error.code && error.code === 'permission-denied') {
                    console.error(error);
                    // Ignore the error
                  } else {
                    console.error(error);
                    console.error(`failed on ${ids.companyId}-${ids.noteId}`);
                  }
                })
                .then(() => {
                  if (!this.changeDetector.destroyed) {
                    this.changeDetector.detectChanges();
                  } else {
                    throw new Error(
                      'Short circuit due to destroyed changeDetector'
                    );
                  }
                })
            ).catch((error) => {
              console.log(error);
            });
            this.loadingNotes = false;
            if (!this.changeDetector.destroyed) {
              this.changeDetector.detectChanges();
            }
          };
          loadNotes().catch((error) => console.error(error));
          // #endregion load patient notes

          this.loadCPP(user.uid);
        }
      });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  async loadCPP(uid: string): Promise<void> {
    const snapshot = await this.patientUserDataService
      .getPatientData(uid)
      .pipe(take(1))
      .toPromise();
    console.log(snapshot);
    if (snapshot && snapshot.uid) {
      const patientInfo = snapshot;
      if (patientInfo.cpp) {
        this.cppData = this.initNoteService.formatCPPData(patientInfo.cpp);
        this.changeDetector.detectChanges();
      } else {
        this.cppData = {};
      }
    }
    console.log(this.cppData);
  }

  save() {
    const saveCPPData = {};
    console.log(this.cppData)
    for (const item of Object.keys(this.cppData || {})) {
      // catch undefined
      if (
        !!this.cppData[item].response &&
        !!this.cppData[item].response.selectedAnswers &&
        !this.cppData[item].response.selectedAnswers[-1]
      ) {
        this.cppData[item].response.selectedAnswers[-1] = null;
      }
      if (this.cppData[item].response) {
        saveCPPData[item] = this.cppData[item].response;
      }
    } // end for loop
    console.log(saveCPPData);
    this.patientUserDataService.saveCPPData(this.userID, saveCPPData);
  }

  toggleEditing(item) {
    if (!this.editing[item.type]) {
      console.log(item.type);
      if (!this.cppData[item.type] || !this.cppData[item.type].type) {
        this.cppData[item.type] = { type: item.type };
      }
      if (item.type === 'medications') {
        this.cppData[item.type].allowMultiple = true;
      }
      this.editing[item.type] = true;
    } else {
      // TO DO rebuild item text after edited
      this.cppData[item.type].result = this.initNoteService.buildCPPResult(
        this.cppData[item.type]
      );
      if (!this.cppData[item.type].result) {
        this.cppData[item.type].result = '';
      }
      console.log(this.cppData[item.type]);
      this.save();
      this.editing[item.type] = false;
    }
  }

  sortByTime(array: Array<{ time: string }>): any[] {
    return array.sort((a, b) => {
      if (!a || !b) {
        return 1;
      }
      return b.time.localeCompare(a.time);
    });
  }

  openNotePatient(note: any): Window {
    return window.open(
      `/notes/patient-note/${note.noteId || note.caseID || note.noteID || note.nid}/${
        note.companyID
      }-${note.apptId || note.apptID}`
    );
  }

  handleCppResponseChange(question: Question): void {
    this.cppData = {
      ...this.cppData,
      [question.type]: question,
    };
  }
}
