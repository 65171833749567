// @ts-strict-ignore
import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import firebase from 'firebase/compat/app';

import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';


import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';

import { Subscription } from 'rxjs';

import { take } from 'rxjs/operators';

@Component({
  selector: 'insig-check-in-place-in-line',
  templateUrl: './check-in-place-in-line.component.html',
  styleUrls: ['./check-in-place-in-line.component.scss'],
  providers: [PatientUserDataService],
})
export class CheckInPlaceInLineComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() locationID: string;
  @Input() locationData: any;
  @Input() companyID: string;
  @Input() companyData: any;
  @Input() queryCheckInID: string;
  @Input() emrDoctors = [];
  @Input() parsedAppointments = {
    walkIn: [],
    appointmentBased: [],
  };
  @Input() doctorAppointments = {};

  private userID: string;
  private userSubscription: Subscription;
  private attemptedInput = false;
  private waitingForQueryValidation = false;

  public checkInID: string;
  public nextAppointment: string;
  public currentScreen = 'selectOption';
  public loading = false;
  public checkInIDInput = '';
  public currentAppointment: any;

  constructor(
    private firebaseAuthService: FirebaseAuthService,
    private gcpIpAuthService: GcpIpAuthService,
    private patientUserDataService: PatientUserDataService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  checkQuery() {
    if (!!this.queryCheckInID && this.parsedAppointments) {
      console.log('querycheckinid: ', this.queryCheckInID);
      const appointmentFound = this.findAppointment();
      if (appointmentFound) {
        this.currentScreen = 'showAppt';
      } else {
        this.currentScreen = 'selectOption';
      }
    }
  }

  ngOnChanges(changes) {
    if (!!changes.queryCheckInID || !!changes.parsedAppointments) {
      this.checkQuery();
    }
  }

  getUser() {
    this.userSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userID = user.uid;

          if (!(await this.checkIfPatientAccount(user))) {
            // this.loading = false;
            this.gcpIpAuthService.signOut();
            // this.snackbar.open('Error loading user data!', null, { duration:  4000});
          } else {
            this.attemptedInput = true;

            this.findAppointment();
          }
        } else {
          this.gcpIpAuthService.signOut();
        }
      });
  }

  resetVariables() {
    this.currentScreen = 'selectOption';
    this.checkInID = '';
    this.checkInIDInput = '';
  }

  findAppointment(): boolean {
    if (!!this.queryCheckInID || !!this.checkInIDInput || !!this.userID) {
      const appointmentBased = this.parsedAppointments.appointmentBased.filter(
        (appt) => {
          if (this.checkInIDInput) {
            return (
              appt.checkInID.toLowerCase() === this.checkInIDInput.toLowerCase()
            );
          } else {
            return appt.checkInID === this.queryCheckInID;
          }
        }
      );

      const walkIn = this.parsedAppointments.walkIn.filter((appt) => {
        if (this.checkInIDInput) {
          return (
            appt.checkInID.toLowerCase() === this.checkInIDInput.toLowerCase()
          );
        } else {
          return appt.checkInID === this.queryCheckInID;
        }
      });

      let appointmentBasedUID;
      let walkInUID;
      if (this.userID) {
        appointmentBasedUID = this.parsedAppointments.appointmentBased.filter(
          (appt) => {
            return appt.userID === this.userID;
          }
        );

        walkInUID = this.parsedAppointments.walkIn.filter((appt) => {
          return appt.userID === this.userID;
        });
      }

      console.log(
        'found appointments by check in id: ',
        appointmentBased,
        walkIn
      );
      console.log(
        'found appointments by userID: ',
        appointmentBasedUID,
        walkInUID
      );

      if (!!appointmentBased && appointmentBased.length > 0) {
        const appt = (this.currentAppointment = appointmentBased[0]);
        this.checkInID = appt.checkInID;
        this.currentScreen = 'showAppt';
        return true;
      } else if (!!walkIn && walkIn.length > 0) {
        const appt = (this.currentAppointment = walkIn[0]);
        this.checkInID = appt.checkInID;
        this.currentScreen = 'showAppt';
        return true;
      } else if (!!appointmentBasedUID && appointmentBasedUID.length > 0) {
        const appt = (this.currentAppointment = appointmentBasedUID[0]);
        this.checkInID = appt.checkInID;
        this.currentScreen = 'showAppt';
        return true;
      } else if (!!walkInUID && walkInUID.length > 0) {
        const appt = (this.currentAppointment = walkInUID[0]);
        this.checkInID = appt.checkInID;
        this.currentScreen = 'showAppt';
        return true;
      } else {
        if (this.attemptedInput) {
          this.currentScreen = 'nothingFound';
        }

        return false;
      }
    }
  }

  async checkIfPatientAccount(user: firebase.User): Promise<boolean> {
    console.log('Checking if patient');
    console.log(user);
    try {
      const userData = await this.patientUserDataService
        .getPatientData(user.uid)
        .pipe(take(1))
        .toPromise();
      if (!!userData && !!userData.uid) {
        console.log('Using a patient account!');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  placeInLineOptionSelected(event) {
    if (!event || event !== 'login') {
      this.currentScreen = 'checkInInput';
    } else {
      this.currentScreen = 'login';
    }
  }

  patientLoggedIn(userID) {
    console.log('patient logged in, userid: ', userID);
    this.attemptedInput = true;
  }

  checkInIDSubmitted(event) {
    console.log('set check in id input to: ', event);
    this.checkInIDInput = event;
    this.attemptedInput = true;
    this.findAppointment();
  }
}
