import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'insig-check-in-patient-select',
  templateUrl: './check-in-patient-select.component.html',
  styleUrls: ['./check-in-patient-select.component.scss'],
  providers: [],
})
export class CheckInPatientSelectComponent {
  @Output() patientSelected = new EventEmitter();
}
