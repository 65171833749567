// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { SendGridTemplateId } from '@insig-health/constants/send-grid-template-ids';

import { Apollo, gql } from 'apollo-angular';

import { SendEmailService } from '../../services/sendEmail.service';
import { SendSMSService } from '../../services/sendSMS.service';

import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'send-survey-dialog',
  templateUrl: './send-survey-dialog.component.html',
  providers: [SendSMSService, SendEmailService],
})
export class SendSurveyDialogComponent implements OnInit {
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
        last
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyDataQuery($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        name
        email
        brandingName
      }
    }
  `;
  //

  public emailInput = null;
  public smsToInput = null;
  public insigURL = null;
  public companyID = null;
  public userID = null;
  public surveyID = null;
  public companyName = null;
  public surveyLink = null;
  private userData = null;
  public emailMessage: any;
  public smsMessage: any;
  private sendQuestionnaireTemplateID = 'f2640920-60d8-4f72-96cd-f972a15f4057';
  // forms
  emailForm: UntypedFormGroup;
  smsForm: UntypedFormGroup;

  // endpoints

  constructor(
    public dialogRef: MatDialogRef<SendSurveyDialogComponent>,
    private snackBar: MatSnackBar,
    private sendSMSService: SendSMSService,
    private sendEmailService: SendEmailService,
    private formBuilder: UntypedFormBuilder,
    private apollo: Apollo
  ) {}

  async ngOnInit() {
    this.buildForm();
    try {
      const userDataQuery: any = await this.apollo
        .query({
          query: this.userDataQuery,
          variables: {
            userID: this.userID,
          },
        })
        .toPromise();
      this.userData = userDataQuery.data.getUserData;
      this.companyID = this.userData.company;

      const companyDataQuery: any = await this.apollo
        .query({
          query: this.companyDataQuery,
          variables: {
            companyID: this.companyID,
            token: undefined,
          },
        })
        .toPromise();
      const companyData = companyDataQuery.data.getCompanyData;
      this.companyName = companyData.name;
      if (!this.surveyLink) {
        this.surveyLink =
          this.insigURL +
          '/' +
          this.companyID +
          '/' +
          this.userID +
          '/' +
          this.surveyID;
      }

      console.log(this.surveyLink);
      console.log(companyData);
      console.log(this.userData.last.replace(/\s/g, ''));

      let baseURL  = 'insighealth.com';
      if(window.location.origin.includes('app.well.company')
      || window.location.origin.includes('app.wellclinics.ca')){
        baseURL = 'wellclinics.ca';
      } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
        baseURL = 'jacknathanhealth.com';
      } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
        baseURL = 'thevirtualdoctor.org';
      } else if (window.location.origin.includes('virtual.highmark.tech')) {
        baseURL = 'highmark.tech';
      }

      this.emailForm.controls['emailFrom'].setValue(
        `virtual-care-no-reply@${baseURL}`
      );
      this.emailForm.controls['emailSubject'].setValue(
        this.companyName + ' Health Questionnaire'
      );
      this.emailForm.controls['emailMessage'].setValue(
        'This email was sent by your healthcare provider, ' +
          this.companyName +
          '. \n\nPlease click the button below and fill out your information. Your information is secure and will only be shared with your healthcare provider.'
      );

      this.smsForm.controls['smsMessage'].setValue(
          this.companyName +
          ' - Please complete your intake form: '
          + this.surveyLink
      );
    } catch (error) {
      switch (error.code) {
        default:
          console.log(error);
          break;
        case 'PERMISSION_DENIED':
          console.log('Wrong uid. User may have logged out.');
          break;
      }
    }
  }

  buildForm() {
    this.emailForm = this.formBuilder.group({
      emailTo: this.formBuilder.control(this.emailInput, [Validators.required]),
      emailFrom: this.formBuilder.control({ value: '', disabled: true }, [
        Validators.required,
      ]),
      emailSubject: this.formBuilder.control(null),
      emailMessage: this.formBuilder.control(null, [Validators.required]),
    });
    this.smsForm = this.formBuilder.group({
      smsTo: this.formBuilder.control(this.smsToInput, [Validators.required]),
      smsMessage: this.formBuilder.control(null, [Validators.required]),
    });
  }

  checkEmailInput() {
    let message = '';
    if (!this.emailForm.controls['emailTo'].valid) {
      message = 'Please enter a valid \'To\' email!';
    } else if (!this.emailForm.controls['emailMessage'].valid) {
      message = 'Please enter a message!';
    } else {
      // form is valid so retun true
      return true;
    }
    this.snackBar.open(message, null, { duration: 2000 });
    return false;
  } // end checkEmailInput

  sendSurveyEmail() {
    if (!this.checkEmailInput()) {
      return false;
    }
    const emailTo = this.emailForm.controls['emailTo'].value;
    const emailFrom = this.emailForm.controls['emailFrom'].value;
    const subject = this.emailForm.controls['emailSubject'].value;
    let message = this.emailForm.controls['emailMessage'].value;
    message = message.replace(/(?:\r\n|\r|\n)/g, '<br />');
    const substitutions = {
      message,
      companyName: this.companyName,
      surveyLink: this.surveyLink,
    };
    this.snackBar.open('Sending message...', null, { duration: 2000 });
    this.sendEmailService
      .sendTemplateEmail(
        emailTo,
        (this.companyID === 'tiaHealth') ? {email: "noreply@tiahealth.com", name: "Tia Health"} : emailFrom,
        subject,
        message,
        (this.companyID === 'tiaHealth') ? SendGridTemplateId.TIA_SEND_QUESTIONNAIRE : this.sendQuestionnaireTemplateID,
        substitutions
      )
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          this.snackBar.open('Message Sent!', null, { duration: 2000 });
        } else {
          this.snackBar.open('Erorr sending message!', null, {
            duration: 2000,
          });
        }
      });
    this.dialogRef.close();
  } // end send email

  checkSMSInput() {
    let message = '';
    if (!this.smsForm.controls['smsTo'].valid) {
      message = 'Please enter a valid phone number!';
    } else if (!this.smsForm.controls['smsMessage'].valid) {
      message = 'Please enter a message!';
    } else {
      // form is valid so retun true
      return true;
    }
    this.snackBar.open(message, null, { duration: 2000 });
    return false;
  } // end checkSMSInput

  sendSurveySMS() {
    if (!this.checkSMSInput()) {
      return false;
    }
    let phoneNumber = this.smsForm.controls['smsTo'].value;
    phoneNumber = phoneNumber.replace(/\D/g, '');
    const message = this.smsForm.controls['smsMessage'].value;
    this.sendSMSService.sendSMS(phoneNumber, message).then((response) => {
      console.log(response);
      if (response.statusCode === 200) {
        this.snackBar.open('Message Sent!', null, { duration: 2000 });
      } else {
        this.snackBar.open('Erorr sending message!', null, { duration: 2000 });
      }
    });
    this.dialogRef.close();
  } // end send sms

  copyLink() {
    this.snackBar.open('Questionnaire link copied to clipboard!', null, {
      duration: 2000,
    });
    this.dialogRef.close();
  }
} // end component
