// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { AppointmentPatientNonMedicalResponse, PatientManagementService } from '@insig-health/api/patient-management-api';
import { AppointmentManagementService } from '@insig-health/api/appointment-management-api';
import { PatientProfile } from 'insig-types/user-data';
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

const MALE_REGEX = new RegExp(/^(m|male)$/);

@Injectable()
export class AppointmentService {

  constructor(
    private patientManagementService: PatientManagementService,
    private appointmentManagementService: AppointmentManagementService,
  ) {}

  async getCompanyPatientProfileFromFirestoreByNote(companyId: string, appointmentLinkId: string): Promise<PatientProfile> {
    const patientSid = await this.getPatientSidForAppointmentByNote(appointmentLinkId);
    if (patientSid === undefined) {
      throw new Error('No note submitted for appointment');
    }

    const patientProfileQuery = firebase.firestore().collection('companies').doc(companyId).collection('admittedPatients').where('pid', '==', patientSid);
    const patientProfiles = (await patientProfileQuery.get()).docs.map((document) => document.data() as PatientProfile);
    const patientProfile = patientProfiles[0];
    if (patientProfile === undefined) {
      throw new Error('Patient profile not found');
    } else {
      return patientProfile;
    }
  }

  async getPatientSidForAppointmentByNote(appointmentLinkId: string): Promise<string | undefined> {
    // To get the patientSid for an appointment through client-side firestore sdk, a note needs to be submitted beforehand
    const [companyId, appointmentId] = appointmentLinkId.split('-');
    const noteQuery = firebase.firestore().collection('companies').doc(companyId).collection('notes').where('apptID', '==', appointmentId);
    const notes = (await noteQuery.get()).docs.map((document) => document.data()).filter((note) => note.patientSid !== null && note.patientSid !== undefined);
    return notes[0]?.patientSid;
  }

  async getCompanyPatientProfilesFromFirestoreByUid(companyId: string, patientUid: string): Promise<PatientProfile[]> {
    const patientProfileQuery = firebase.firestore().collection('companies').doc(companyId).collection('admittedPatients').where('uid', '==', patientUid);
    return (await patientProfileQuery.get()).docs.map((document) => document.data() as PatientProfile);
  }

  async getAppointmentPatientProfile(companyId: string, appointmentId: string): Promise<PatientProfile> {
    const patientNonMedicalData = await this.patientManagementService.getPatientNonMedicalByAppointmentId(companyId, appointmentId).toPromise();

    return this.convertPatientNonMedicalDataToPatientProfile(patientNonMedicalData);
  }

  async setAppointmentStatusCompleted(companyId: string, appointmentLinkId: string): Promise<void> {
    await firstValueFrom(this.appointmentManagementService.updateAppointment(companyId, appointmentLinkId, {
      status: 'Completed'
    }));
  }

  private convertPatientNonMedicalDataToPatientProfile(patientData: AppointmentPatientNonMedicalResponse): PatientProfile {
    const [year, month, day] = patientData.patientPersonalDetailResponse.birthday.split('-').map((intString) => parseInt(intString, 10));


    const patientDateOfBirth = `${year}/${month}/${day}`;
    const genderValue = (patientData.patientPersonalDetailResponse.gender.toLocaleLowerCase().match(MALE_REGEX)) ? 'Male' : 'Female';

    return {
      first: patientData.patientPersonalDetailResponse.firstName,
      last: patientData.patientPersonalDetailResponse.lastName,
      gender: genderValue,
      age: patientData.patientPersonalDetailResponse.age,
      dob: patientDateOfBirth,
      birthday: patientDateOfBirth,
      year,
      month,
      day,
      healthCardNumber: patientData.patientMedicalDetailResponse.healthCardNumber,
      phone: patientData.patientContactDetailResponse.phoneNumber,
      extension: patientData.patientContactDetailResponse.extension,
      email: patientData.patientContactDetailResponse.email,
      address: patientData.patientContactDetailResponse.patientAddressDetailResponse.street,
      city: patientData.patientContactDetailResponse.patientAddressDetailResponse.city,
      province: patientData.patientContactDetailResponse.patientAddressDetailResponse.province,
      country: patientData.patientContactDetailResponse.patientAddressDetailResponse.country,
      pharmaName: patientData.patientMedicalDetailResponse.patientPharmacyDetailResponse.pharmacyName,
      pharmaFax: patientData.patientMedicalDetailResponse.patientPharmacyDetailResponse.pharmacyFax,
      tiaRosterStatus: patientData.patientRosterStatusResponse.rosterStatus,
      __typename: '',
      _links: {
        self: {
          href: '',
        },
      },
    };
  }
}
