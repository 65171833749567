// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'insig-check-in-enter-phone',
  templateUrl: './check-in-enter-phone.component.html',
  styleUrls: ['./check-in-enter-phone.component.scss'],
})
export class CheckInEnterPhoneComponent implements OnInit {
  public signUpForm: UntypedFormGroup;
  private _patientPhone: any;
  @Output() patientPhoneChange = new EventEmitter();
  @Input() get patientPhone() {
    return this._patientPhone;
  }
  set patientPhone(val) {
    this._patientPhone = val;
    this.patientPhoneChange.emit(this._patientPhone);
  }

  private _phoneValid: any;
  @Output() phoneValidChange = new EventEmitter();
  @Input() get phoneValid() {
    return this._phoneValid;
  }
  set phoneValid(val) {
    this._phoneValid = val;
    this.phoneValidChange.emit(this._phoneValid);
  }

  @Output() dotsForwardClicked = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.signUpForm = this.buildSignUpForm();
  }

  buildSignUpForm() {
    return this.formBuilder.group(
      {
        phone: this.formBuilder.control(null, [
          Validators.minLength(10),
          Validators.maxLength(14),
        ]),
      },
      { validators: [] }
    );
  }

  phoneValueChange(event) {
    console.log(event, this.signUpForm.valid);
    this.patientPhone = event;
    this.phoneValid = this.signUpForm.valid;
  }

  enterClicked() {
    if (this.phoneValid) {
      this.dotsForwardClicked.emit();
    }
  }
}
