<section class="w-100 d-flex flex-column justify-content-between h-100vh">
  <div class="d-flex">
    <div class="booking-header row m-0 d-flex flex-row">
      <div class="header-container flex col-sm-8 col-6">
        <div class="image-container mr-3">
          <img
            src="{{
              companyData.branding || 'assets/images/global/logofullnormal.svg'
            }}"
            class="logo-image my-auto"
          />
        </div>

        <insig-stepper
          [numberSteps]="numberSteps"
          class="insig-stepper"
          [currentStep]="selectedStepperIndex"
          *ngIf="checkInType === 'kiosk'"
        >
        </insig-stepper>
      </div>

      <div class="flex d-flex text-right justify-content-end col-sm-4 col-6">
        <div class="pt-2" *ngIf="checkInType === 'kiosk'">
          <button mat-button [insMenuTriggerFor]="flagMenu" class="notranslate">
            <!-- <img class="flag" [src]="flag" /> -->
            {{ !!selectedLanguage ? selectedLanguage : 'English' }}

            <i class="material-icons notranslate">
              arrow_drop_down
            </i>
          </button>
          <ins-menu
            #flagMenu="insMenu"
            x-position="after"
            y-position="below"
            overlapTrigger="false"
          >
            <button
              mat-menu-item
              *ngFor="let language of languageList"
              (click)="switchLanguage(language)"
            >
              <!-- <img class="flag" src="../../../assets/images/language-flags/{{language}}.png" /> -->
              <span>&nbsp;{{ language }}</span>
            </button>
          </ins-menu>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex flex-grow-1 background-white">
    <div *ngIf="checkInType === 'kiosk'" class="full-width">
      <insig-check-in-select-option
        *ngIf="currentScreen === 'selectOption'"
        (checkInOption)="checkInOption($event)"
        class="full-height"
      ></insig-check-in-select-option>

      <insig-check-in-enter-phone
        *ngIf="currentScreen === 'enterPhone'"
        class="full-height"
        [(patientPhone)]="patientPhone"
        [(phoneValid)]="phoneValid"
        (dotsForwardClicked)="dotsForwardClicked()"
      ></insig-check-in-enter-phone>

      <insig-check-in-phone-confirmation
        *ngIf="currentScreen === 'phoneConfirmation'"
        class="full-height"
        (resetScreen)="resetScreen()"
      ></insig-check-in-phone-confirmation>

      <insig-check-in-family-member-select
        *ngIf="currentScreen === 'familyMemberSelect'"
        class="full-height"
        (familyMemberSelected)="familyMemberSelected($event)"
        [skipLogin]="skipLogin"
        [familyMemberNeeded]="familyMemberNeeded"
        (familyMemberInfoEntered)="familyMemberInfoEntered($event)"
      ></insig-check-in-family-member-select>

      <insig-check-in-patient-select
        *ngIf="currentScreen === 'patientSelect'"
        class="full-height"
        (patientSelected)="patientSelected($event)"
      ></insig-check-in-patient-select>

      <insig-check-in-login-sign-up
        *ngIf="currentScreen === 'patientLogin'"
        class="full-height"
        (patientLoggedIn)="patientLoggedIn($event)"
        [companyData]="companyData"
        (goToForgotPassword)="goToForgotPassword()"
        [skipLogin]="skipLogin"
        [familyMemberNeeded]="familyMemberNeeded"
        (patientInfoEntered)="patientInfoEntered($event)"
        [goToValidation]="goToValidation"
        [goToResetPassword]="goToResetPassword"
        [patientDevice]="patientDevice"
        (signedUpForFamilyMember)="signedUpForFamilyMember($event)"
      ></insig-check-in-login-sign-up>

      <insig-check-in-number-confirmation
        *ngIf="currentScreen === 'numberConfirmation'"
        class="full-height"
        [generatedID]="generatedID"
        [patientDevice]="patientDevice"
        (resetScreen)="resetScreen()"
        (launchSurvey)="launchSurvey()"
        [skipLogin]="skipLogin"
      ></insig-check-in-number-confirmation>

      <insig-check-in-error
        *ngIf="currentScreen === 'checkInError'"
        class="full-height"
        (resetScreen)="resetScreen()"
        [patientDevice]="patientDevice"
      ></insig-check-in-error>

      <insig-check-in-reset-password
        *ngIf="currentScreen === 'forgotPassword'"
        class="full-height"
        (checkInResetPasswordOption)="checkInResetPasswordOption($event)"
      ></insig-check-in-reset-password>
    </div>

    <insig-check-in-display
      *ngIf="checkInType !== 'kiosk' && checkInType !== 'placeInLine'"
      class="full-height full-width"
      [locationID]="locationID"
      [companyID]="companyID"
      [locationData]="locationData"
      [emrDoctors]="emrDoctors"
      [doctorAppointments]="doctorAppointments"
      [parsedAppointments]="parsedAppointments"
    ></insig-check-in-display>

    <insig-check-in-place-in-line
      *ngIf="checkInType === 'placeInLine'"
      class="full-height full-width"
      [locationID]="locationID"
      [companyID]="companyID"
      [locationData]="locationData"
      [emrDoctors]="emrDoctors"
      [doctorAppointments]="doctorAppointments"
      [parsedAppointments]="parsedAppointments"
      [queryCheckInID]="queryCheckInID"
      [companyData]="companyData"
    ></insig-check-in-place-in-line>
  </div>

  <div class="bottom-toolbar" *ngIf="checkInType === 'kiosk'">
    <button
      mat-fab
      class="mr-2 bottom-toolbar-button"
      [ngClass]="{ 'active-bottom-toolbar-button': selectedStepperIndex !== 0 }"
      (click)="dotsBackClicked()"
    >
      <i class="material-icons notranslate">
        arrow_back
      </i>
    </button>
    <button
      mat-fab
      class="ml-2 bottom-toolbar-button"
      [ngClass]="{
        'active-bottom-toolbar-button':
          currentScreen === 'enterPhone' && !!phoneValid
      }"
      (click)="dotsForwardClicked()"
    >
      <i class="material-icons notranslate">
        arrow_forward
      </i>
    </button>
  </div>
</section>
